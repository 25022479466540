import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router';

import type { State } from '@app/store';

import type { City } from '@shared/api';

import { tabletRoutes } from '@shared/config/routes/tablet.routes';

import useWindowDimensions from '@shared/lib/use-window-dimensions';

import { navigate } from '@shared/router';

import { sendOtp, setCity } from '../../model';

type StartPageBlockProps = {
  city: City;
};

const useStartPageBlockProps = ({ city }: StartPageBlockProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { isNumberAlreadyUsed, isOtpSent } = useSelector(
    (state: State) => state.startPage
  );

  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [accept, setAccept] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const formatPhone = () => phone.replace(/[\s]/g, '').replace('+', '');

  const onSendOtp =
    (isInternational = false) =>
    () => {
      dispatch(sendOtp(formatPhone(), isInternational));
    };

  const onBackClick = () => {
    let route = '/';

    if (location.pathname.includes('odessa')) {
      route = tabletRoutes.odesa();
    } else if (location.pathname.includes('dnipro')) {
      route = tabletRoutes.dnipro();
    } else if (location.pathname.includes('lviv')) {
      route = tabletRoutes.lviv();
    } else if (location.pathname.includes('kyiv')) {
      route = tabletRoutes.kyiv();
    }

    dispatch(navigate(route));
  };

  useEffect(() => {
    if (!city?.name) return;

    dispatch(setCity(city.name));
  }, [city]);

  useEffect(() => {
    if (isNumberAlreadyUsed) return;

    setPhone('');
    setAccept(false);
  }, [isNumberAlreadyUsed]);

  useEffect(() => {
    if (isOtpSent) return;

    setPhone('');
    setAccept(false);
  }, [isOtpSent]);

  useEffect(() => {
    const onClick = () => {
      setShowPrivacy(true);
    };

    const button = document.getElementById('privacy-modal-open');

    button?.addEventListener('click', onClick);

    return () => {
      button?.removeEventListener('click', () => onClick);
    };
  }, []);

  useEffect(() => {
    if (!location.pathname.includes('tablet')) return;

    let timer: any[] = [];

    const interval = () => {
      timer.push(setInterval(onBackClick, 180000));
    };

    const resetInterval = () => {
      if (timer.length != 0) {
        timer.map(timer => {
          clearInterval(timer);
        });

        timer = [];

        timer.push(setInterval(onBackClick, 180000));
      }
    };

    interval();

    document.addEventListener('mousemove', resetInterval);
    document.addEventListener('click', resetInterval);
    document.addEventListener('scroll', resetInterval);
    document.addEventListener('keypress', resetInterval);

    return () => {
      document.removeEventListener('mousemove', resetInterval);
      document.removeEventListener('click', resetInterval);
      document.removeEventListener('scroll', resetInterval);
      document.removeEventListener('keypress', resetInterval);

      if (timer.length != 0) {
        timer.map(timer => {
          clearInterval(timer);
        });

        timer = [];
      }
    };
  }, []);

  return {
    city,
    phone,
    isValid: formatPhone().length === 12 && accept && isPhoneValid,
    isTablet: location.pathname.includes('tablet'),
    isTabletInternational: location.pathname.includes('it'),
    isInternational: location.pathname.includes('in'),
    isOnlyInternational: location.pathname.includes('intr'),
    isPhoneValid,
    setIsPhoneValid,
    accept,
    width,
    showPrivacy,
    setPhone,
    setAccept,
    onSendOtp,
    setShowPrivacy,
    onBackClick
  };
};

export { useStartPageBlockProps };
