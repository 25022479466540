import { CreateDeliveryProcessSaga } from '@processes/create-delivery/model';

import { StartPageSaga } from '@pages/start-page';

import { DeliverySaga } from '@entities/delivery';

import { RouterSaga } from '@shared/router';

import { GeneralSaga } from './general';

/**
 * App sagas
 */
const sagas = [
  new GeneralSaga(),
  new DeliverySaga(),
  new StartPageSaga(),
  new CreateDeliveryProcessSaga(),
  new RouterSaga()
];

export { sagas };
