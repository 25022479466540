import { useNavigate } from 'react-router';

import useWindowDimensions from '@shared/lib/use-window-dimensions';

import styles from './add-review-info-form.module.scss';

const PricingInfo = ({ link, image }: { link: string; image: string }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const onClickEdit = () => {
    navigate(link);
  };

  return (
    <div className={styles.grid}>
      {width > 470 && (
        <>
          <div className={styles.labels}>
            <p>Тип документа:</p>

            <p>Валюта:</p>

            <p>Сумарна оголошена вартість:</p>
          </div>

          <div className={styles.values}>
            <p>DAP</p>

            <p>Євро</p>

            <p>2 EUR</p>
          </div>

          <div className={styles.translate}>
            <p>DAP</p>

            <p>Euro</p>

            <p>2 EUR</p>
          </div>
        </>
      )}

      <div className={styles.mob}>
        <p className={styles.label}>Тип документа:</p>

        <div className={styles.valuesBlock}>
          <p>DAP</p>

          <p>DAP</p>
        </div>

        <p className={styles.label}>Валюта:</p>

        <div className={styles.valuesBlock}>
          <p>Євро</p>

          <p>Euro</p>
        </div>

        <p className={styles.label}>Сумарна оголошена вартість:</p>

        <div className={styles.valuesBlock}>
          <p>2 EUR</p>

          <p>2 EUR</p>
        </div>
      </div>

      <div onClick={onClickEdit} className={styles.editButton}>
        <p>Редагувати</p>

        <img className={styles.editImage} src={image} alt='' />
      </div>
    </div>
  );
};

export { PricingInfo };
