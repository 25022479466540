import { reducer } from 'redux-chill';

import { resetDeliveryPage } from '@pages/delivery';

import { setParametersInfo } from '@features/delivery/add-parameters';

import { setDeliveryReceiverInfo } from '@features/delivery/add-receiver-info';

import { setDeliveryReceiverInfoUt } from '@features/delivery/add-receiver-info-ut';

import { setDeliverySenderInfo } from '@features/delivery/add-sender-info';

import { setDeliverySenderInfoUt } from '@features/delivery/add-sender-info-ut';

import { CreateDeliveryProcessState } from './state';

const createDeliveryProcessReducer = reducer(new CreateDeliveryProcessState())
  .on(setDeliverySenderInfo, (state, { info }) => {
    state.sender = info;
  })

  .on(setDeliverySenderInfoUt, (state, { info }) => {
    state.senderUt = info;
  })

  .on(setDeliveryReceiverInfo, (state, { info }) => {
    state.receiver = info;
  })

  .on(setDeliveryReceiverInfoUt, (state, { info }) => {
    state.receiverUt = info;
  })

  .on(setParametersInfo, (state, { info }) => {
    state.params = info;
  })

  .on(resetDeliveryPage, state => {
    const defaultState = new CreateDeliveryProcessState();

    state.params = defaultState.params;
    state.receiver = defaultState.receiver;
    state.receiverUt = defaultState.receiverUt;
    state.sender = defaultState.sender;
    state.senderUt = defaultState.senderUt;
  });

export { createDeliveryProcessReducer };
