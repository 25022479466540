import classNames from 'classnames';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import headerImg from '../../img/tablet-header-text.png';

import startDniproImage from './../../img/start-dnipro-image.png';

import startKyivImage from './../../img/start-kyiv-image.png';

import startLvivImage from './../../img/start-lviv-image.png';

import startOdessaImage from './../../img/start-odessa-image.png';

import styles from './tablet-navigation-block.module.scss';

import { useTabletNavigationBlock } from './tablet-navigation-block.props';

/**
 * <TabletNavigationBlock />
 */
const TabletNavigationBlock = hoc(
  useTabletNavigationBlock,
  ({ city, onSendToAnotherCity, onSendInternational }) => (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.header} src={headerImg} alt='' />

        <h1>
          Куди хочеш надіслати посилку
          <br />з улюбленим містом?
        </h1>

        <div className={styles.buttons}>
          <Button
            className={styles.buttonFirst}
            variant='secondary'
            onClick={onSendToAnotherCity}
          >
            Другу в інше місто
          </Button>

          <Button
            className={styles.buttonLast}
            variant='primary'
            onClick={onSendInternational}
          >
            Другу закордон
          </Button>
        </div>
      </div>

      <img
        className={classNames(styles.img, {
          [styles.bottom]: city === 'dnipro'
        })}
        src={
          city === 'lviv'
            ? startLvivImage
            : city === 'kyiv'
            ? startKyivImage
            : city === 'dnipro'
            ? startDniproImage
            : city === 'odessa'
            ? startOdessaImage
            : ''
        }
        alt=''
      />
    </div>
  )
);

export { TabletNavigationBlock };
