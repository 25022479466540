import classNames from 'classnames';

import type { ChangeEvent, FC, FocusEvent } from 'react';

import { useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@shared/lib/use-click-outside';

import type { InputProps } from '@shared/ui/atoms/input';

import { Input } from '@shared/ui/atoms/input';

import { List } from '@shared/ui/atoms/list';

import styles from './search.module.scss';

type SearchOption = { id: string; label: string; ref?: string ; description?: string; area?: string };

type SearchProps = Omit<InputProps, 'value' | 'onChange'> & {
  onQueryChange: (query: string) => any;

  onChange: (option: SearchOption | null) => any;

  value: SearchOption | null;

  options: SearchOption[];

  loading?: boolean;
};

const Search: FC<SearchProps> = ({
  options,
  value,
  loading,

  onQueryChange,

  onChange,

  onFocus: _onFocus,

  isError,
  ...props
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');

  const [opened, setOpened] = useState(false);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    onQueryChange(event.target.value);

    if (!value) return;

    onChange(null);
  };

  const onSelect = (option: SearchOption) => {
    setQuery(option.label);
    onChange(option);
    setOpened(false);
  };

  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    setOpened(true);
    _onFocus?.(event);
  };

  const onOutsideClick = () => {
    setOpened(false);
  };

  useEffect(() => {
    if (!value || !value.label) return;

    setQuery(value.label);
  }, [value?.label]);

  useClickOutside(wrapperRef, onOutsideClick);

  return (
    <div className={styles.search} ref={wrapperRef}>
      <Input
        value={query}
        // @ts-expect-error Because typescript mismatch textarea|input events
        onChange={onInputChange}
        // @ts-expect-error Because typescript mismatch textarea|input events
        onFocus={onFocus}
        isError={!opened && isError}
        opened={opened}
        {...props}
      />

      {opened ? (
        <ul
          className={classNames(styles.results, {
            [styles.error]: !!isError && !!props.helperText,
            [styles.isValue]: !!query
          })}
        >
          <List
            items={loading ? [] : options}
            renderItem={({ id, label, ref , description , area }) => (
              <li
                className={styles.result}
                key={id}
                onClick={() => onSelect({ id, label, ref, description , area })}
              >
                {label}
              </li>
            )}
            placeholder={
              loading ? (
                <div className={styles.result}>Пошук...</div>
              ) : (
                <div className={styles.result}>Нічого не знайдено</div>
              )
            }
          />
        </ul>
      ) : null}
    </div>
  );
};

export { Search };

export type { SearchProps, SearchOption };
