import { routes } from '@shared/config/routes';

const steps = [
  'Відправник',
  'Отримувач',
  'Відправлення та доставка',
  'Оголошена вартість',
  'Перевірка даних'
];

const stepsUt = ['Відправник', 'Отримувач', 'Перевірка даних'];

const stepsRoutes = [
  routes.delivery.sender(),
  routes.delivery.receiver(),
  routes.delivery.parameters(),
  routes.delivery.pricing(),
  routes.delivery.review()
];

const stepsRoutesUt = [
  routes.delivery.senderUt(),
  routes.delivery.receiverUt(),
  routes.delivery.reviewUt()
];

export { steps, stepsRoutes, stepsUt, stepsRoutesUt };
