import { object, string } from 'yup';

import type { Directory } from '@shared/lib/directory';

import type { ReceiverInfo } from '../types';

import { englishLettersAndNumbers } from './regexp';

import { onlyNumbers } from './rules';

const schema = object<ReceiverInfo>({
  // @ts-expect-error nullable broke typings
  phone: object()
    .test(
      'validPhone',
      'Поле повинно містити телефон',
      async (value: ReceiverInfo['phone']) => {
        try {
          const { parsePhoneNumber } = await import('libphonenumber-js');

          const phone = parsePhoneNumber(
            value.country.phonePrefix + (value.value ?? ''),
            value.country.id
          );

          return phone.isValid();
        } catch (e) {
          return false;
        }
      }
    )
    .test('onlyNumbers', 'Поле повинно містити тільки цифри', onlyNumbers)
    .nullable()
    .required(),

  email: string().email().required(),

  type: object<Directory>().required(),

  lastName: string().required("Обов'язкове поле"),

  firstName: string().required("Обов'язкове поле"),

  // @ts-expect-error nullable broke typings
  country: object<ReceiverInfo['country']>().nullable().required(),

  city: string().required(),

  cityIndex: string()
    .matches(englishLettersAndNumbers, 'Тільки цифри та латинські літери')
    .max(10)
    .required(),

  street: string().required(),

  building: string().required(),

  additionalInfo: string()
});

export { schema };
