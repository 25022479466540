import { reducer } from 'redux-chill';

import { loading } from './actions';

import { SpinnerState } from './state';

const spinnerReducer = reducer(new SpinnerState())
  .on(loading.start, state => {
    state.isSpinning = true;
  })
  .on(loading.finish, state => {
    state.isSpinning = false;
  });

export { spinnerReducer };
