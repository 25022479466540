import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: "Обов'язкове поле"
  },
  string: {
    length: 'Довжина повинна бути ${length} символів',
    email: 'Поле повинно містити email'
  }
});
