import type { HttpService } from '@shared/lib/http';

import type {
  ApiResponse,
  CreateShipmentDto,
  CreateShipmentDtoUt
} from '../models';

class StartPageService {
  public constructor(private readonly http: HttpService) {}

  public sendOtp = ({
    phone,
    city,
    shipment_type
  }: {
    phone: string;
    city?: string;
    shipment_type: 'uk' | 'int';
  }) =>
    this.http.request<ApiResponse>({
      url: '/validate-phone',
      data: { phone, source: 'iqos_np', city, shipment_type },
      method: 'POST'
    });

  public checkOtp = (data: { phone: string; otp: string; city?: string }) =>
    this.http.request<ApiResponse>({
      url: '/validate-otp',
      method: 'POST',
      data
    });

  public createShipment = (data: CreateShipmentDto) =>
    this.http.request<
      ApiResponse<
        {
          result: string;
          status: string;
        },
        { trac_number?: string }
      >
    >({
      url: '/create-shipment',
      method: 'POST',
      data
    });

  public createShipmentUt = (data: CreateShipmentDtoUt) =>
    this.http.request<
      ApiResponse<
        {
          result: string;
          status: string;
        },
        { trac_number?: string }
      >
    >({
      url: '/create-shipment-uk',
      method: 'POST',
      data
    });

  public translit = (text: string) =>
    this.http
      .request<{ translitText: string }>({
        url: '/translit',
        method: 'POST',
        data: { text }
      })
      .then(response => response.data.translitText);

  public translationTextBatch = async (queries: string[]) =>
    Promise.all(queries.map(query => this.translit(query)));
}

export { StartPageService };
