import type { PropsWithChildren, ReactNode } from 'react';

// eslint-disable-next-line react/function-component-definition
const List = function <T>({
  placeholder,
  renderItem,
  items
}: PropsWithChildren<{
  placeholder?: ReactNode;
  items: T[];
  renderItem: (item: T) => ReactNode;
}>) {
  if (!items.length) return <>{placeholder}</>;

  return <>{items.map(renderItem)}</>;
};

export { List };
