import classNames from 'classnames';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './number-already-used-modal.module.scss';

import { useNumberAlreadyUsedModalProps } from './number-already-used-modal.props';

/**
 * <NumberAlreadyUsedModal />
 */
const NumberAlreadyUsedModal = hoc(
  useNumberAlreadyUsedModalProps,
  ({ open, isTablet, close }) => (
    <Modal open={open} isTablet={isTablet} closeFunction={close}>
      <h1
        className={classNames(styles.title, { [styles.titleTablet]: isTablet })}
      >
        Один номер — дві посилки!
      </h1>

      <p
        className={classNames(styles.description, {
          [styles.descriptionTablet]: isTablet
        })}
      >
        Ми знаємо, що ти вже надіслав сюрпризи друзям 🙌🏻
      </p>

      <div className={styles.buttonContainer}>
        <Button
          className={classNames(styles.button, {
            [styles.buttonTablet]: isTablet
          })}
          variant='primary'
          onClick={close}
        >
          Так, дякую!
        </Button>
      </div>
    </Modal>
  )
);

export { NumberAlreadyUsedModal };
