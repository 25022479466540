import type { To } from 'history';

import type { NavigateFunction, NavigateOptions } from 'react-router/lib/hooks';

import { make } from 'redux-chill';

const setNavigate = make('[router] set navigate').stage(
  (navigateFn: NavigateFunction) => ({ navigateFn })
);

const navigate = make('[router] navigate').stage(
  (to: To, options?: NavigateOptions) => ({ to, options })
);

export { navigate, setNavigate };
