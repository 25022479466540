import type { City } from '@shared/api';

import dniproMobileImage from './img/start-page-dnipro-image-mobile.png';

import dniproImage from './img/start-page-dnipro-image.png';

import dniproQrCodeIn from './img/start-page-dnipro-qr-code-in.png';

import dniproQrCodeUk from './img/start-page-dnipro-qr-code-uk.png';

import kyivMobileImage from './img/start-page-kyiv-image-mobile.png';

import kyivImage from './img/start-page-kyiv-image.png';

import kyivQrCodeIn from './img/start-page-kyiv-qr-code-in.png';

import kyivQrCodeUk from './img/start-page-kyiv-qr-code-uk.png';

import lvivMobileImage from './img/start-page-lviv-image-mobile.png';

import lvivImage from './img/start-page-lviv-image.png';

import lvivQrCodeIn from './img/start-page-lviv-qr-code-in.png';

import lvivQrCodeUk from './img/start-page-lviv-qr-code-uk.png';

import odesaMobileImage from './img/start-page-odesa-image-mobile.png';

import odesaImage from './img/start-page-odesa-image.png';

import odesaQrCodeIn from './img/start-page-odesa-qr-code-in.png';

import odesaQrCodeUk from './img/start-page-odesa-qr-code-uk.png';

/**
 * Cities
 */
const cities: Record<'odesa' | 'dnipro' | 'kyiv' | 'lviv', City> = {
  odesa: {
    name: 'odesa',
    image: {
      tablet: odesaImage,
      mobile: odesaMobileImage
    },
    qrCode: {
      in: odesaQrCodeIn,
      uk: odesaQrCodeUk
    },
    description:
      'Можна надіслати сюрприз з рідного міста тому, кого любиш і хто так сумує за Одесою. Посилка з ароматом літа біля моря ❤️'
  },
  dnipro: {
    name: 'dnipro',
    image: {
      tablet: dniproImage,
      mobile: dniproMobileImage
    },
    qrCode: {
      in: dniproQrCodeIn,
      uk: dniproQrCodeUk
    },
    description:
      'Можна надіслати сюрприз з рідного міста тому, кого любиш і хто так сумує за Дніпром. Пакуй місто в коробки та ділися деталями ❤️'
  },
  kyiv: {
    name: 'kyiv',
    image: {
      tablet: kyivImage,
      mobile: kyivMobileImage
    },
    qrCode: {
      in: kyivQrCodeIn,
      uk: kyivQrCodeUk
    },
    description:
      'Можна надіслати сюрприз з рідного міста тому, кого любиш і хто так сумує за Києвом. Ну, тому що як його не любити? ❤️'
  },
  lviv: {
    name: 'lviv',
    image: {
      tablet: lvivImage,
      mobile: lvivMobileImage
    },
    qrCode: {
      in: lvivQrCodeIn,
      uk: lvivQrCodeUk
    },
    description:
      'Можна надіслати сюрприз з рідного міста тому, кого любиш і хто так сумує за Львовом. Привіт від міста Лева з нотками кави ❤️'
  }
};

export { cities };
