import classNames from 'classnames';

import type { FC } from 'react';

import { useDispatch } from 'react-redux';

import { getBranch, getParcelLocker, getStreets } from '@entities/delivery';

import type { useAddReceiverInfoUt } from '../model';

import styles from './add-receiver-info-ut-form.module.scss';

type DelyveryDescriptionCheckProps = {
  form: ReturnType<typeof useAddReceiverInfoUt>['form'];
};

const ReceivCheck: FC<DelyveryDescriptionCheckProps> = ({ form }) => {
  const dispatch = useDispatch();
  const receivs = [
    {
      id: 1,
      label: 'Відділення'
    },
    {
      id: 2,
      label: 'Адреса'
    }
  ];

  const onClickReceiv = (id: number) => {
    dispatch(getBranch.reset());
    dispatch(getStreets.reset());
    dispatch(getParcelLocker.reset());
    form.setFieldValue('street', null);
    form.setFieldValue('branch', null);
    form.setFieldValue('apartments', '');
    form.setFieldValue('building', '');
    form.setFieldValue('parcelLocker', null);
    form.setFieldValue('receivCheckId', id);
  };

  return (
    <>
      {receivs.map(receivs => (
        <div
          className={classNames(styles.blocks, {
            [styles.checked]: receivs.id === form?.values?.receivCheckId
          })}
          key={receivs.id}
          onClick={() => {
            onClickReceiv(receivs.id);
          }}
        >
          {receivs?.label}
        </div>
      ))}
    </>
  );
};

export { ReceivCheck };
