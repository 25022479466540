type MobUkRoutes = {
  odesa: () => string;
  dnipro: () => string;
  kyiv: () => string;
  lviv: () => string;
  cityUk: () => string;
};

const mobUkRoutes: MobUkRoutes = {
  odesa: () => '/odesa-uk',
  dnipro: () => '/dnipro-uk',
  kyiv: () => '/kyiv-uk',
  lviv: () => '/lviv-uk',
  cityUk: () => '/city-uk'
};

export { mobUkRoutes };
