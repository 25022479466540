import classNames from 'classnames';

import { hoc } from '@shared/lib/hoc';

import styles from './checkbox.module.scss';

import { useCheckbox } from './checkbox.props';

/**
 * Renders Checkbox
 */
const Checkbox = hoc(
  useCheckbox,
  ({ className, label, isError, disabled, value, onClick }) => (
    <div
      className={classNames(styles.checkbox, className?.container, {
        [styles.active]: value,
        [styles.error]: isError,
        [styles.disabled]: disabled
      })}
    >
      <div className={classNames(styles.box, className?.box)} onClick={onClick}>
        {value && (
          <div className={classNames(styles.checkmark, className?.checkmark)} />
        )}
      </div>

      <label
        className={classNames(styles.label, className?.label)}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: `${label}` }}
      />
    </div>
  )
);

export { Checkbox };
