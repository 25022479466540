import type { AxiosResponse } from 'axios';

class MockHttp {
  private readonly mockResponseConfig: Omit<AxiosResponse, 'data'> = {
    status: 200,
    config: {},
    request: {},
    statusText: 'Ok',
    headers: {}
  };

  protected getMockResponse = async <T>(data: T): Promise<AxiosResponse<T>> => {
    await new Promise(r => setTimeout(r, 500));

    return Object.assign({}, this.mockResponseConfig, { data });
  };
}

export { MockHttp };
