/* eslint-disable react/no-unescaped-entities */
import classNames from 'classnames';

import { Field, Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import { useAddParameters } from '../model';

import styles from './add-parameters-form.module.scss';

import { DelyveryDescriptionCheck } from './check-delyvery-description.component';

const AddParametersForm = hoc(
  useAddParameters,
  ({ form, isValid, onGoBackClick }) => (
    <Form
      className={styles.form}
      style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
      use={form}
    >
      <div>
        <h3 className={styles.title}>Параметри відправлення та тип доставки</h3>

        <p className={styles.subtitle}>
          Ми вже все обрали за тебе, просто перевір чи все вірно та тисни "Далі"
          🙌
        </p>
      </div>

      <div className={styles.exportBlock}>
        <Field.Text
          className={styles.exportTarget}
          name='target'
          type='text'
          label='Ціль експорту'
          disabled
        />
      </div>

      <div className={styles.block}>
        <p className={styles.blockTitle}>
          Опис відправлення (можна обрати декілька)
        </p>

        <div className={styles.grid}>
          <DelyveryDescriptionCheck form={form} />
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.blockTitle}> Тип відправлення</p>

        <div className={styles.grid}>
          <div className={classNames(styles.blocks, styles.disabled)}>
            Вантаж
          </div>

          <div className={classNames(styles.blocks, styles.disabledText)}>
            Документи
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.blockTitle}> Місце 1</p>

        <div className={styles.gridValuesBlock}>
          <Field.Text
            className={styles.field}
            name='weight'
            type='text'
            label='Фактична вага'
            disabled
          />

          <Field.Text
            className={styles.field}
            name='length'
            type='text'
            label='Довжина'
            disabled
          />

          <Field.Text
            className={styles.field}
            name='width'
            type='text'
            label='Ширина'
            disabled
          />

          <Field.Text
            className={styles.field}
            name='height'
            type='text'
            label='Висота'
            disabled
          />

          <Field.Text
            className={styles.field}
            name='volumetricWeight'
            type='text'
            label='Об’ємна вага'
            disabled
          />
        </div>
      </div>

      <div className={styles.deliveryBlock}>
        <p className={styles.blockTitle}> Тип доставки</p>

        <div className={styles.grid}>
          <p className={styles.value}>Глобальна доставка (global delivery)</p>

          <div className={styles.delyveryInfo}>
            <p className={styles.label}>Вага</p>

            <p className={styles.value}>до 70 кг</p>
          </div>

          <div className={styles.delyveryInfo}>
            <p className={styles.label}>Розміри</p>

            <p className={styles.value}>до 40х60х35 кг</p>
          </div>

          <div className={styles.delyveryInfo}>
            <p className={styles.label}>
              Орієнтовний час доставки від столиці до столиці
            </p>

            <p className={styles.value}>від 10 днів</p>
          </div>
        </div>
      </div>

      <StepperFormControls
        dirty={form.dirty}
        canSubmit={isValid}
        onGoBackClick={onGoBackClick}
      />
    </Form>
  )
);

export { AddParametersForm };
