import { useField } from 'formik';

import type { FC } from 'react';

import type { Directory } from '@shared/lib/directory';

import type { RadioGroupProps } from '@shared/ui/molecules/radio-group';

import { RadioGroup } from '@shared/ui/molecules/radio-group';

type RadioGroupFieldProps = Omit<RadioGroupProps, 'value' | 'onChange'> & {
  name: string;
};

const RadioGroupField: FC<RadioGroupFieldProps> = ({ name, ...props }) => {
  const [{ value }, , { setValue, setTouched }] = useField<Directory | null>(
    name
  );

  const onFieldChange = (option: Directory) => {
    setTouched(true);
    setValue(option);
  };

  return <RadioGroup value={value} onChange={onFieldChange} {...props} />;
};

export { RadioGroupField };

export type { RadioGroupFieldProps };
