import '@shared/i18n/yup';

import type { FC } from 'react';

import { Suspense, useEffect, useLayoutEffect } from 'react';

import { Pages } from '@pages';

import { useSetupRouter } from '@shared/router';

import { Spinner } from '@shared/spinner';

const App: FC = () => {
  useSetupRouter();

  useEffect(() => {
    const isIphone = JSON.parse(localStorage.getItem('isIphone') ?? 'false');

    if (
      navigator.maxTouchPoints > 0 &&
      (navigator.userAgent.match(/Macintosh/i) ||
        navigator.userAgent.match(/iPad/i)) &&
      !isIphone
    ) {
      const viewPortMeta: any = document.querySelector('meta[name="viewport"]');

      if (viewPortMeta) {
        viewPortMeta.content =
          'width=device-width, initial-scale=0.5, minimum-scale=0.5, maximum-scale=0.5';

        document.body.addEventListener(
          'gesturestart',
          () => {
            viewPortMeta.content =
              'width=device-width, initial-scale=0.5, minimum-scale=0.5, maximum-scale=0.5';
          },
          false
        );
      }
    }

    if (!isIphone) {
      localStorage.setItem(
        'isIphone',
        JSON.stringify(!!navigator.userAgent.match(/iPhone/))
      );
    }
  }, []);

  useLayoutEffect(() => {
    const setRealViewportHeight = () => {
      //fix iphone vh real viewport height
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    };

    setRealViewportHeight();

    window.addEventListener('resize', setRealViewportHeight);

    return () => {
      window.removeEventListener('resize', setRealViewportHeight);
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Pages />

      <Spinner />
    </Suspense>
  );
};

export { App };
