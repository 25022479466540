import React from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { DeliveryPage } from '@pages/delivery';

import {
  CityUkStartBlock,
  DniproStartBlock,
  KiyvStartBlock,
  LvivStartBlock,
  OdessaStartBlock
} from '@pages/start-page';

import {
  DniproNavigationBlock,
  KiyvNavigationBlock,
  LvivNavigationBlock,
  OdessaNavigationBlock
} from '@pages/tablet-navigation-page';

import { AddPricingForm } from '@features/delivery/add-pricing';

import { AddReviewInfoForm } from '@features/delivery/add-review-info';

import { AddReviewInfoUtForm } from '@features/delivery/add-review-info-ut';

import { routes } from '@shared/config/routes';

import {
  DeliveryParametersPage,
  DeliveryReceiverPage,
  DeliveryReceiverUtPage,
  DeliverySenderPage,
  DeliverySenderUtPage
} from './delivery/pages';

const Pages = () => (
  <Routes>
    <Route path={routes.mobUkRoutes.odesa()} element={<OdessaStartBlock />} />

    <Route path={routes.mobUkRoutes.dnipro()} element={<DniproStartBlock />} />

    <Route path={routes.mobUkRoutes.kyiv()} element={<KiyvStartBlock />} />

    <Route path={routes.mobUkRoutes.lviv()} element={<LvivStartBlock />} />

    <Route path={routes.mobInRoutes.odesa()} element={<OdessaStartBlock />} />

    <Route path={routes.mobInRoutes.dnipro()} element={<DniproStartBlock />} />

    <Route path={routes.mobInRoutes.kyiv()} element={<KiyvStartBlock />} />

    <Route path={routes.mobInRoutes.lviv()} element={<LvivStartBlock />} />

    <Route path={routes.mobInTrRoutes.odesa()} element={<OdessaStartBlock />} />

    <Route
      path={routes.mobInTrRoutes.dnipro()}
      element={<DniproStartBlock />}
    />

    <Route path={routes.mobInTrRoutes.kyiv()} element={<KiyvStartBlock />} />

    <Route path={routes.mobInTrRoutes.lviv()} element={<LvivStartBlock />} />

    <Route path={routes.mobUkRoutes.cityUk()} element={<CityUkStartBlock />} />

    <Route path={routes.tablet()} element={<Outlet />}>
      <Route path={routes.tablet.odesa()} element={<Outlet />}>
        <Route path={routes.tablet.odesaIt()} element={<OdessaStartBlock />} />

        <Route path={routes.tablet.odesaUt()} element={<OdessaStartBlock />} />

        <Route
          path={routes.tablet.odesa()}
          element={<OdessaNavigationBlock />}
        />

        <Route path='*' element={<Navigate to={routes.tablet.odesa()} />} />
      </Route>

      <Route path={routes.tablet.dnipro()} element={<Outlet />}>
        <Route path={routes.tablet.dniproIt()} element={<DniproStartBlock />} />

        <Route path={routes.tablet.dniproUt()} element={<DniproStartBlock />} />

        <Route
          path={routes.tablet.dnipro()}
          element={<DniproNavigationBlock />}
        />

        <Route path='*' element={<Navigate to={routes.tablet.dnipro()} />} />
      </Route>

      <Route path={routes.tablet.kyiv()} element={<Outlet />}>
        <Route path={routes.tablet.kyivIt()} element={<KiyvStartBlock />} />

        <Route path={routes.tablet.kyivUt()} element={<KiyvStartBlock />} />

        <Route path={routes.tablet.kyiv()} element={<KiyvNavigationBlock />} />

        <Route path='*' element={<Navigate to={routes.tablet.kyiv()} />} />
      </Route>

      <Route path={routes.tablet.lviv()} element={<Outlet />}>
        <Route path={routes.tablet.lvivIt()} element={<LvivStartBlock />} />

        <Route path={routes.tablet.lvivUt()} element={<LvivStartBlock />} />

        <Route path={routes.tablet.lviv()} element={<LvivNavigationBlock />} />

        <Route path='*' element={<Navigate to={routes.tablet.lviv()} />} />
      </Route>

      <Route path='*' element={<Navigate to={routes.tablet.odesa()} />} />
    </Route>

    <Route path={routes.delivery()} element={<DeliveryPage />}>
      <Route path={routes.delivery.sender()} element={<DeliverySenderPage />} />

      <Route
        path={routes.delivery.senderUt()}
        element={<DeliverySenderUtPage />}
      />

      <Route
        path={routes.delivery.receiver()}
        element={<DeliveryReceiverPage />}
      />

      <Route
        path={routes.delivery.receiverUt()}
        element={<DeliveryReceiverUtPage />}
      />

      <Route
        path={routes.delivery.parameters()}
        element={<DeliveryParametersPage />}
      />

      <Route path={routes.delivery.pricing()} element={<AddPricingForm />} />

      <Route path={routes.delivery.review()} element={<AddReviewInfoForm />} />

      <Route
        path={routes.delivery.reviewUt()}
        element={<AddReviewInfoUtForm />}
      />

      <Route path='*' element={<Navigate to={routes.delivery.sender()} />} />
    </Route>

    <Route path='*' element={<Navigate to={routes.mobUkRoutes.odesa()} />} />
  </Routes>
);

export { Pages };
