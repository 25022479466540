import { useFormik } from 'formik';

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { schema } from '../lib';

import type { Parameters } from '../types';

import { setParametersInfo } from './actions';

type AddParametersProps = {
  initialValues: Pick<Parameters, 'delyveryInfo'>;
};

const useAddParameters = ({ initialValues }: AddParametersProps) => {
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const form = useFormik<Parameters>({
    initialValues: {
      target: 'Для особистих цілей',

      delyveryInfo: initialValues.delyveryInfo ?? [],

      weight: '0.5 кг',

      length: '24 см',

      width: '17 см',

      height: '4 см',

      volumetricWeight: '0.5 кг'
    },

    validateOnMount: true,

    validationSchema: schema,

    onSubmit: (values, { setSubmitting }) => {
      dispatch(setParametersInfo(values));
      setSubmitting(false);
    }
  });

  const onGoBackClick = () => {
    dispatch(setParametersInfo.reset());
  };

  const checkIsValid = async () => {
    const isValid = await form.validateForm(form.values);
  };

  useEffect(() => {
    checkIsValid();

    if (!form.isValid) {
      setIsValid(false);

      return;
    }

    setIsValid(true);
  }, [form.isValid]);

  useEffect(() => {
    checkIsValid();
  }, [form.dirty]);

  return {
    form,
    isValid,
    onGoBackClick
  };
};

export { useAddParameters };
