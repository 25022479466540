import type { HttpService } from '@shared/lib/http';

import { MockHttp } from '@shared/lib/http';

import type {
  NPResponse,
  SearchBranch,
  SearchCitiesContract,
  SearchCity,
  SearchParcelLocker,
  SearchStreetsContract
} from '../models';

class NovaPoshtaService extends MockHttp {
  public constructor(private readonly http: HttpService) {
    super();
  }

  public searchCities = (query: string) =>
    this.http
      .request<NPResponse<SearchCitiesContract>>({
        url: '/search-settlement',
        data: {
          city_name: query,
          limit: '5',
          page: '1'
        },
        method: 'POST'
      })
      .then((r): SearchCitiesContract => {
        if (r.data.ops[0].data.success === 'false') {
          return { Addresses: [], TotalCount: 0 };
        }

        return r.data?.ops?.[0]?.data?.data?.[0];
      });

  public searchStreets = ({
    ref,
    query
  }: {
    query: string;
    ref: SearchCity['Ref'];
  }) =>
    this.http
      .request<NPResponse<SearchStreetsContract>>({
        url: '/search-settlement-streets',
        data: {
          street_name: query,
          settlement_ref: ref,
          limit: '5'
        },
        method: 'POST'
      })
      .then((r): SearchStreetsContract => {
        if (r.data.ops[0].data.success === 'false') {
          return { Addresses: [], TotalCount: 0 };
        }

        return r.data?.ops?.[0]?.data?.data?.[0];
      });

  public searchBranch = ({ ref }: { ref: SearchCity['Ref'] }) =>
    this.http
      .request<NPResponse<SearchBranch>>({
        url: '/search-warehouse',
        data: {
          settlement_ref: ref
        },
        method: 'POST'
      })
      .then((r): [SearchBranch] => {
        if (r.data.ops[0].data.success === 'false') {
          return [{ Description: '', Ref: '' }];
        }

        return r.data?.ops?.[0]?.data?.data;
      });

  public searchParcelLocker = ({
    ref,
    query
  }: {
    query: string;
    ref: SearchCity['Ref'];
  }) =>
    this.http
      .request<NPResponse<SearchParcelLocker>>({
        url: '/search-poshtomat',
        data: {
          poshta_name: query,
          settlement_ref: ref
        },
        method: 'POST'
      })
      .then((r): [SearchParcelLocker] => {
        if (r.data.ops[0].data.success === 'false') {
          return [{ Description: '', Ref: '' }];
        }

        return r.data?.ops?.[0]?.data?.data;
      });
}

export { NovaPoshtaService };
