import { useFormik } from 'formik';

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import type { SenderInfo } from '@features/delivery/add-sender-info';

import { schema } from '@features/delivery/add-sender-info/lib';

import { setDeliverySenderInfo } from './actions';

type AddSenderInfoProps = {
  initialValues: SenderInfo;
};

const useAddSenderInfo = ({ initialValues }: AddSenderInfoProps) => {
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const form = useFormik<SenderInfo>({
    initialValues,

    validateOnMount: true,
    validationSchema: schema,

    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        setDeliverySenderInfo({
          ...values,
          surname: values.surname?.trim(),
          firstname: values.firstname?.trim()
        })
      );

      setSubmitting(false);
    }
  });

  const onGoBack = () => {
    dispatch(setDeliverySenderInfo.reset());
  };

  const checkIsValid = async () => {
    const isValid = await form.validateForm(form.values);
  };

  useEffect(() => {
    checkIsValid();

    if (!form.isValid) {
      setIsValid(false);

      return;
    }

    setIsValid(true);
  }, [form.isValid]);

  useEffect(() => {
    checkIsValid();
  }, [form.dirty]);

  return {
    form,
    isValid,
    onGoBack
  };
};

export { useAddSenderInfo };
