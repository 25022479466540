import { useDispatch } from 'react-redux';

import { getParcelLocker } from '@entities/delivery';

import { Field } from '@shared/form';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { useCityId, useIsCityEntered } from '../model';

import styles from './add-receiver-info-ut-form.module.scss';

const ParcelLockerInput = () => {
  const dispatch = useDispatch();
  const { searchParcelLockerResult, searchStreetsPending } = useTypedSelector(
    state => state.delivery
  );

  const isCityEntered = useIsCityEntered();
  const cityId = useCityId();

  const onCitySearchQueryChange = (query: string) => {
    if (query.length < 2) return;

    if (!cityId) return;

    dispatch(getParcelLocker(query, cityId));
  };

  const onReset = () => {
    dispatch(getParcelLocker.reset());
  };

  return (
    <Field.Search
      className={styles.field}
      name='parcelLocker'
      type='search'
      label='Поштомат'
      options={searchParcelLockerResult}
      onQueryChange={onCitySearchQueryChange}
      onBlur={onReset}
      disabled={!isCityEntered}
      loading={searchStreetsPending}
    />
  );
};

export { ParcelLockerInput };
