import FuzzySearch from 'fuzzy-search';

import { useState } from 'react';

import { countries } from '@features/delivery/add-receiver-info/config';

import { Field } from '@shared/form';

import styles from './add-receiver-info-form.module.scss';

const searcher = new FuzzySearch(
  countries.filter(country => country.id !== 'UA'),
  ['id', 'label'],
  {
    caseSensitive: false,
    sort: true
  }
);

const CountrySearchInput = () => {
  const [options, setOptions] = useState(
    countries.filter(country => country.id !== 'UA')
  );

  const onCitySearchQueryChange = (query = '') => {
    setOptions(searcher.search(query));
  };

  return (
    <Field.Search
      className={styles.field}
      name='country'
      label='Країна'
      options={options.sort((a, b) => a.label[0].localeCompare(b.label[0]))}
      onQueryChange={onCitySearchQueryChange}
    />
  );
};

export { CountrySearchInput };
