import { useNavigate } from 'react-router';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import styles from './add-review-info-ut-form.module.scss';

const SenderInfo = ({ link, image }: { link: string; image: string }) => {
  const { senderUt } = useTypedSelector(state => state.createDeliveryProcess);
  const { phone } = useTypedSelector(state => state.startPage);

  const navigate = useNavigate();

  const onClickEdit = () => {
    navigate(link);
  };

  return (
    <div className={styles.grid}>
      <div className={styles.labels}>
        <p>Телефон:</p>

        <p>Ім’я:</p>
      </div>

      <div className={styles.values}>
        <p>{phone}</p>

        <p>{`${senderUt?.firstname} ${senderUt?.surname}`}</p>
      </div>

      <div onClick={onClickEdit} className={styles.editButton}>
        <p>Редагувати</p>

        <img className={styles.editImage} src={image} alt='' />
      </div>
    </div>
  );
};

export { SenderInfo };
