import { useField } from 'formik';

import type { FC } from 'react';

import type { SearchOption, SearchProps } from '@shared/ui/molecules/search';

import { Search } from '@shared/ui/molecules/search';

type SearchFieldProps = Omit<SearchProps, 'value' | 'name' | 'onChange'> & {
  name: string;
};

const SearchField: FC<SearchFieldProps> = ({
  name: formName,
  onBlur: _onBlur,
  ...props
}) => {
  const [{ name, value }, { error, touched }, { setValue, setTouched }] =
    useField<SearchOption | null>(formName);

  return (
    <Search
      key={name}
      name={name}
      value={value}
      onChange={setValue}
      onBlur={() => setTouched(true)}
      isError={touched && !!error}
      helperText={!props.disabled && touched ? error : ''}
      {...props}
    />
  );
};

export { SearchField };

export type { SearchFieldProps };
