import type { DeliveryDescription } from '../types';

const deliveryDescription: DeliveryDescription[] = [
  {
    id: 1,
    label: [
      { langCode: 'ua', value: 'Картка із стікерами' },
      { langCode: 'en', value: 'Card with stickers' }
    ]
  },
  {
    id: 2,
    label: [
      { langCode: 'ua', value: 'Саше' },
      { langCode: 'en', value: 'Sachet' }
    ]
  },
  {
    id: 3,
    label: [
      { langCode: 'ua', value: 'Магніт' },
      { langCode: 'en', value: 'Magnet' }
    ]
  },
  {
    id: 4,
    label: [
      { langCode: 'ua', value: 'Картка "To Do List"' },
      { langCode: 'en', value: "Card 'To Do List'" }
    ]
  },
  {
    id: 5,
    label: [
      { langCode: 'ua', value: 'Картка із плейлистом' },
      { langCode: 'en', value: 'Card with a playlist' }
    ]
  },
  {
    id: 6,
    label: [
      { langCode: 'ua', value: 'Картка із піном' },
      { langCode: 'en', value: 'Card with pin' }
    ]
  },
  {
    id: 7,
    label: [
      { langCode: 'ua', value: 'Конверт' },
      { langCode: 'en', value: 'Envelope' }
    ]
  },
  {
    id: 8,
    label: [
      { langCode: 'ua', value: 'Лист' },
      { langCode: 'en', value: 'Letter' }
    ]
  },
  {
    id: 9,
    label: [
      { langCode: 'ua', value: 'Фото INSTAX' },
      { langCode: 'en', value: 'Photo INSTAX' }
    ]
  },
  {
    id: 10,
    label: [
      { langCode: 'ua', value: 'Сет тимчасових тату' },
      { langCode: 'en', value: 'Set with temporary tattoos' }
    ]
  }
];

export { deliveryDescription };
