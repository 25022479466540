import { Field, Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import { useAddPricing } from '../model';

import styles from './add-pricing-form.module.scss';

const AddPricingForm = hoc(useAddPricing, ({ form, onGoBack }) => (
  <Form
    className={styles.form}
    style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
    use={form}
  >
    <div>
      <h3 className={styles.title}>Оголошена вартість</h3>

      <p className={styles.subtitle}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Тут теж не потрібно нічого змінювати — просто натискай "Далі".
      </p>
    </div>

    <div className={styles.grid}>
      <Field.Text
        className={styles.field}
        name='pricing'
        type='text'
        label='Оголошена вартість'
        disabled
      />

      <div className={styles.conditionsBlock}>
        <Field.Text
          className={styles.conditions}
          name='deliveryСonditions'
          type='text'
          label='Умови поставки DAP'
          disabled
        />
      </div>
    </div>

    <StepperFormControls
      dirty={form.dirty}
      canSubmit={form.isValid}
      onGoBackClick={onGoBack}
    />
  </Form>
));

export { AddPricingForm };
