import classNames from 'classnames';

import type _Mask from 'react-input-mask';

import * as InputMask from 'react-input-mask';

import { hoc } from '@shared/lib';

import styles from './phone-input.module.scss';

import { usePhoneInput } from './phone-input.props';

/**
 * !Typings retard alert!
 */
const Mask: typeof _Mask = InputMask as any;

/**
 * Renders Phone Input
 */
const PhoneInput = hoc(
  usePhoneInput,
  ({
    className,
    input,
    value,
    error,
    placeholder,
    label,
    isError,
    isTablet,
    focused,
    _onBlur,
    disabled,
    _onFocus,
    hasValue,
    onInputChange,
    onLabelClick
  }) => (
    <div className={classNames(styles.inputContainer, className)}>
      {label && (
        <p
          className={classNames(styles.label, {
            [styles.labelFocused]: hasValue || focused,
            [styles.labelError]: isError
          })}
          onClick={onLabelClick}
        >
          {label}
        </p>
      )}

      <Mask
        className={classNames(styles.input, {
          [styles.inputTablet]: isTablet,
          [styles.inputHasValue]: hasValue && !isTablet,
          [styles.inputHasValueTablet]: hasValue && isTablet,
          [styles.inputFocused]: focused && !isTablet,
          [styles.inputFocusedTablet]: focused && isTablet,
          [styles.inputError]: isError && !isTablet,
          [styles.inputErrorTablet]: isError && isTablet
        })}
        name='phone'
        type='tel'
        mask='+38 999 999 99 99'
        maskChar=''
        placeholder={placeholder}
        onBlur={_onBlur}
        inputRef={ref => !!input && (input.current = ref as any)}
        onFocus={_onFocus}
        disabled={disabled}
        value={value}
        onChange={onInputChange}
      />

      {(focused || hasValue || value) && (
        <div
          className={classNames(styles.prefix, {
            [styles.prefixTablet]: isTablet
          })}
        >
          +38
        </div>
      )}

      {(isError || !!error) && (
        <p
          className={classNames(styles.error, {
            [styles.errorTablet]: isTablet
          })}
        >
          {error}
        </p>
      )}

      {(isError || !!error) && (
        <p
          className={classNames(styles.errorSign, {
            [styles.errorSignTablet]: isTablet
          })}
        >
          !
        </p>
      )}
    </div>
  )
);

export { PhoneInput };
