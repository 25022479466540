import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router';

import type { State } from '@app/store';

import {
  checkOtp,
  sendOtp,
  setIsOtpIncorrect,
  setIsOtpSent
} from '@pages/start-page/model';

/**
 * <OtpModal /> props
 */
const useOtpModalProps = () => {
  const otpDivRef = useRef<HTMLDivElement>();

  const location = useLocation();
  const dispatch = useDispatch();

  const { phone, isOtpSent, isOtpIncorrect, isInternationalClicked } =
    useSelector((state: State) => state.startPage);

  const [otp, setOtp] = useState('');

  const onCheckOtp = () => {
    dispatch(checkOtp(otp, location.pathname.includes('tablet')));
  };

  const onSendAgain = () => {
    dispatch(sendOtp(phone, isInternationalClicked));

    setOtp('');
  };

  const onChange = (value: string) => {
    setOtp(value);

    if (!isOtpIncorrect) return;

    dispatch(setIsOtpIncorrect(false));
  };

  const onClose = () => {
    dispatch(setIsOtpSent(false));
  };

  useEffect(() => {
    if (isOtpSent) return;

    setOtp('');
  }, [isOtpSent]);

  useEffect(() => {
    if (!isOtpSent) return;

    const inputs = otpDivRef.current?.getElementsByTagName('input');

    const firstInput = inputs?.item(0);
    const secondInput = inputs?.item(1);

    if (!firstInput) return;

    firstInput.addEventListener('focus', () => {
      firstInput.className = `${
        firstInput.className
      } otp-modal_otp-input-focused${
        location.pathname.includes('tablet') ? '-tablet' : ''
      }`;
    });

    firstInput.className = secondInput?.className as string;
  }, [isOtpSent]);

  return {
    otpDivRef,
    open: isOtpSent,
    incorrectOtp: isOtpIncorrect,
    isValid: otp.length === 4,
    isTablet: location.pathname.includes('tablet'),
    otp,
    onChange,
    onCheckOtp,
    onSendAgain,
    onClose
  };
};

export { useOtpModalProps };
