import { reducer } from 'redux-chill';

import {
  checkOtp,
  sendOtp,
  setCity,
  setIsNumberAlreadyUser,
  setIsOtpIncorrect,
  setIsOtpSent,
  setPhone,
  setStartPageParams
} from './actions';

import { StartPageState } from './state';

/**
 * General reducer
 */
const startPageReducer = reducer(new StartPageState())
  .on(setCity, (state, payload) => {
    state.city = payload;
  })
  .on(setIsOtpSent, (state, payload) => {
    state.isOtpSent = payload;
  })
  .on(setIsNumberAlreadyUser, (state, payload) => {
    state.isNumberAlreadyUsed = payload;
  })
  .on(setPhone, (state, payload) => {
    state.phone = payload;
  })
  .on(sendOtp, (state, payload) => {
    state.isOtpIncorrect = false;
    state.isInternationalClicked = payload.isInternationalClick;
  })
  .on(checkOtp, state => {
    state.isOtpIncorrect = false;
  })
  .on(checkOtp.error, state => {
    state.isOtpIncorrect = true;
  })
  .on(checkOtp.success, state => {
    state.isOtpIncorrect = false;
    state.isOtpSent = false;
    state.isNumberAlreadyUsed = false;
  })
  .on(setIsOtpIncorrect, (state, payload) => {
    state.isOtpIncorrect = payload;
  })
  .on(setStartPageParams, (state, { isTablet, isInternational }) => {
    state.isTablet = isTablet;
    state.isInternational = isInternational;
  });

export { startPageReducer };
