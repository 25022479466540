import type { History } from 'history';

import type { Store } from 'redux';

import { NovaPoshtaService, TranslationService } from '@shared/api/services';

import { StartPageService } from '@shared/api/services/start-page.service';

import { enviroment } from '@shared/config/enviroment';

import { HttpService } from '@shared/lib/http';

/**
 * Get context
 */
const getContext = (history: History, store: Store) => {
  const http = new HttpService(
    () => ({
      baseURL: enviroment.startPageServiceApiUrl,
      headers: { Authorization: enviroment.startPageServiceApiKey }
    }),
    {}
  );

  const translationHttp = new HttpService(
    () => ({ baseURL: enviroment.translationApiUrl }),
    {}
  );

  return {
    store,
    history,
    api: {
      novaPoshta: new NovaPoshtaService(http),
      startPage: new StartPageService(http),
      translation: new TranslationService(translationHttp)
    }
  };
};

/**
 * Saga context
 */
type StoreContext = ReturnType<typeof getContext>;

export { getContext };

export type { StoreContext };
