import { descriptions } from '@pages/start-page/data';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './privacy-policy-modal.module.scss';

/**
 * <PrivacyPolicyModal />
 */
const PrivacyPolicyModal = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Modal
    className={{ content: styles.modal }}
    open={open}
    closeFunction={onClose}
  >
    <div className={styles.content}>
      <h1
        dangerouslySetInnerHTML={{
          __html: `Умови участі у активності
        <br />
        «IQOS x Нова Пошта – доставка
        
        <br />
        обіймів» (далі – «Активність»)`
        }}
      />

      <h4>від 16 серпня 2022 року</h4>

      {descriptions.map((description, index) => (
        <div key={index} className={styles.description}>
          <h6>{`${index + 1}. ${description.title}`}</h6>

          {description.descriptions.map((description, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: `${description}` }} />
          ))}
        </div>
      ))}
    </div>
  </Modal>
);

export { PrivacyPolicyModal };
