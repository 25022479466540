import useWindowDimensions from '@shared/lib/use-window-dimensions';

import styles from './add-review-info-form.module.scss';

const ParametersInfo = () => {
  const { width } = useWindowDimensions();

  return (
    <div className={styles.grid}>
      {width > 470 && (
        <>
          <div className={styles.labels}>
            <p className={styles.label}>Ціль експорту:</p>

            <p className={styles.label}>Тип відправлення:</p>

            <p className={styles.label}>Опис вантажу:</p>

            <p className={styles.label}>Послуга:</p>

            <p className={styles.label}>Вартість:</p>
          </div>

          <div className={styles.values}>
            <p>Для особистих цілей</p>

            <p>Вантаж</p>

            <p>Сувенір</p>

            <p>UA - PL. Речі з дому. inPost</p>

            <p>грн</p>
          </div>

          <div className={styles.translate}>
            <p>For personal use</p>

            <p>Cargo</p>

            <p>Souvenir</p>

            <p>UA - PL. Things from home. inPost</p>

            <p>UAH</p>
          </div>
        </>
      )}

      <div className={styles.mob}>
        <p className={styles.label}>Ціль експорту:</p>

        <div className={styles.valuesBlock}>
          <p>Для особистих цілей</p>

          <p>For personal use</p>
        </div>

        <p className={styles.label}>Тип відправлення:</p>

        <div className={styles.valuesBlock}>
          <p>Вантаж</p>

          <p>Cargo</p>
        </div>

        <p className={styles.label}>Опис вантажу:</p>

        <div className={styles.valuesBlock}>
          <p>Сувенір</p>

          <p>Souvenir</p>
        </div>

        <p className={styles.label}>Послуга:</p>

        <div className={styles.valuesBlock}>
          <p>UA - PL. Речі з дому. inPost</p>

          <p>UA - PL. Things from home. inPost</p>
        </div>

        <p className={styles.label}>Вартість:</p>

        <div className={styles.valuesBlock}>
          <p>грн</p>

          <p>UAH</p>
        </div>
      </div>

      <div className={styles.parameters}>
        <div className={styles.parameterBlock}>
          <p className={styles.labelParameter}>Місце</p>

          <p className={styles.value}>1</p>
        </div>

        <div className={styles.parameterBlock}>
          <p className={styles.labelParameter}>Фактична вага, кг</p>

          <p className={styles.value}>0,5</p>
        </div>

        <div className={styles.parameterBlock}>
          <p className={styles.labelParameter}>Габарити, см</p>

          <p className={styles.value}>21х17х4</p>
        </div>

        <div className={styles.parameterBlock}>
          <p className={styles.labelParameter}>Об’ємна вага, кг</p>

          <p className={styles.value}>0,33</p>
        </div>
      </div>
    </div>
  );
};

export { ParametersInfo };
