import { useField } from 'formik';

import type { FC, KeyboardEvent } from 'react';

import type { TextFieldProps } from './text';

import { TextField } from './text';

type NumberFieldProps = TextFieldProps & {
  // Trim after maxLength
  maxLength?: number;

  // Allow only value > 0
  positive?: boolean;
};

const NumberField: FC<NumberFieldProps> = ({
  maxLength,
  positive,
  ...props
}) => {
  const [{ onChange }] = useField(props.name);

  if (maxLength) {
    props.max = lengthToMaxNumber(maxLength);

    props.onChange = event => {
      const value = event.target.value;

      event.target.value =
        value.length >= (props.max as any)
          ? value.substring(0, props.max as any)
          : value;

      onChange(event);
    };
  }

  if (positive) {
    props.min = 0;
  }

  return (
    <TextField
      type='tel'
      pattern='[0-9]*'
      inputMode='numeric'
      maxLength={maxLength}
      onKeyDown={e => {
        // @ts-expect-error Because typescript mismatch textarea|input events
        if (isForbiddenNumberPressed(e)) {
          e.preventDefault();

          return;
        }

        // @ts-expect-error Because typescript mismatch textarea|input events
        props.onKeyDown?.(e);
      }}
      {...props}
    />
  );
};

function lengthToMaxNumber(maxLength: number): number {
  return +Array.from({ length: maxLength }, () => '9').join('');
}

function isForbiddenNumberPressed<T>(e: KeyboardEvent<T>) {
  return ['e', '+', '-', ',', '.'].includes(e.key.toLowerCase());
}

export type { NumberFieldProps };

export { NumberField };
