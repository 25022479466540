import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router';

import type { State } from '@app/store';

import { setIsNumberAlreadyUser } from '@pages/start-page/model';

const useNumberAlreadyUsedModalProps = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isNumberAlreadyUsed } = useSelector(
    (state: State) => state.startPage
  );

  const close = () => {
    dispatch(setIsNumberAlreadyUser(false));
  };

  return {
    open: isNumberAlreadyUsed,
    isTablet: location.pathname.includes('tablet'),
    close
  };
};

export { useNumberAlreadyUsedModalProps };
