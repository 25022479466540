import styles from './desktop.module.scss';

/**
 * <Desktop />
 */
const Desktop = ({ title }: { title?: string }) => (
  <div className={styles.container}>
    <h1
      dangerouslySetInnerHTML={{
        __html: title
          ? `${title}`
          : `Поки що сайт доступний<br />лише з мобільного 🙌🏻`
      }}
    />
  </div>
);

export { Desktop };
