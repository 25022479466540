import type { FC } from 'react';

import { useDispatch } from 'react-redux';

import {
  getCities,
  getBranch,
  getStreets,
  getParcelLocker
} from '@entities/delivery';

import { Field } from '@shared/form';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import type { useAddReceiverInfoUt } from '../model';

import styles from './add-receiver-info-ut-form.module.scss';

type DelyveryDescriptionCheckProps = {
  form: ReturnType<typeof useAddReceiverInfoUt>['form'];
};

const CitySearchInput: FC<DelyveryDescriptionCheckProps> = ({ form }) => {
  const dispatch = useDispatch();
  const { searchCitiesResult, searchCitiesPending } = useTypedSelector(
    state => state.delivery
  );

  const onCitySearchQueryChange = (query: string) => {
    // if (query.length === 0) {
    //   dispatch(getBranch.reset());
    //   dispatch(getParcelLocker.reset());
    //   dispatch(getStreets.reset());
    // }
    if (query.length < 2) return;

    dispatch(getCities(query));
  };

  const onReset = () => {
    dispatch(getCities.reset());
    dispatch(getBranch.reset());
    dispatch(getParcelLocker.reset());
    dispatch(getStreets.reset());
    form.setFieldValue('street', null);
    form.setFieldValue('branch', null);
    form.setFieldValue('apartments', '');
    form.setFieldValue('building', '');
    form.setFieldValue('parcelLocker', null);
  };

  return (
    <Field.Search
      className={styles.field}
      name='city'
      type='search'
      label='Населений пункт'
      options={searchCitiesResult}
      onQueryChange={onCitySearchQueryChange}
      onBlur={onReset}
      loading={searchCitiesPending}
    />
  );
};

export { CitySearchInput };
