import classNames from 'classnames';

import type { CountryCode } from 'libphonenumber-js/types';

import type { FC, MouseEvent } from 'react';

import { useRef, useState } from 'react';

import { ReactCountryFlag } from 'react-country-flag';

import type { Directory } from '@shared/lib/directory';

import { useClickOutside } from '@shared/lib/use-click-outside';

import type { InputProps } from '@shared/ui/atoms/input';

import { Input } from '@shared/ui/atoms/input';

import styles from './phone.module.scss';

type Country = Omit<Directory, 'id'> & {
  id: CountryCode;
  phonePrefix: string;
};

type PhoneProps = InputProps & {
  onCountryChange?: (country: Country) => void;

  countries: Country[];
};

const Phone: FC<PhoneProps> = ({ countries, onCountryChange, ...props }) => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState<Country>(countries[0]);

  const countriesDivRef = useRef<HTMLLabelElement>(null);

  const onCountryClick = (country: Country, event: MouseEvent) => {
    event.stopPropagation();
    setCountry(country);
    onCountryChange?.(country);

    setOpen(false);
  };

  useClickOutside(countriesDivRef, () => setOpen(false));

  return (
    <Input
      {...props}
      rootRef={countriesDivRef}
      forceFocusedStyles
      classnames={{
        wrapper: classNames(styles.wrapper)
      }}
      className={styles.input}
      style={{
        // @ts-expect-error NOT KNOWN CSS VAR
        '--phone-padding-left': `${country.phonePrefix.length - 1}px`
      }}
    >
      <div className={styles.country}>
        <div className={styles.selected} onClick={() => setOpen(true)}>
          {country && (
            <ReactCountryFlag
              className={styles.flag}
              // style={{ width: 55, height: 40, marginRight: 20 }}
              countryCode={country.id}
              svg
            />
          )}

          <svg
            className={styles.arrowDown}
            width='29'
            height='18'
            viewBox='0 0 29 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0.0334473 3.60597L2.79586 0.966797L14.1112 11.7773L25.4266 0.966797L28.189 3.60597L14.1112 17.0557L0.0334473 3.60597Z'
            />
          </svg>

          <div className={styles.divider} />

          <span className={styles.prefix}>{country.phonePrefix}</span>
        </div>

        {open && (
          <div className={styles.results}>
            {countries.map(country => (
              <div
                className={styles.result}
                key={country.id}
                onClick={e => onCountryClick(country, e)}
              >
                <ReactCountryFlag
                  className={styles.flag}
                  countryCode={country.id}
                  svg
                />

                <span>{country.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </Input>
  );
};

export { Phone };

export type { PhoneProps, Country };
