import type { FC, PropsWithChildren } from 'react';

import { memo, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import styles from './spinner.module.scss';

const Spinner: FC = memo(() => {
  const { isSpinning } = useTypedSelector(state => state.spinner);

  if (!isSpinning) return null;

  return (
    <Portalled>
      <div className={styles.backdrop}>
        <svg
          className={styles.spinner}
          width='76'
          height='76'
          viewBox='0 0 76 76'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M71.75 38.0017C71.7497 45.1289 69.493 52.073 65.3035 57.8389C61.114 63.6047 55.2067 67.8963 48.4283 70.0985C41.6498 72.3007 34.3482 72.3005 27.5699 70.0979C20.7916 67.8953 14.8845 63.6034 10.6954 57.8373C6.50619 52.0712 4.24995 45.127 4.25 37.9998C4.25005 30.8726 6.5064 23.9283 10.6957 18.1623C14.8849 12.3962 20.792 8.10443 27.5704 5.90194C34.3487 3.69945 41.6503 3.69935 48.4288 5.90166'
            stroke='#00D1D2'
            strokeWidth='8'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </Portalled>
  );
});

const Portalled: FC<PropsWithChildren> = ({ children }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export { Spinner };
