import type { FC } from 'react';

import { TabletNavigationBlock } from './ui';

const OdessaNavigationBlock: FC = () => <TabletNavigationBlock city='odessa' />;
const KiyvNavigationBlock: FC = () => <TabletNavigationBlock city='kyiv' />;
const LvivNavigationBlock: FC = () => <TabletNavigationBlock city='lviv' />;
const DniproNavigationBlock: FC = () => <TabletNavigationBlock city='dnipro' />;

export {
  OdessaNavigationBlock,
  KiyvNavigationBlock,
  LvivNavigationBlock,
  DniproNavigationBlock
};
