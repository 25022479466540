import { get } from 'object-path';

import type { ChangeEvent, ReactNode } from 'react';

import { useEffect, useMemo, useRef, useState } from 'react';

import { useClickOutside } from '@shared/lib/use-click-outside';

/**
 * Field selector
 */
type Selector = string | ((option: unknown) => any);

/**
 * Formik
 */
type ControlProps<V = string> = {
  /**
   * Html id
   */
  id?: string;
  /**
   * Control value
   */
  value: V;
  /**
   * Control label
   */
  label?: string;
  /**
   * Root classname
   */
  className?: string;
  /***
   * Is form control disabled
   */
  disabled?: boolean;
  /**
   * Field error
   */
  error?: ReactNode;
  /**
   * Is control touched
   */
  touched?: boolean;
  /**
   * Is has error
   */
  isError?: boolean;
  /**
   * Placeholder
   */
  placeholder?: React.ReactNode;
  /**
   * Is Field required
   */
  required?: boolean;
  /**
   * Change handler
   */
  onChange?: (value: V) => void;
  /**
   * Handle touch
   */
  onTouch?: () => void;
  /**
   * Control tabindex
   */
  tabIndex?: number;
  /**
   * On key down
   */
  onKeyDown?: (value: any) => any;
};

/**
 * Props
 */
type SelectProps = ControlProps<any> & {
  /**
   * Searchable
   */
  searchable?: boolean;
  /**
   * Options list
   */
  options: any[];
  /**
   * Options shape
   */
  shape?: {
    id?: Selector;
    name?: Selector;
  };
  /**
   * Placeholder
   */
  placeholder?: string;
  /**
   * No options label
   */
  noOptionsLabel?: string;
  /**
   * Color
   */
  color?: 'white' | 'milk-white';

  setSearchChange?: string;
};

/**
 * Default selectors
 */
const defaultSelectors = {
  id: 'id',
  name: 'name'
};

/**
 * Use Select
 */
const useSelect = ({
  searchable,
  shape,
  value,
  options,
  disabled,
  onChange,
  placeholder
}: SelectProps) => {
  const [focused, setFocused] = useState(false);

  const selectors = useMemo(
    () => ({
      ...defaultSelectors,
      ...shape
    }),
    [shape]
  );

  const input = useRef<HTMLInputElement>(null as any);
  const reference = useRef<HTMLDivElement>(null as any);

  const select = (selector: any, option: any) =>
    typeof selector == 'string' ? get(option, selector) : selector(option);

  const [selected, setSelected] = useState<any>();

  const [search, setSearch] = useState(selected?.name || '');

  const hasValue =
    (typeof value === 'string'
      ? !!value && value !== ''
      : !!value && !!value?.id && value?.id !== '') ||
    value === 0 ||
    (!!search && search !== '');

  const filteredOptions = useMemo(
    () =>
      (!selected || select(selectors.name, selected) !== search) && searchable
        ? options.filter(option =>
            select(selectors.name, option)
              .toLowerCase()
              .includes(search.toLowerCase())
          )
        : options,
    [selected, search, searchable, options]
  );

  const onClick = () => {
    if (disabled) return;

    if (focused) {
      input.current?.blur();
    } else {
      input.current?.focus();
    }

    setFocused(!focused);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const onOptionClick = (option: SelectProps['options'][number]) => {
    onChange?.(select(selectors.id, option));

    setSearch(option.name);

    setFocused(false);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!searchable) return;

    setSearch(event.target.value);
  };

  useClickOutside(reference, () => {
    if (focused) {
      setFocused(false);

      if (selected?.name !== search) {
        setSearch('');
        onChange?.({ id: '', name: '' });
      }
    }
  });

  useEffect(() => {
    const selected = options?.find(one => select(selectors.id, one) == value);

    setSearch(selected?.name || '');
    setSelected(selected);
  }, [options, value]);

  return {
    search,
    filteredOptions,
    input,
    select,
    onBlur,
    focused,
    onClick,
    selected,
    hasValue,
    reference,
    selectors,
    placeholder,
    setSearch,
    onOptionClick,
    onSearchChange
  };
};

export { useSelect };

export type { SelectProps };
