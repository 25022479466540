import type { Parameters } from '@features/delivery/add-parameters';

import type { ReceiverInfo } from '@features/delivery/add-receiver-info';

import type { ReceiverInfoUt } from '@features/delivery/add-receiver-info-ut';

import type { SenderInfo } from '@features/delivery/add-sender-info';

import type { SenderInfoUT } from '@features/delivery/add-sender-info-ut';

class CreateDeliveryProcessState {
  public sender: SenderInfo | null = null;

  public senderUt: SenderInfoUT | null = null;

  public receiver: ReceiverInfo | null = null;

  public receiverUt: ReceiverInfoUt | null = null;

  public params: Parameters | null = null;
}

export { CreateDeliveryProcessState };
