import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { setPricingInfo } from '@features/delivery/add-pricing/model/actions';

import { schema } from '../lib';

import type { Pricing } from '../types';

const useAddPricing = () => {
  const dispatch = useDispatch();

  const form = useFormik<Pricing>({
    initialValues: {
      pricing: '2 EUR',

      deliveryСonditions: 'DAP'
    },
    validationSchema: schema,

    onSubmit: (values, { setSubmitting }) => {
      dispatch(setPricingInfo(values));
      setSubmitting(false);
    }
  });

  const onGoBack = () => {
    dispatch(setPricingInfo.reset());
  };

  return { form, onGoBack };
};

export { useAddPricing };
