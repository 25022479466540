import { useNavigate } from 'react-router';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import useWindowDimensions from '@shared/lib/use-window-dimensions';

import styles from './add-review-info-form.module.scss';

const SenderInfo = ({ link, image }: { link: string; image: string }) => {
  const { sender } = useTypedSelector(state => state.createDeliveryProcess);
  const { transliteration } = useTypedSelector(state => state.delivery);
  const { phone } = useTypedSelector(state => state.startPage);

  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const onClickEdit = () => {
    navigate(link);
  };

  return (
    <div className={styles.grid}>
      {width > 470 && (
        <>
          <p className={styles.gridSubtitle}>Транслітерація/переклад</p>

          <div className={styles.labels}>
            <p>Телефон:</p>

            <p>ПІБ:</p>

            {/*<p>Індекс:</p>*/}

            {/*<p>Місто:</p>*/}

            <p>Email:</p>

            <p>Тип контрагента:</p>
          </div>

          <div className={styles.values}>
            <p>{phone}</p>

            <p>{`${sender?.firstname} ${sender?.surname}`}</p>

            {/*<p>{sender?.postIndex}</p>*/}

            {/*<p>{sender?.city?.label}</p>*/}

            <p>{sender?.email}</p>

            <p>Юридична особа</p>
          </div>

          <div className={styles.translate}>
            <p>{phone}</p>

            <p>
              {`${transliteration?.sender?.name} ${transliteration?.sender?.surname}`}
            </p>

            {/*<p>{sender?.postIndex}</p>*/}

            {/*<p>{transliteration?.sender?.city}</p>*/}

            <p>{sender?.email}</p>

            <p>Legal entity</p>
          </div>
        </>
      )}

      <div className={styles.mob}>
        <p className={styles.label}>Телефон:</p>

        <div className={styles.valuesBlock}>
          <p>{phone}</p>

          <p>{phone}</p>
        </div>

        <p className={styles.label}>ПІБ:</p>

        <div className={styles.valuesBlock}>
          <p>{`${sender?.firstname} ${sender?.surname}`}</p>

          <p>
            {`${transliteration?.sender?.name} ${transliteration?.sender?.surname}`}
          </p>
        </div>

        {/*<p className={styles.label}>Індекс:</p>*/}

        {/*<div className={styles.valuesBlock}>*/}

        {/*  <p>{sender?.postIndex}</p>*/}

        {/*  <p>{sender?.postIndex}</p>*/}

        {/*</div>*/}

        {/*<p className={styles.label}>Місто:</p>*/}

        {/*<div className={styles.valuesBlock}>*/}

        {/*  <p>{sender?.city?.label}</p>*/}

        {/*  <p>{transliteration?.sender?.city}</p>*/}

        {/*</div>*/}

        <p className={styles.label}>Email:</p>

        <div className={styles.valuesBlock}>
          <p>{sender?.email}</p>

          <p>{sender?.email}</p>
        </div>

        <p className={styles.label}>Тип контрагента:</p>

        <div className={styles.valuesBlock}>
          <p>Юридична особа</p>

          <p>Legal entity</p>
        </div>
      </div>

      <div onClick={onClickEdit} className={styles.editButton}>
        <p>Редагувати</p>

        <img className={styles.editImage} src={image} alt='' />
      </div>
    </div>
  );
};

export { SenderInfo };
