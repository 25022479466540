import classNames from 'classnames';

import { Outlet } from 'react-router-dom';

import { Desktop } from '@app/ui';

import { steps, stepsUt } from '@pages/delivery/config';

import { hoc } from '@shared/lib';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { Header } from '@shared/ui/header';

import { Stepper } from '@shared/ui/stepper';

import { useDeliveryPage } from '../model';

import { DeliveryModal } from './modal';

import styles from './page.module.scss';

const DeliveryPage = hoc(useDeliveryPage, ({ activeStep, phone }) => {
  const { isTablet, isInternational } = useTypedSelector(
    state => state.startPage
  );

  if (!phone)
    return (
      <Desktop title='Скануй QR на листівках або поштоматі.<br />А далі - магія!' />
    );

  return (
    <>
      <Header />

      <Stepper
        steps={!isInternational && isTablet ? stepsUt : steps}
        activeStep={activeStep}
      />

      <div className={classNames('container', styles.page)}>
        <Outlet />
      </div>

      <DeliveryModal />
    </>
  );
});

export { DeliveryPage };
