import { useField } from 'formik';

import type { ReceiverInfoUt } from '../types';

const useIsCityEntered = () =>
  !!useField<ReceiverInfoUt['city']>('city')[0].value;

const useIsStreetEntered = () =>
  !!useField<ReceiverInfoUt['street']>('street')[0].value;

const useIsBuildingEntered = () =>
  !!useField<ReceiverInfoUt['building']>('building')[0].value;

const useIsBranchEntered = () =>
  !!useField<ReceiverInfoUt['branch']>('branch')[0].value;

const useIsParcelLockerEntered = () =>
  !!useField<ReceiverInfoUt['parcelLocker']>('parcelLocker')[0].value;

const useCityId = () => useField<ReceiverInfoUt['city']>('city')[0].value?.id;

export {
  useIsCityEntered,
  useIsStreetEntered,
  useIsBuildingEntered,
  useIsBranchEntered,
  useIsParcelLockerEntered,
  useCityId
};
