import type { ReactNode } from 'react';

/**
 * Checkbox Props
 */
type CheckboxProps = {
  /**
   * Classname
   */
  className?: {
    container?: string;
    box?: string;
    label?: string;
    checkmark?: string;
  };
  /**
   * Label
   */
  label?: ReactNode;
  /**
   * Is error
   */
  isError?: boolean;
  /**
   * Disabled
   */
  disabled?: boolean;
  /**
   * Value
   */
  value: boolean;
  /**
   * On change
   */
  onChange: (value: boolean) => void;
};

/**
 * Use Checkbox
 */
const useCheckbox = ({ disabled, value, onChange }: CheckboxProps) => {
  const onClick = () => {
    if (disabled) return;

    onChange?.(!value);
  };

  return { onClick };
};

export { useCheckbox };

export type { CheckboxProps };
