import classNames from 'classnames';

import type { FC } from 'react';

import type { ButtonProps } from '@shared/ui/atoms/button';

import { Button } from '@shared/ui/atoms/button';

import styles from './stepper-form-controls.module.scss';

type StepperFormControlsProps = {
  cancelText?: string;

  submitText?: string;

  goBackText?: string;

  dirty: boolean;

  canSubmit: boolean;
  submitProps?: Partial<ButtonProps>;

  resetProps?: Partial<ButtonProps>;
  goBackProps?: Partial<ButtonProps>;

  onGoBackClick?: ButtonProps['onClick'];
};

const StepperFormControls: FC<StepperFormControlsProps> = ({
  submitText,
  cancelText,
  goBackText,
  canSubmit,
  dirty,
  onGoBackClick,
  goBackProps = {},
  submitProps = {},
  resetProps = {}
}) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      {!dirty ? (
        <Button
          {...goBackProps}
          className={classNames(styles.reset, goBackProps?.className)}
          variant={goBackProps?.variant ?? 'secondary'}
          onClick={goBackProps?.onClick ?? onGoBackClick}
          type='button'
        >
          {goBackText}
        </Button>
      ) : (
        <Button
          {...resetProps}
          className={classNames(styles.reset, resetProps?.className)}
          variant={resetProps?.variant ?? 'secondary'}
          type='reset'
        >
          {cancelText}
        </Button>
      )}

      <Button
        {...submitProps}
        className={classNames(styles.submit, submitProps?.className)}
        variant={submitProps?.variant ?? 'primary'}
        type={submitProps?.type ?? 'submit'}
        disabled={submitProps?.disabled ?? !canSubmit}
      >
        {submitText}
      </Button>
    </div>
  </footer>
);

StepperFormControls.defaultProps = {
  cancelText: 'Очистити',
  submitText: 'Далі',
  goBackText: 'Назад'
};

export { StepperFormControls };
