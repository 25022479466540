import type { routes } from '@shared/config/routes';

/**
 * General state
 */
class StartPageState {
  /**
   * City
   */
  public city: keyof typeof routes.tablet | undefined;

  /**
   * Is otp sent
   */
  public isOtpSent = false;

  /**
   * Is number already used
   */
  public isNumberAlreadyUsed = false;

  /**
   * Is otp incorrect
   */
  public isOtpIncorrect = false;

  /**
   * For redirect to main after delivery
   */
  public isTablet = false;

  /**
   * For redirect to main after delivery
   */
  public isInternational = false;

  /**
   * Is  internation clicked
   */
  public isInternationalClicked = false;

  /**
   * Phone
   */
  public phone = '';
}

export { StartPageState };
