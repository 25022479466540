import classNames from 'classnames';

import { useIsCityEntered } from '@features/delivery/add-receiver-info/model';

import { Field, Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import {
  useAddReceiverInfoUt,
  useIsBuildingEntered,
  useIsStreetEntered
} from '../model';

import styles from './add-receiver-info-ut-form.module.scss';

import { ReceivCheck } from './check-receiv.component';

import { BranchSearchInput } from './search-branch.component';

import { CitySearchInput } from './search-city.component';

import { ParcelLockerInput } from './search-parcelLocker.component';

import { CityStreetSearchInput } from './search-street.component';

const AddReceiverInfoUtForm = hoc(
  useAddReceiverInfoUt,
  ({ form, isValid, onGoBack }) => (
    <Form
      className={styles.form}
      style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
      use={form}
    >
      <h3 className={styles.title}>
        Інформація про отримувача — це друг, що отримає сюрприз
      </h3>

      <div className={styles.grid}>
        <Field.Text className={styles.field} name='lastName' label='Прізвище' />

        <Field.Text className={styles.field} name='firstName' label="Ім'я" />
      </div>

      <div className={styles.grid}>
        <Field.PhoneInput
          className={classNames(styles.input, {
            [styles.inputTablet]: true,
            [styles.inputErrorTablet]: !!form.errors.phone
          })}
          label='Телефон отримувача'
          isTablet
          value={form.values.phone}
          isError={
            (!!form.errors.phone &&
              !!form.values.phone[3] &&
              form.values?.phone[3] !== '0') ||
            (!!form.values.phone[3] &&
              form.values?.phone.replace('+', '')?.length !== 12)
          }
          error={
            (!!form.errors.phone &&
              !!form.values.phone[3] &&
              form.values?.phone[3] !== '0') ||
            (!!form.values.phone[3] &&
              form.values?.phone.replace('+', '')?.length !== 12)
              ? form.errors.phone
              : ''
          }
          onChange={value => {
            value.trim()[3]
              ? form.setFieldValue('phone', value.replaceAll(' ', ''))
              : form.setFieldValue('phone', '');
          }}
        />
      </div>

      <div>
        <h2 className={styles.blocksTitel}>Місце отримання</h2>

        <div className={styles.gridBox}>
          <ReceivCheck form={form} />
        </div>
      </div>

      <div className={styles.grid}>
        <CitySearchInput form={form} />

        {form?.values?.receivCheckId === 1 && <BranchSearchInput />}

        {form?.values?.receivCheckId === 2 && <HouseSection />}

        {form?.values?.receivCheckId === 3 && <ParcelLockerInput />}
      </div>

      <Field.Text
        className={styles.field}
        name='additionalInfo'
        label='Додаткова інформація адреси Отримувача'
        rows={10}
        textarea
      />

      <StepperFormControls
        dirty={form.dirty}
        canSubmit={isValid}
        onGoBackClick={onGoBack}
      />
    </Form>
  )
);

const ParcelLockerSection = () => {
  const isCityEntered = useIsCityEntered();

  return (
    <Field.Text
      className={styles.field}
      name='parcelLocker'
      label='Поштомат'
      disabled={!isCityEntered}
    />
  );
};

const HouseSection = () => {
  const isBuildingEntered = useIsBuildingEntered();
  const isStreetEntered = useIsStreetEntered();

  return (
    <>
      <CityStreetSearchInput />

      <Field.Text
        className={styles.field}
        name='building'
        type='text'
        label='Будинок'
        disabled={!isStreetEntered}
      />

      <Field.Number
        className={styles.field}
        name='apartments'
        label='Квартира'
        positive
        disabled={!isBuildingEntered}
      />
    </>
  );
};

export { AddReceiverInfoUtForm };
