import { useField } from 'formik';

import type { FC } from 'react';

import type { InputProps } from '@shared/ui/atoms/input';

import { Input } from '@shared/ui/atoms/input';

type TextFieldProps = Omit<InputProps, 'value' | 'onBlur' | 'name'> & {
  name: string;
  toUpperCase?: boolean;
};

const TextField: FC<TextFieldProps> = ({
  name: formName,
  toUpperCase,
  ...props
}) => {
  const [{ name, value, onBlur, onChange }, { error, touched }] =
    useField(formName);

  return (
    <Input
      name={name}
      value={value}
      onChange={event => {
        if (toUpperCase) {
          event.target.value = event.target.value?.toUpperCase();
        }

        onChange(event);
      }}
      onBlur={onBlur}
      isError={touched && !!error}
      helperText={!props.disabled && touched ? error : ''}
      {...props}
    />
  );
};

export { TextField };

export type { TextFieldProps };
