/**
 * Build enviroment
 */
const enviroment = {
  stage: process.env.stage,
  development: process.env.NODE_ENV != 'production',
  startPageServiceApiKey: process.env.REACT_APP_START_PAGE_SERVICE_API_KEY,
  startPageServiceApiUrl: process.env.REACT_APP_START_PAGE_SERVICE_API_URL,
  translationApiUrl: process.env.REACT_APP_TRANSLATION_API_URL,
  translationApiKey: process.env.REACT_APP_TRANSLATION_API_KEY
};

export { enviroment };
