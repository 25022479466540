import { deliveryRoutes } from './delivery.routes';

import { mobInternationRoutes } from './mob-internation.routes';

import { mobIntrRoutes } from './mob-intr.routes';

import { mobUkRoutes } from './mob-uk.routes';

import { tabletRoutes } from './tablet.routes';

const routes = {
  tablet: tabletRoutes,
  mobUkRoutes: mobUkRoutes,
  mobInRoutes: mobInternationRoutes,
  mobInTrRoutes: mobIntrRoutes,
  delivery: deliveryRoutes
};

export { routes };
