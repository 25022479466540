import { Field, Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import { useAddSenderInfo } from '../model';

import styles from './add-sender-info-form.module.scss';

const AddSenderInfoForm = hoc(
  useAddSenderInfo,
  ({ form, isValid, onGoBack }) => (
    <Form
      className={styles.form}
      style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
      use={form}
    >
      <h3 className={styles.title}>
        Інформація про відправника, тобто про тебе!
      </h3>

      <div className={styles.grid}>
        <Field.Text
          className={styles.field}
          name='surname'
          type='text'
          label='Прізвище'
        />

        <Field.Text
          className={styles.field}
          name='firstname'
          type='text'
          label='Ім’я'
        />
      </div>

      <Field.Text
        className={styles.field}
        name='email'
        type='email'
        label='Email'
      />

      {/*<div className={styles.grid}>*/}

      {/*  <Field.Number*/}

      {/*    className={styles.field}*/}

      {/*    name='postIndex'*/}

      {/*    label='Індекс'*/}

      {/*    maxLength={5}*/}

      {/*    positive*/}

      {/*  />*/}

      {/*  <CitySearchInput />*/}

      {/*</div>*/}

      {/*<div className={styles.grid}>*/}

      {/*  <CityStreetSearchInput />*/}

      {/*  <HouseSection />*/}

      {/*</div>*/}

      <StepperFormControls
        dirty={form.dirty}
        canSubmit={isValid}
        onGoBackClick={onGoBack}
      />
    </Form>
  )
);

// const HouseSection = () => {
// const isBuildingEntered = useIsBuildingEntered();
// const isStreetEntered = useIsStreetEntered();

// return (
//   <div className={styles.grid}>
//     <Field.Text
//       className={styles.field}
//       name='building'
//       type='text'
//       label='Будинок'
//       disabled={!isStreetEntered}
//     />
//
//     <Field.Number
//       className={styles.field}
//       name='apartments'
//       label='Квартира'
//       positive
//       disabled={!isBuildingEntered}
//     />
//   </div>
// );
// };

export { AddSenderInfoForm };
