import classNames from 'classnames';

import type { FC } from 'react';

import { Fragment } from 'react';

import styles from './stepper.module.scss';

type StepperProps = {
  steps: string[];
  activeStep: number;
};

const Stepper: FC<StepperProps> = ({ activeStep, steps }) => (
  <div className={styles.stepper}>
    <div className={styles.container}>
      {steps.map((label, index) => (
        <Fragment key={label}>
          <div
            className={classNames(styles.step, {
              [styles.current]: index === activeStep,
              [styles.completed]: activeStep > index
            })}
          >
            <div
              className={classNames(styles.counter, {
                [styles.current]: index === activeStep,
                [styles.completed]: activeStep > index
              })}
            >
              {index + 1}
            </div>

            <span className={styles.stepLabel}>{label}</span>
          </div>

          {index !== steps.length - 1 && <div className={styles.line} />}
        </Fragment>
      ))}
    </div>
  </div>
);

export { Stepper };
