type MobInternationRoutes = {
  odesa: () => string;
  dnipro: () => string;
  kyiv: () => string;
  lviv: () => string;
};

const mobInternationRoutes: MobInternationRoutes = {
  odesa: () => '/odesa-in',
  dnipro: () => '/dnipro-in',
  kyiv: () => '/kyiv-in',
  lviv: () => '/lviv-in'
};

export { mobInternationRoutes };
