import { make } from 'redux-chill';

import type {
  Branch,
  City,
  ParcelLocker,
  Street,
  Transliteration
} from '../types';

const getCities = make('[delivery] search cities')
  .stage((query: string) => ({ query }))
  .stage('success', (cities: City[]) => ({ cities }))
  .stage('failure')
  .stage('reset');

const getStreets = make('[delivery] search streets')
  .stage((query: string, ref: string) => ({ query, ref }))
  .stage('success', (streets: Street[]) => ({ streets }))
  .stage('failure')
  .stage('reset');

const getBranch = make('[delivery] search branch')
  .stage((ref: string) => ({ ref }))
  .stage('success', (branches: Branch[]) => ({ branches }))
  .stage('failure')
  .stage('reset');

const getParcelLocker = make('[delivery] search parcelLocker')
  .stage((query: string, ref: string) => ({ query, ref }))
  .stage('success', (parcelLockers: ParcelLocker[]) => ({ parcelLockers }))
  .stage('failure')
  .stage('reset');

const getDelyveryDescription = make('[delivery] get delyveryDescription');

const createDelivery = make('[delivery] confirm create delivery')
  .stage((_: void) => ({}))
  .stage('success', (trackNumber: string) => ({ trackNumber }))
  .stage('fail')
  .stage('reset');

const createDeliveryUt = make('[delivery] confirm create deliveryUt')
  .stage((_: void) => ({}))
  .stage('success', (trackNumber: string) => ({ trackNumber }))
  .stage('fail')
  .stage('reset');

const setTransliteration = make('[delivery] set transliteration').stage(
  (transliteration: Transliteration) => ({ transliteration })
);

export {
  getCities,
  getStreets,
  getBranch,
  getParcelLocker,
  getDelyveryDescription,
  createDelivery,
  createDeliveryUt,
  setTransliteration
};
