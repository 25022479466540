/* eslint-disable react/jsx-newline */
import classNames from 'classnames';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import { useCreateDeliveryModal } from '../../model';

import styles from './modal.module.scss';

const DeliveryModal = hoc(
  useCreateDeliveryModal,
  ({
    open,
    status,
    trackNumber,
    onClose,
    onErrorClose,
    isInternational,
    isTablet
  }) => {
    if (!open) return null;

    if (status === 'success' && trackNumber) {
      return (
        <div className={styles.modal}>
          <div className={styles.container}>
            <h3 className={styles.title}>
              {!isInternational && isTablet
                ? 'Ми створили відправлення по Україні! '
                : 'Ми створили заявку на відправку міжнародної посилки!'}
            </h3>

            <p className={styles.description}>
              Номер твого відправлення:{' '}
              <span className={styles.trackNumber}>{trackNumber}</span>.
              <br className={styles.desktop} />
              Поділись ним, будь ласка, з нашим експертом — він допоможе
              надрукувати <br className={styles.desktop} />
              стікер для відправки😍
            </p>

            <Button
              className={styles.button}
              variant='primary'
              onClick={onClose}
            >
              Зрозуміло, дякую!
            </Button>
          </div>
        </div>
      );
    }

    if (status === 'error') {
      return (
        <div className={styles.modal}>
          <div className={styles.container}>
            <h3 className={styles.title}>
              🙁 Щось пішло не так… Зверніться в підтримку
            </h3>

            <Button
              className={classNames(styles.button, styles.error)}
              variant='primary'
              onClick={onErrorClose}
            >
              Зрозуміло, дякую!
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }
);

export { DeliveryModal };
