import type { FC } from 'react';

import { cities } from './data';

import { CityUkStartBlockProps, StartPageBlock } from './ui';

const OdessaStartBlock: FC = () => <StartPageBlock city={cities.odesa} />;
const KiyvStartBlock: FC = () => <StartPageBlock city={cities.kyiv} />;
const LvivStartBlock: FC = () => <StartPageBlock city={cities.lviv} />;
const DniproStartBlock: FC = () => <StartPageBlock city={cities.dnipro} />;
const CityUkStartBlock: FC = () => <CityUkStartBlockProps />;

export {
  OdessaStartBlock,
  KiyvStartBlock,
  LvivStartBlock,
  DniproStartBlock,
  CityUkStartBlock
};
