import { useField } from 'formik';

import type { FC } from 'react';

import type { Country, PhoneProps } from '@shared/ui/molecules/phone';

import { Phone } from '@shared/ui/molecules/phone';

type PhoneFieldProps = Omit<PhoneProps, 'value' | 'onBlur' | 'name'> & {
  name: string;
};

type PhoneFieldValue = { country: Country; value: string };

const PhoneField: FC<PhoneFieldProps> = ({ name: formName, ...props }) => {
  const [
    {
      name,
      value: { value, country } = { value: '', country: props.countries[0] }
    },
    { error, touched },
    { setValue, setTouched }
  ] = useField<PhoneFieldValue>(formName);

  return (
    <Phone
      name={name}
      value={value}
      onChange={event => setValue({ value: event.target.value, country })}
      onCountryChange={country => setValue({ value, country })}
      onBlur={() => setTouched(true)}
      isError={touched && !!error}
      helperText={!props.disabled && touched ? error : ''}
      {...props}
    />
  );
};

export { PhoneField };

export type { PhoneFieldProps, PhoneFieldValue };
