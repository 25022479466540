import { AddParametersForm } from '@features/delivery/add-parameters';

import { AddReceiverInfoForm } from '@features/delivery/add-receiver-info';

import { AddReceiverInfoUtForm } from '@features/delivery/add-receiver-info-ut';

import {
  contragentTypes,
  countries
} from '@features/delivery/add-receiver-info/config';

import { AddSenderInfoForm } from '@features/delivery/add-sender-info';

import { AddSenderInfoUtForm } from '@features/delivery/add-sender-info-ut';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

const DeliverySenderPage = () => {
  //TODO: Figure how to not use internal state of @processes in @pages
  const { sender } = useTypedSelector(state => state.createDeliveryProcess);

  return (
    <AddSenderInfoForm
      initialValues={
        sender ?? {
          surname: '',
          firstname: '',

          email: ''

          // postIndex: '',

          // city: null,

          // street: null,

          // building: '',

          // apartments: ''
        }
      }
    />
  );
};

const DeliverySenderUtPage = () => {
  //TODO: Figure how to not use internal state of @processes in @pages
  const { senderUt } = useTypedSelector(state => state.createDeliveryProcess);

  return (
    <AddSenderInfoUtForm
      initialValues={
        senderUt ?? {
          surname: '',
          firstname: ''
        }
      }
    />
  );
};

const DeliveryReceiverPage = () => {
  //TODO: Figure how to not use internal state of @processes in @pages
  const { receiver } = useTypedSelector(state => state.createDeliveryProcess);

  return (
    <AddReceiverInfoForm
      initialValues={
        receiver ?? {
          phone: { value: '', country: countries[0] },

          email: '',

          type: contragentTypes[0],

          lastName: '',

          firstName: '',

          country: null,

          city: '',

          cityIndex: '',

          street: '',

          building: '',

          additionalInfo: ''
        }
      }
    />
  );
};

const DeliveryReceiverUtPage = () => {
  //TODO: Figure how to not use internal state of @processes in @pages
  const { receiverUt } = useTypedSelector(state => state.createDeliveryProcess);

  return (
    <AddReceiverInfoUtForm
      initialValues={
        receiverUt ?? {
          phone: '',

          lastName: '',

          firstName: '',

          receivCheckId: 1,

          city: null,

          additionalInfo: '',

          branch: null,

          //address

          street: null,

          building: '',

          apartments: '',

          //parcelLocker

          parcelLocker: null
        }
      }
    />
  );
};

const DeliveryParametersPage = () => {
  //TODO: Figure how to not use internal state of @processes in @pages
  const { params } = useTypedSelector(state => state.createDeliveryProcess);

  return (
    <AddParametersForm
      initialValues={{ delyveryInfo: params?.delyveryInfo ?? [] }}
    />
  );
};

export {
  DeliveryReceiverPage,
  DeliveryReceiverUtPage,
  DeliverySenderPage,
  DeliverySenderUtPage,
  DeliveryParametersPage
};
