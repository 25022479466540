import type {
  City,
  DeliveryDescription,
  Street,
  Branch,
  ParcelLocker,
  Transliteration
} from '../types';

class DeliveryState {
  public searchCitiesResult: City[] = [];

  public searchCitiesPending = false;

  public searchStreetsResult: Street[] = [];

  public searchStreetsPending = false;

  public searchBranchResult: Branch[] = [];

  public searchBranchPending = false;

  public searchParcelLockerResult: ParcelLocker[] = [];

  public searchParcelLockerPending = false;

  public deliveryDescription: DeliveryDescription[] = [];

  public createDeliveryTractNumber: string | null = null;

  public createDeliveryFinished = false;

  public transliteration: Transliteration | null = null;
}

export { DeliveryState };
