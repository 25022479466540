import { useNavigate } from 'react-router';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import useWindowDimensions from '@shared/lib/use-window-dimensions';

import styles from './add-review-info-form.module.scss';

const RecipientInfo = ({ link, image }: { link: string; image: string }) => {
  const { receiver } = useTypedSelector(state => state.createDeliveryProcess);
  const { transliteration } = useTypedSelector(state => state.delivery);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const onClickEdit = () => {
    navigate(link);
  };

  return (
    <div className={styles.grid}>
      {width > 470 && (
        <>
          <div className={styles.labels}>
            <p>Телефон:</p>

            <p>Email:</p>

            <p>Ім’я:</p>

            <p>Індекс:</p>

            <p>Населений пункт:</p>

            <p>Країна:</p>

            <p>Адреса:</p>

            <p>Тип контрагента:</p>
          </div>

          <div className={styles.values}>
            <p>
              {`${receiver?.phone?.country?.phonePrefix}${receiver?.phone?.value}`}
            </p>

            <p>{receiver?.email}</p>

            <p>{`${receiver?.firstName} ${receiver?.lastName}`}</p>

            <p>{receiver?.cityIndex}</p>

            <p>{receiver?.city}</p>

            <p>{receiver?.country?.label}</p>

            <p>{`${receiver?.street}, ${receiver?.building}`}</p>

            <p>Приватна особа</p>
          </div>

          <div className={styles.translate}>
            <p>{`${receiver?.phone?.country?.phonePrefix}${receiver?.phone?.value}`}</p>

            <p>{receiver?.email}</p>

            <p>{`${transliteration?.receiver?.name} ${transliteration?.receiver?.surname}`}</p>

            <p>{receiver?.cityIndex}</p>

            <p>{transliteration?.receiver?.city}</p>

            <p>{transliteration?.receiver?.country}</p>

            <p>{`${transliteration?.receiver?.street}, ${transliteration?.receiver?.building}`}</p>

            <p>Private person</p>
          </div>
        </>
      )}

      <div className={styles.mob}>
        <p className={styles.label}>Телефон:</p>

        <div className={styles.valuesBlock}>
          <p>{`${receiver?.phone?.country?.phonePrefix}${receiver?.phone?.value}`}</p>

          <p>{`${receiver?.phone?.country?.phonePrefix}${receiver?.phone?.value}`}</p>
        </div>

        <p className={styles.label}>Email:</p>

        <div className={styles.valuesBlock}>
          <p>{receiver?.email}</p>

          <p>{receiver?.email}</p>
        </div>

        <p className={styles.label}>Ім’я:</p>

        <div className={styles.valuesBlock}>
          <p>{`${receiver?.firstName} ${receiver?.lastName}`}</p>

          <p>{`${transliteration?.receiver?.name} ${transliteration?.receiver?.surname}`}</p>
        </div>

        <p className={styles.label}>Індекс:</p>

        <div className={styles.valuesBlock}>
          <p>{receiver?.cityIndex}</p>

          <p>
            <p>{receiver?.cityIndex}</p>
          </p>
        </div>

        <p className={styles.label}>Населений пункт:</p>

        <div className={styles.valuesBlock}>
          <p>{receiver?.city}</p>

          <p>{transliteration?.receiver?.city}</p>
        </div>

        <p className={styles.label}>Країна:</p>

        <div className={styles.valuesBlock}>
          <p>{receiver?.country?.label}</p>

          <p>{transliteration?.receiver?.country}</p>
        </div>

        <p className={styles.label}>Адреса:</p>

        <div className={styles.valuesBlock}>
          <p>{`${receiver?.street}, ${receiver?.building}`}</p>

          <p>{`${transliteration?.receiver?.street}, ${transliteration?.receiver?.building}`}</p>
        </div>

        <p className={styles.label}>Тип контрагента:</p>

        <div className={styles.valuesBlock}>
          <p>Приватна особа</p>

          <p>Private person</p>
        </div>
      </div>

      <div onClick={onClickEdit} className={styles.editButton}>
        <p>Редагувати</p>

        <img className={styles.editImage} src={image} alt='' />
      </div>
    </div>
  );
};

export { RecipientInfo };
