import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useLocation, useNavigate } from 'react-router';

import { setDeliverySenderInfo } from '@features/delivery/add-sender-info';

import { createDelivery } from '@entities/delivery';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { stepsRoutes, stepsRoutesUt } from '../config';

import { resetDeliveryPage } from './actions';

const useDeliveryStepperGuard = () => {
  const navigate = useNavigate();

  const { isInternational, isTablet } = useTypedSelector(
    state => state.startPage
  );

  useEffect(() => {
    navigate(!isInternational && isTablet ? stepsRoutesUt[0] : stepsRoutes[0]);
  }, []);
};

const useCreateDeliveryModal = () => {
  const dispatch = useDispatch();

  const { createDeliveryTractNumber, createDeliveryFinished } =
    useTypedSelector(state => state.delivery);

  const { isInternational, isTablet } = useTypedSelector(
    state => state.startPage
  );

  const onClose = () => {
    dispatch(createDelivery.reset());
    dispatch(setDeliverySenderInfo.reset());
  };

  const onErrorClose = () => {
    dispatch(createDelivery.reset());
  };

  return {
    open: createDeliveryFinished,
    status: (createDeliveryTractNumber ? 'success' : 'error') as
      | 'success'
      | 'error',
    trackNumber: createDeliveryTractNumber,
    onClose,
    onErrorClose,
    isInternational,
    isTablet
  };
};

const useDeliveryPage = () => {
  useDeliveryStepperGuard();

  const dispatch = useDispatch();
  const location = useLocation();

  const { phone, isTablet, isInternational } = useTypedSelector(
    state => state.startPage
  );

  const index =
    !isInternational && isTablet
      ? stepsRoutesUt.indexOf(location.pathname)
      : stepsRoutes.indexOf(location.pathname);

  useEffect(
    () => () => {
      dispatch(resetDeliveryPage());
    },
    []
  );

  return {
    activeStep: index === -1 ? 0 : index,
    phone
  };
};

export { useDeliveryPage, useCreateDeliveryModal };
