type TabletRoutes = {
  (): string;
  odesa: () => string;
  odesaIt: () => string;
  odesaUt: () => string;
  dnipro: () => string;
  dniproIt: () => string;
  dniproUt: () => string;
  kyiv: () => string;
  kyivIt: () => string;
  kyivUt: () => string;
  lviv: () => string;
  lvivIt: () => string;
  lvivUt: () => string;
};

const tabletRoutes: TabletRoutes = () => '/tablet';

tabletRoutes.odesa = () => '/tablet/odessa';
tabletRoutes.odesaIt = () => '/tablet/odessa/it';
tabletRoutes.odesaUt = () => '/tablet/odessa/ut';
tabletRoutes.dnipro = () => '/tablet/dnipro';
tabletRoutes.dniproIt = () => '/tablet/dnipro/it';
tabletRoutes.dniproUt = () => '/tablet/dnipro/ut';
tabletRoutes.kyiv = () => '/tablet/kyiv';
tabletRoutes.kyivIt = () => '/tablet/kyiv/it';
tabletRoutes.kyivUt = () => '/tablet/kyiv/ut';
tabletRoutes.lviv = () => '/tablet/lviv';
tabletRoutes.lvivIt = () => '/tablet/lviv/it';
tabletRoutes.lvivUt = () => '/tablet/lviv/ut';

export { tabletRoutes };
