import type { FormikProps } from 'formik';

import { Form as FormikForm, FormikContext } from 'formik';

import type { FC, FormHTMLAttributes, PropsWithChildren } from 'react';

type FormProps = PropsWithChildren<
  FormHTMLAttributes<HTMLFormElement> & {
    html?: boolean;
    use: FormikProps<any>;
    formRef?: any;
  }
>;

/**
 * Form
 */
const Form: FC<FormProps> = ({ html, use, formRef, children, ...props }) => (
  <FormikContext.Provider value={use}>
    {html ? (
      <FormikForm ref={formRef} {...props}>
        {children}
      </FormikForm>
    ) : (
      children
    )}
  </FormikContext.Provider>
);

Form.defaultProps = {
  html: true
};

export { Form };
