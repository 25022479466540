import type { ReceiverInfo } from '@features/delivery/add-receiver-info';

import { onlyNumbersRegexp } from '@features/delivery/add-receiver-info/lib/regexp';

const withoutSpecChars = (() => {
  const specChars: string[] = [
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '+',
    '=',
    '/',
    '|',
    '\\',
    '.',
    ',',
    '<',
    '>',
    '?',
    '№',
    ';',
    ':',
    '{',
    '}',
    '[',
    ']',
    ' '
  ];

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(String);

  const invalidChars = [...specChars, ...numbers];

  return (value = '') => {
    if (!value) return true;

    return !invalidChars.some(char => value.includes(char));
  };
})();

const onlyNumbers = (value: ReceiverInfo['phone']) => {
  try {
    const phone = value.country.phonePrefix + (value.value ?? '');
    const phoneWithoutPlus = phone.substring(1);

    return onlyNumbersRegexp.test(phoneWithoutPlus);
  } catch (e) {
    return false;
  }
};

export { withoutSpecChars, onlyNumbers };
