import type { Payload } from 'redux-chill';

import { Saga } from 'redux-chill';

import { call, put, select } from 'redux-saga/effects';

import type { State } from '@app/store';

import type { StoreContext } from '@app/store/context';

import type { StartPageState } from '@pages/start-page';

import { setParametersInfo } from '@features/delivery/add-parameters';

import { setPricingInfo } from '@features/delivery/add-pricing';

import { setDeliveryReceiverInfo } from '@features/delivery/add-receiver-info';

import { setDeliveryReceiverInfoUt } from '@features/delivery/add-receiver-info-ut';

import { setReviewInfo } from '@features/delivery/add-review-info';

import { setReviewInfoUt } from '@features/delivery/add-review-info-ut';

import { setDeliverySenderInfo } from '@features/delivery/add-sender-info';

import { setDeliverySenderInfoUt } from '@features/delivery/add-sender-info-ut';

import {
  createDelivery,
  createDeliveryUt,
  setTransliteration
} from '@entities/delivery';

import { enviroment } from '@shared/config/enviroment';

import { routes } from '@shared/config/routes';

import type { Called } from '@shared/lib/saga';

import type { DeepNonNullable } from '@shared/lib/typescript';

import { navigate } from '@shared/router';

import { loading } from '@shared/spinner';

import { Adapter } from '../lib';

function isDeepNonNullable<T>(
  source: T
): source is NonNullable<DeepNonNullable<T>> {
  return !!source;
}

class CreateDeliveryProcessSaga {
  @Saga(createDelivery)
  public *createDelivery(
    _: Payload<typeof createDelivery>,
    { api, history }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const {
        createDeliveryProcess: { receiver: r, sender: s, params },
        startPage: { phone, city },
        delivery: { transliteration }
      }: State = yield select();

      const pathname = history.location.pathname;

      if (
        !isDeepNonNullable(s) ||
        !isDeepNonNullable(r) ||
        !isDeepNonNullable(params) ||
        !isDeepNonNullable(transliteration)
      ) {
        yield put(createDelivery.fail());

        return;
      }

      const dto = Adapter.toCreateDto(
        s,

        r,

        params,

        phone,

        transliteration,

        city as string,

        pathname as string
      );

      const {
        data: {
          ops: [{ data }]
        }
      }: Called<typeof api.startPage.createShipment> = yield call(
        api.startPage.createShipment,
        dto
      );

      if (!data.trac_number) {
        yield put(createDelivery.fail());

        return;
      }

      yield put(createDelivery.success(data.trac_number));
    } catch (e) {
      if (enviroment.development) {
        console.log(e);
      }
      yield put(createDelivery.fail());
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(createDeliveryUt)
  public *createDeliveryUt(
    _: Payload<typeof createDeliveryUt>,
    { api, history }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const {
        createDeliveryProcess: { receiverUt: r, senderUt: s },
        startPage: { phone, city }
      }: State = yield select();

      const pathname = history.location.pathname;

      if (!isDeepNonNullable(s) || !isDeepNonNullable(r)) {
        yield put(createDelivery.fail());

        return;
      }

      const dto = Adapter.toCreateDtoUt(
        s,

        r,

        phone,

        city as string,

        pathname as string
      );

      const {
        data: {
          ops: [{ data }]
        }
      }: Called<typeof api.startPage.createShipmentUt> = yield call(
        api.startPage.createShipmentUt,
        r.receivCheckId === 1
          ? {
              ...dto,
              postal_service: r.branch.label,
              postal_service_ref: r.branch.id
            }
          : r.receivCheckId === 2
          ? {
              ...dto,
              street: r.street.label,
              street_ref: r.street.id,
              building: r.building,
              flat: r.apartments
            }
          : {
              ...dto,
              postal_machine: r.parcelLocker.label,
              postal_machine_ref: r.parcelLocker.id
            }
      );

      if (!data.trac_number) {
        yield put(createDeliveryUt.fail());

        return;
      }

      yield put(createDeliveryUt.success(data.trac_number));
    } catch (e) {
      if (enviroment.development) {
        console.log(e);
      }
      yield put(createDelivery.fail());
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(setReviewInfo.reset)
  public *setReviewInfoReset() {
    yield put(navigate(routes.delivery.pricing()));
  }

  @Saga(setReviewInfoUt.reset)
  public *setReviewInfoUtReset() {
    yield put(navigate(routes.delivery.receiverUt()));
  }

  @Saga(setPricingInfo)
  public *setPricingInfo(
    _: Payload<typeof setTransliteration>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const {
        createDeliveryProcess: { receiver: r, sender: s }
      }: State = yield select();

      if (!isDeepNonNullable(s) || !isDeepNonNullable(r)) {
        yield put(createDelivery.fail());

        return;
      }

      const [
        // senderCity,
        receiverCity,
        receiverCountry
      ]: string[] = yield call(api.translation.translationTextBatch, [
        // s.city.label,
        r.city,
        r.country.label
      ]);

      const [
        senderName,
        senderSurname,
        receiverFirstName,
        receiverSurname,
        receiverStreet,
        receiverBuilding
      ]: string[] = yield call(api.startPage.translationTextBatch, [
        s.firstname,
        s.surname,
        r.firstName,
        r.lastName,
        r.street,
        r.building
      ]);

      yield put(
        setTransliteration({
          sender: {
            name: senderName,
            surname: senderSurname
            // city: senderCity
          },
          receiver: {
            name: receiverFirstName,
            city: receiverCity,
            surname: receiverSurname,
            country: receiverCountry,
            street: receiverStreet,
            building: receiverBuilding
          }
        })
      );
      yield put(navigate(routes.delivery.review()));
    } catch (e) {
      if (enviroment.development) {
        console.log(e);
      }
      yield put(createDelivery.fail());
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(setPricingInfo.reset)
  public *setPricingInfoReset() {
    yield put(navigate(routes.delivery.parameters()));
  }

  @Saga(setParametersInfo)
  public *setParametersInfo() {
    yield put(navigate(routes.delivery.pricing()));
  }

  @Saga(setParametersInfo.reset)
  public *setParametersInfoRest() {
    yield put(navigate(routes.delivery.receiver()));
  }

  @Saga(setDeliveryReceiverInfo)
  public *setDeliveryReceiverInfo() {
    yield put(navigate(routes.delivery.parameters()));
  }

  @Saga(setDeliveryReceiverInfoUt)
  public *setDeliveryReceiverInfoUt() {
    yield put(navigate(routes.delivery.reviewUt()));
  }

  @Saga(setDeliveryReceiverInfo.reset)
  public *setDeliveryReceiverInfoReset() {
    yield put(navigate(routes.delivery.sender()));
  }

  @Saga(setDeliveryReceiverInfoUt.reset)
  public *setDeliveryReceiverInfoUtReset() {
    yield put(navigate(routes.delivery.senderUt()));
  }

  @Saga(setDeliverySenderInfo)
  public *setDeliverySenderInfo() {
    yield put(navigate(routes.delivery.receiver()));
  }

  @Saga(setDeliverySenderInfoUt)
  public *setDeliverySenderInfoUt() {
    yield put(navigate(routes.delivery.receiverUt()));
  }

  @Saga(setDeliverySenderInfo.reset)
  public *resetDeliverySenderInfo() {
    const {
      city = 'odesa',
      isTablet,
      isInternational
    }: StartPageState = yield select((state: State) => state.startPage);

    const routesMap = isTablet
      ? routes.tablet
      : isInternational
      ? routes.mobInRoutes
      : routes.mobUkRoutes;

    const route = (routesMap as any)[city];

    if (!route) return;

    yield put(navigate(route()));
  }

  @Saga(setDeliverySenderInfoUt.reset)
  public *resetDeliverySenderInfoUt() {
    const {
      city = 'odesa',
      isTablet,
      isInternational
    }: StartPageState = yield select((state: State) => state.startPage);

    const routesMap = isTablet
      ? routes.tablet
      : isInternational
      ? routes.mobInRoutes
      : routes.mobUkRoutes;

    const route = (routesMap as any)[city];

    if (!route) return;

    yield put(navigate(route()));
  }
}

export { CreateDeliveryProcessSaga };
