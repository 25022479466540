import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';

import { tabletRoutes } from '@shared/config/routes/tablet.routes';

import { navigate } from '@shared/router';

type TabletNavigationBlockProps = {
  city: string;
};

const useTabletNavigationBlock = (_: TabletNavigationBlockProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const onSendToAnotherCity = () => {
    let route = '/';

    if (location.pathname.includes('odessa')) {
      route = tabletRoutes.odesaUt();
    } else if (location.pathname.includes('dnipro')) {
      route = tabletRoutes.dniproUt();
    } else if (location.pathname.includes('lviv')) {
      route = tabletRoutes.lvivUt();
    } else if (location.pathname.includes('kyiv')) {
      route = tabletRoutes.kyivUt();
    }

    dispatch(navigate(route));
  };

  const onSendInternational = () => {
    let route = '/';

    if (location.pathname.includes('odessa')) {
      route = tabletRoutes.odesaIt();
    } else if (location.pathname.includes('dnipro')) {
      route = tabletRoutes.dniproIt();
    } else if (location.pathname.includes('lviv')) {
      route = tabletRoutes.lvivIt();
    } else if (location.pathname.includes('kyiv')) {
      route = tabletRoutes.kyivIt();
    }

    dispatch(navigate(route));
  };

  return {
    onSendToAnotherCity,
    onSendInternational
  };
};

export { useTabletNavigationBlock };
