import classNames from 'classnames';

import {
  NumberAlreadyUsedModal,
  OtpModal,
  PrivacyPolicyModal
} from '@pages/start-page/ui/atoms';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import { PhoneInput } from '@shared/ui/atoms/phone-input';

import { Select } from '@shared/ui/atoms/select';

import noCityMobile from '../../data/img/start-page-no-city-mobile.png';

import styles from './city-uk-start-block.module.scss';

import { useCityUkStartBlockProps } from './city-uk-start-block.props';

/**
 * <CityUkStartBlockProps />
 */
const CityUkStartBlockProps = hoc(
  useCityUkStartBlockProps,
  ({
    selectedCity,
    phone,
    accept,
    isInternational,
    isValid,
    isTablet,
    showPrivacy,
    setShowPrivacy,
    setAccept,
    setPhone,
    onSendOtp,
    onChange
  }) => (
    <>
      <NumberAlreadyUsedModal />

      <OtpModal />

      <PrivacyPolicyModal
        open={showPrivacy}
        onClose={() => setShowPrivacy(false)}
      />

      <div className={classNames({ [styles.container]: isTablet })}>
        <img className={styles.image} src={noCityMobile} alt='' />

        <div
          className={classNames(styles.content, {
            [styles.contentTablet]: isTablet
          })}
        >
          <div
            className={classNames(styles.top, { [styles.topTablet]: isTablet })}
          >
            <h1>Поїхали?</h1>

            <p>
              Можна надіслати сюрприз з рідного міста тому, кого любиш і хто так
              сумує за ним ❤️
            </p>

            <PhoneInput
              className={classNames(styles.input, {
                [styles.inputTablet]: isTablet
              })}
              label={!isTablet ? 'Номер телефону' : ''}
              placeholder={isTablet ? 'Твій номер телефону' : ''}
              value={phone}
              onChange={setPhone}
            />

            <Select
              className={styles.select}
              label='Місто'
              placeholder='Місто'
              value={selectedCity}
              onChange={onChange}
              options={[
                {
                  name: 'Київ',
                  id: 'kyiv'
                },
                {
                  name: 'Дніпро',
                  id: 'dnipro'
                },
                {
                  name: 'Львів',
                  id: 'lviv'
                },
                {
                  name: 'Одеса',
                  id: 'odesa'
                }
              ]}
            />

            <Checkbox
              className={{
                container: classNames(styles.checkbox, {
                  [styles.checkboxTablet]: isTablet
                }),
                box: classNames(styles.checkboxBox, {
                  [styles.checkboxTabletBox]: isTablet
                }),
                label: classNames(styles.checkboxLabel, {
                  [styles.checkboxTabletLabel]: isTablet
                }),
                checkmark: classNames({ [styles.checkboxCheckmark]: !isTablet })
              }}
              label="Я приймаю умови <span id='privacy-modal-open'>Угоди користувача</span>"
              value={accept}
              onChange={setAccept}
            />

            <Button style={{ marginBottom: '10px' }} disabled={!isValid} variant='primary' onClick={onSendOtp(true)}>
              Міжнародна доставка обіймів
            </Button>

            <Button disabled={!isValid} variant={
              isInternational
                    ? 'secondary'
                    : 'primary'
                } onClick={onSendOtp()}>
              Доставка обіймів по Україні
            </Button>
          </div>
        </div>
      </div>
    </>
  )
);

export { CityUkStartBlockProps };
