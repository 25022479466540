import { routes } from '@shared/config/routes';

import { Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import backIcon from '../assets/img/back-icon.png';

import { useAddReviewInfo } from '../model';

import styles from './add-review-info-ut-form.module.scss';

import { RecipientInfo } from './recipient-info.component';

import { SenderInfo } from './sender-info.component';

const AddReviewInfoUtForm = hoc(useAddReviewInfo, ({ form, onGoBack }) => (
  <Form
    use={form}
    style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
    className={styles.form}
  >
    <h3 className={styles.title}>Перевіримо усі дані ще раз? </h3>

    <div className={styles.block}>
      <p className={styles.blockTitle}>Відправник</p>

      <SenderInfo link={routes.delivery.senderUt()} image={backIcon} />
    </div>

    <div className={styles.block}>
      <p className={styles.blockTitle}>Отримувач</p>

      <RecipientInfo link={routes.delivery.receiverUt()} image={backIcon} />
    </div>

    <StepperFormControls
      dirty={form.dirty}
      canSubmit={form.isValid}
      onGoBackClick={onGoBack}
    />
  </Form>
));

export { AddReviewInfoUtForm };
