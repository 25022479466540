import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { createDelivery } from '@entities/delivery';

import { setReviewInfo } from './actions';

const useAddReviewInfo = () => {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {},

    onSubmit: () => {
      dispatch(createDelivery());
    }
  });

  const onGoBack = () => {
    dispatch(setReviewInfo.reset());
  };

  return { form, onGoBack };
};

export { useAddReviewInfo };
