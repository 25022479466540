import type { FC, PropsWithChildren } from 'react';

import { Provider as StoreProvider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import type { ProviderProps } from './provider.props';

/**
 * <Provider />
 */
const Provider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  store
}) => (
  <StoreProvider store={store}>
    <BrowserRouter>{children}</BrowserRouter>
  </StoreProvider>
);

export { Provider };
