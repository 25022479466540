import styles from './header.module.scss';

const Header = () => (
  <header className={styles.header}>
    <HeaderLogo />

    <HeaderSubtitleLogo />
  </header>
);

const HeaderLogo = () => (
  <svg
    className={styles.logo}
    width='529'
    height='35'
    viewBox='0 0 529 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 34.127V1.79736H6.55471V34.127H0Z' fill='white' />

    <path
      d='M47.2122 34.127H40.0616L38.3656 32.2614C35.6052 33.929 32.4295 34.7959 29.1982 34.764C26.955 34.8305 24.7219 34.44 22.6365 33.6169C20.5511 32.7937 18.6577 31.5552 17.0729 29.9778C15.4882 28.4003 14.2457 26.5174 13.4221 24.445C12.5986 22.3726 12.2113 20.1547 12.2843 17.928C12.2212 15.711 12.6142 13.5044 13.4392 11.4432C14.2643 9.38204 15.5041 7.50952 17.0829 5.94013C18.6617 4.37073 20.5463 3.13743 22.6215 2.31559C24.6967 1.49376 26.9189 1.10066 29.1524 1.16032C31.3918 1.10021 33.6199 1.4953 35.6999 2.3213C37.7799 3.1473 39.6678 4.38676 41.2475 5.96354C42.8273 7.54032 44.0656 9.42111 44.8864 11.4903C45.7072 13.5596 46.0931 15.7736 46.0204 17.9963C46.065 21.8923 44.7898 25.6902 42.3993 28.7804L47.2122 34.127ZM34.1028 27.4381L27.2272 19.8392L31.2609 16.2899L37.8156 23.6614C38.7576 21.8974 39.2309 19.9241 39.1907 17.928C39.1907 11.9217 35.3862 7.28042 29.0607 7.28042C27.6685 7.25626 26.2866 7.52256 25.0046 8.06209C23.7226 8.60161 22.5689 9.40234 21.6184 10.4125C20.6678 11.4226 19.9415 12.6196 19.4864 13.926C19.0313 15.2323 18.8576 16.619 18.9765 17.9963C18.8669 19.3759 19.0505 20.763 19.5156 22.0676C19.9808 23.3722 20.7169 24.5652 21.6764 25.5692C22.6359 26.5731 23.7973 27.3657 25.0852 27.8954C26.3731 28.4251 27.7588 28.6801 29.1524 28.6439C30.8708 28.6665 32.5692 28.2762 34.1028 27.5064V27.4381Z'
      fill='white'
    />

    <path
      d='M50.0541 17.9963C49.9811 15.7697 50.3683 13.5518 51.1919 11.4794C52.0155 9.40698 53.258 7.52402 54.8427 5.94657C56.4274 4.36913 58.3209 3.13065 60.4063 2.30748C62.4917 1.48431 64.7248 1.0939 66.968 1.16033C69.2074 1.10023 71.4355 1.49531 73.5155 2.32131C75.5955 3.14731 77.4834 4.38677 79.0632 5.96355C80.6429 7.54033 81.8813 9.42112 82.702 11.4904C83.5228 13.5596 83.9087 15.7736 83.8361 17.9963C83.9087 20.219 83.5228 22.433 82.702 24.5023C81.8813 26.5715 80.6429 28.4523 79.0632 30.0291C77.4834 31.6058 75.5955 32.8453 73.5155 33.6713C71.4355 34.4973 69.2074 34.8924 66.968 34.8323C64.7248 34.8987 62.4917 34.5083 60.4063 33.6851C58.3209 32.862 56.4274 31.6235 54.8427 30.046C53.258 28.4686 52.0155 26.5856 51.1919 24.5132C50.3683 22.4408 49.9811 20.223 50.0541 17.9963ZM77.098 17.9963C77.098 11.99 73.2935 7.34869 66.968 7.34869C65.5744 7.31251 64.1887 7.56754 62.9008 8.09725C61.6129 8.62695 60.4515 9.41949 59.492 10.4235C58.5326 11.4274 57.7964 12.6204 57.3313 13.925C56.8661 15.2296 56.6825 16.6167 56.7922 17.9963C56.6825 19.3759 56.8661 20.763 57.3313 22.0676C57.7964 23.3722 58.5326 24.5652 59.492 25.5692C60.4515 26.5731 61.6129 27.3657 62.9008 27.8954C64.1887 28.4251 65.5744 28.6801 66.968 28.6439C73.3394 28.6439 77.098 24.0027 77.098 17.9963Z'
      fill='white'
    />

    <path
      d='M87.0905 30.7826L90.0241 25.7773C92.7608 27.7832 96.0648 28.8818 99.4665 28.9169C102.95 28.9169 105.242 27.4609 105.242 24.9127C105.242 18.0873 87.7781 22.0915 87.7781 10.4884C87.7781 4.0725 93.0952 1.16033 99.9707 1.16033C103.993 1.02554 107.956 2.15629 111.293 4.39101L108.451 9.39631C105.966 7.85394 103.084 7.06335 100.154 7.12117C96.6705 7.12117 94.3786 8.16774 94.3786 10.3974C94.3786 16.2672 111.843 12.6725 111.843 25.0037C111.843 31.4651 107.03 34.8778 99.5582 34.8778C95.0507 34.9826 90.6455 33.5356 87.0905 30.7826Z'
      fill='white'
    />

    <path
      d='M152.592 25.8455H147.206L143.768 20.9767L140.468 25.8455H135.219L141.041 17.8143L135.219 10.1471H140.582L143.997 14.9249L147.32 10.1471H152.569L146.679 17.9735L152.592 25.8455Z'
      fill='white'
    />

    <path
      d='M199.941 12.3085H188.23V4.18627C188.246 3.69397 188.16 3.20367 187.978 2.7454C187.796 2.28714 187.522 1.87057 187.172 1.52122C186.823 1.17188 186.405 0.897119 185.944 0.713786C185.484 0.530454 184.99 0.442409 184.494 0.455049H174.181V9.10055H178.765V32.9667H188.116V21.136H199.827V32.9667H209.178V0.455049H199.941V12.3085Z'
      fill='white'
    />

    <path
      d='M230.813 2.43215e-05C228.566 -0.0878807 226.325 0.282777 224.229 1.08906C222.133 1.89535 220.225 3.12011 218.625 4.68757C217.024 6.25503 215.765 8.13184 214.924 10.2019C214.084 12.2719 213.68 14.4911 213.738 16.7222C213.738 21.2176 215.537 25.5288 218.739 28.7075C221.941 31.8862 226.284 33.672 230.813 33.672C235.341 33.672 239.684 31.8862 242.886 28.7075C246.088 25.5288 247.887 21.2176 247.887 16.7222C247.945 14.4911 247.542 12.2719 246.701 10.2019C245.861 8.13184 244.601 6.25503 243.001 4.68757C241.4 3.12011 239.493 1.89535 237.396 1.08906C235.3 0.282777 233.059 -0.0878807 230.813 2.43215e-05ZM230.813 24.3894C229.79 24.421 228.771 24.2443 227.82 23.8701C226.868 23.496 226.004 22.9324 225.281 22.214C224.557 21.4956 223.989 20.6378 223.612 19.6933C223.235 18.7488 223.057 17.7377 223.089 16.7222C223.089 14.6888 223.903 12.7386 225.351 11.3007C226.8 9.86284 228.764 9.05505 230.813 9.05505C232.861 9.05505 234.826 9.86284 236.274 11.3007C237.723 12.7386 238.536 14.6888 238.536 16.7222C238.568 17.7377 238.39 18.7488 238.013 19.6933C237.636 20.6378 237.068 21.4956 236.345 22.214C235.621 22.9324 234.757 23.496 233.806 23.8701C232.854 24.2443 231.836 24.421 230.813 24.3894Z'
      fill='white'
    />

    <path
      d='M276.833 15.7895C277.994 15.0978 278.952 14.1162 279.611 12.9426C280.271 11.769 280.609 10.4443 280.592 9.10058C280.592 4.09529 277.314 0.455078 270.806 0.455078H252.333V32.9667H270.806C277.681 32.9667 281.623 29.0307 281.623 23.5932C281.647 21.9765 281.21 20.3861 280.362 19.0054C279.515 17.6246 278.291 16.5098 276.833 15.7895ZM261.042 7.71275H267.918C270.21 7.71275 271.379 8.78206 271.379 10.5794C271.379 12.3768 270.118 13.4688 267.918 13.4688H261.042V7.71275ZM268.674 25.9138H261.042V19.7937H268.674C270.966 19.7937 272.318 20.9085 272.318 22.7969C272.318 24.6852 271.012 25.8228 268.674 25.8228V25.9138Z'
      fill='white'
    />

    <path
      d='M295.947 0.455078L283.021 32.9667H292.991L295.076 26.9831H308.232L310.294 32.9667H320.447L307.544 0.455078H295.947ZM297.597 19.7027L301.287 9.10058H301.998L305.688 19.7482L297.597 19.7027Z'
      fill='white'
    />

    <path
      d='M365.184 0.455071H333.832V9.10057H338.415V32.9667H347.789V9.10057H359.478V32.9667H368.989V4.18629C369.044 3.67887 368.984 3.16557 368.811 2.68468C368.639 2.20379 368.36 1.76772 367.994 1.40901C367.628 1.0503 367.185 0.778209 366.699 0.613033C366.212 0.447856 365.695 0.393859 365.184 0.455071Z'
      fill='white'
    />

    <path
      d='M390.463 2.24961e-05C388.215 -0.0910799 385.972 0.277209 383.873 1.08206C381.774 1.88692 379.863 3.11118 378.26 4.67898C376.657 6.24677 375.396 8.12468 374.554 10.1963C373.712 12.2679 373.308 14.4891 373.366 16.7222C373.528 21.1131 375.399 25.2705 378.586 28.32C381.772 31.3695 386.026 33.0734 390.452 33.0734C394.878 33.0734 399.132 31.3695 402.318 28.32C405.505 25.2705 407.376 21.1131 407.538 16.7222C407.596 14.4911 407.192 12.2719 406.352 10.2019C405.511 8.13184 404.252 6.25503 402.652 4.68757C401.051 3.12011 399.144 1.89535 397.047 1.08906C394.951 0.282775 392.71 -0.0878825 390.463 2.24961e-05ZM390.463 24.3894C389.441 24.421 388.422 24.2443 387.471 23.8701C386.519 23.496 385.655 22.9324 384.931 22.214C384.208 21.4956 383.64 20.6378 383.263 19.6933C382.886 18.7488 382.708 17.7377 382.74 16.7222C382.683 15.6818 382.84 14.6406 383.202 13.6624C383.563 12.6842 384.122 11.7895 384.844 11.0329C385.566 10.2763 386.435 9.67364 387.399 9.2618C388.364 8.84995 389.402 8.63755 390.452 8.63755C391.502 8.63755 392.54 8.84995 393.505 9.2618C394.469 9.67364 395.338 10.2763 396.06 11.0329C396.782 11.7895 397.341 12.6842 397.702 13.6624C398.064 14.6406 398.221 15.6818 398.164 16.7222C398.196 17.7357 398.018 18.7449 397.643 19.6878C397.267 20.6307 396.702 21.4875 395.981 22.2055C395.259 22.9235 394.398 23.4876 393.449 23.8632C392.501 24.2388 391.484 24.4178 390.463 24.3894Z'
      fill='white'
    />

    <path
      d='M448.47 24.3895H439.578V0.455078H430.227V24.3895H421.335V0.455078H411.984V32.9667H416.384H421.335H448.47H454.269H457.821V0.455078H448.47V24.3895Z'
      fill='white'
    />

    <path
      d='M494.078 9.05508V0.455078H462.267V9.05508H473.497V32.9667H482.848V9.05508H494.078Z'
      fill='white'
    />

    <path
      d='M518.555 32.9667H528.708L515.805 0.455078H504.208L491.305 32.9667H501.275L503.337 26.9831H516.493L518.555 32.9667ZM505.858 19.7027L509.548 9.10058H510.259L513.949 19.7482L505.858 19.7027Z'
      fill='white'
    />
  </svg>
);

const HeaderSubtitleLogo = () => (
  <svg
    width='355'
    height='33'
    viewBox='0 0 355 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M354.16 0.556641H0V33H354.16V0.556641Z' fill='white' />

    <path
      d='M23.1707 20.8508H25.0729V26.6296H22.2768V23.672H12.4219V26.6296H9.62579V20.9418H11.2301C12.1837 19.204 12.5983 17.2251 12.4219 15.254L12.6052 9.06561H23.1707V20.8508ZM20.2142 20.8508V11.7503H15.4471V15.2767C15.5249 17.1887 15.197 19.0955 14.4845 20.8736L20.2142 20.8508Z'
      fill='#00D1D2'
    />

    <path
      d='M26.4022 16.2778C26.3672 15.2754 26.5399 14.2766 26.9096 13.3432C27.2792 12.4098 27.8381 11.5617 28.5514 10.8514C29.2647 10.1412 30.1174 9.58388 31.0565 9.21408C31.9957 8.84428 33.0013 8.66986 34.0112 8.70161C36.0353 8.70161 37.9765 9.49981 39.4077 10.9206C40.839 12.3414 41.643 14.2685 41.643 16.2778C41.643 18.2871 40.839 20.2142 39.4077 21.635C37.9765 23.0558 36.0353 23.854 34.0112 23.854C33.0013 23.8857 31.9957 23.7113 31.0565 23.3415C30.1174 22.9717 29.2647 22.4144 28.5514 21.7042C27.8381 20.9939 27.2792 20.1458 26.9096 19.2124C26.5399 18.279 26.3672 17.2802 26.4022 16.2778ZM38.572 16.2778C38.5227 15.3901 38.2126 14.5362 37.6799 13.8216C37.1473 13.1069 36.4154 12.5628 35.5747 12.2565C34.734 11.9502 33.8213 11.895 32.9494 12.0978C32.0775 12.3006 31.2845 12.7526 30.6685 13.3978C30.0524 14.043 29.6402 14.8532 29.4828 15.7284C29.3254 16.6036 29.4297 17.5053 29.7829 18.3223C30.136 19.1393 30.7224 19.8357 31.4698 20.3255C32.2172 20.8152 33.0927 21.077 33.9882 21.0783C34.612 21.0793 35.2294 20.9538 35.8026 20.7096C36.3758 20.4655 36.8928 20.1078 37.3219 19.6584C37.751 19.209 38.0832 18.6773 38.2981 18.0961C38.5131 17.5148 38.6063 16.8961 38.572 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M43.7286 16.2778C43.6706 14.6986 44.1301 13.1433 45.0382 11.8454C45.9463 10.5475 47.2539 9.57701 48.7649 9.0796C50.2758 8.58219 51.9085 8.58471 53.4179 9.08677C54.9273 9.58883 56.2319 10.5634 57.136 11.864L54.8441 13.4566C54.4163 12.8443 53.8436 12.3457 53.1762 12.0045C52.5088 11.6634 51.7673 11.4901 51.0167 11.5C50.4133 11.5047 49.8177 11.6365 49.2694 11.8867C48.721 12.1369 48.2325 12.4998 47.836 12.9515C47.4395 13.4031 47.1442 13.9332 46.9695 14.5066C46.7948 15.08 46.7447 15.6837 46.8226 16.2778C46.745 16.873 46.7951 17.4777 46.9696 18.0523C47.1441 18.6269 47.4391 19.1584 47.8352 19.6118C48.2313 20.0651 48.7196 20.4302 49.2679 20.683C49.8162 20.9357 50.4122 21.0705 51.0167 21.0783C51.7673 21.0882 52.5088 20.915 53.1762 20.5738C53.8436 20.2327 54.4163 19.734 54.8441 19.1217L57.136 20.6688C56.465 21.67 55.5502 22.4869 54.4765 23.0436C53.4028 23.6004 52.2049 23.8792 50.9938 23.854C50.0013 23.879 49.0146 23.6972 48.0972 23.3204C47.1798 22.9436 46.3522 22.3801 45.6677 21.6663C44.9832 20.9525 44.4569 20.1041 44.123 19.176C43.789 18.2479 43.6546 17.2606 43.7286 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M63.324 23.581V11.6138H58.9007V8.99738H70.6808V11.6138H66.2576V23.581H63.324Z'
      fill='#00D1D2'
    />

    <path
      d='M80.6733 23.581L79.8483 21.3058H73.9811L73.1331 23.581H70.085L75.6313 8.99738H78.1523L83.7673 23.581H80.6733ZM74.8979 18.7122H78.8857L76.9147 13.2519L74.8979 18.7122Z'
      fill='#00D1D2'
    />

    <path
      d='M97.4039 19.4857C97.4188 20.0673 97.3066 20.6452 97.075 21.1796C96.8434 21.7141 96.4979 22.1924 96.0623 22.5815C95.6267 22.9707 95.1113 23.2616 94.5516 23.4341C93.9919 23.6067 93.4012 23.6568 92.8201 23.581H85.9446V8.99738H92.0868C95.0203 8.99738 96.6705 10.499 96.6705 12.8196C96.7142 13.4444 96.5885 14.0694 96.3064 14.6296C96.0242 15.1898 95.5959 15.6647 95.0662 16.0048C95.773 16.2703 96.3785 16.7487 96.7977 17.3729C97.2169 17.9971 97.4288 18.7359 97.4039 19.4857ZM89.0844 11.5228V14.981H91.7888C93.0493 14.981 93.8286 14.3894 93.8286 13.2064C93.8286 12.0233 93.0493 11.5228 91.7888 11.5228H89.0844ZM92.2701 21.0556C93.6452 21.0556 94.3786 20.4413 94.3786 19.3037C94.3786 18.1662 93.6452 17.4836 92.2701 17.4836H89.0844V21.0556H92.2701Z'
      fill='#00D1D2'
    />

    <path
      d='M108.015 16.0503L112.599 23.581H109.276L105.815 17.8931C105.609 17.5291 105.425 17.5291 104.875 17.5291H103.317V23.6947H100.36V8.99738H103.317V14.708H104.967C105.494 14.708 105.632 14.708 105.861 14.2074L109.001 8.99738H112.301L108.015 16.0503Z'
      fill='#00D1D2'
    />

    <path
      d='M124.402 23.581L123.554 21.3058H117.71L116.862 23.581H113.813L119.383 8.99738H121.927L127.519 23.581H124.402ZM118.626 18.7122H122.614L120.643 13.2519L118.626 18.7122Z'
      fill='#00D1D2'
    />

    <path
      d='M134.669 8.99738H146.679V23.581H143.676V11.7275H137.511V23.581H134.669V8.99738Z'
      fill='#00D1D2'
    />

    <path
      d='M149.429 16.2778C149.433 14.7849 149.884 13.3267 150.722 12.0876C151.561 10.8484 152.751 9.88381 154.142 9.3156C155.533 8.74738 157.062 8.60104 158.536 8.89506C160.011 9.18907 161.364 9.91026 162.426 10.9675C163.488 12.0248 164.211 13.3707 164.502 14.8353C164.794 16.2999 164.642 17.8175 164.065 19.1964C163.489 20.5753 162.514 21.7536 161.263 22.5826C160.012 23.4116 158.542 23.854 157.038 23.854C156.029 23.8824 155.025 23.7057 154.087 23.3347C153.15 22.9638 152.298 22.4063 151.586 21.6968C150.873 20.9872 150.314 20.1404 149.943 19.2085C149.572 18.2767 149.397 17.2793 149.429 16.2778ZM161.599 16.2778C161.549 15.3902 161.239 14.5363 160.707 13.8216C160.174 13.107 159.442 12.5629 158.601 12.2565C157.761 11.9502 156.848 11.895 155.976 12.0978C155.104 12.3007 154.311 12.7526 153.695 13.3978C153.079 14.043 152.667 14.8532 152.51 15.7284C152.352 16.6036 152.456 17.5053 152.81 18.3223C153.163 19.1393 153.749 19.8357 154.497 20.3255C155.244 20.8153 156.119 21.077 157.015 21.0784C157.639 21.0793 158.256 20.9538 158.829 20.7097C159.403 20.4655 159.92 20.1078 160.349 19.6584C160.778 19.209 161.11 18.6774 161.325 18.0961C161.54 17.5148 161.633 16.8961 161.599 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M166.778 16.2778C166.711 14.6971 167.164 13.1375 168.069 11.8351C168.973 10.5327 170.281 9.55843 171.794 9.05966C173.306 8.56089 174.941 8.56479 176.451 9.07079C177.961 9.57679 179.264 10.5573 180.163 11.864L177.871 13.4566C177.441 12.8411 176.865 12.3404 176.193 11.9991C175.521 11.6578 174.775 11.4863 174.021 11.5C173.418 11.5082 172.824 11.6424 172.278 11.8938C171.731 12.1452 171.244 12.5082 170.849 12.959C170.453 13.4098 170.157 13.9384 169.981 14.5102C169.805 15.0821 169.752 15.6844 169.826 16.2778C169.749 16.873 169.799 17.4777 169.973 18.0523C170.148 18.6269 170.443 19.1584 170.839 19.6117C171.235 20.0651 171.723 20.4302 172.272 20.6829C172.82 20.9357 173.416 21.0704 174.021 21.0783C174.775 21.092 175.521 20.9205 176.193 20.5792C176.865 20.2379 177.441 19.7372 177.871 19.1217L180.163 20.6688C179.492 21.67 178.577 22.4868 177.503 23.0436C176.43 23.6004 175.232 23.8791 174.021 23.854C173.029 23.8793 172.043 23.6975 171.127 23.3205C170.211 22.9435 169.384 22.3796 168.702 21.6653C168.019 20.9511 167.495 20.1024 167.163 19.1744C166.832 18.2463 166.701 17.2594 166.778 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M192.791 8.99738H195.495V23.581H192.676V13.5931L185.434 23.581H182.73V8.99738H185.64V19.008L192.791 8.99738Z'
      fill='#00D1D2'
    />

    <path
      d='M198.246 21.0783C198.467 21.1007 198.689 21.1007 198.91 21.0783C200.01 21.0783 200.377 20.4868 200.492 16.3916L200.698 8.92914H211.63V23.5127H208.673V11.7276H203.54L203.402 16.5508C203.265 22.1249 202.188 23.854 199.094 23.854C198.705 23.8914 198.313 23.8914 197.925 23.854L198.246 21.0783Z'
      fill='#00D1D2'
    />

    <path
      d='M214.426 16.2778C214.431 14.7849 214.881 13.3267 215.719 12.0876C216.558 10.8484 217.748 9.88381 219.139 9.3156C220.53 8.74738 222.059 8.60104 223.533 8.89506C225.008 9.18907 226.362 9.91026 227.423 10.9675C228.485 12.0248 229.208 13.3707 229.499 14.8353C229.791 16.2999 229.639 17.8175 229.063 19.1964C228.486 20.5753 227.511 21.7536 226.26 22.5826C225.009 23.4116 223.539 23.854 222.035 23.854C221.026 23.8824 220.022 23.7057 219.084 23.3347C218.147 22.9638 217.295 22.4064 216.583 21.6968C215.87 20.9872 215.311 20.1404 214.94 19.2085C214.569 18.2767 214.394 17.2793 214.426 16.2778ZM226.596 16.2778C226.547 15.3902 226.236 14.5363 225.704 13.8216C225.171 13.107 224.439 12.5629 223.599 12.2565C222.758 11.9502 221.845 11.895 220.973 12.0978C220.101 12.3007 219.308 12.7526 218.692 13.3978C218.076 14.043 217.664 14.8532 217.507 15.7284C217.349 16.6036 217.454 17.5054 217.807 18.3223C218.16 19.1393 218.746 19.8357 219.494 20.3255C220.241 20.8153 221.117 21.077 222.012 21.0784C222.636 21.0793 223.253 20.9538 223.826 20.7097C224.4 20.4655 224.917 20.1078 225.346 19.6584C225.775 19.209 226.107 18.6774 226.322 18.0961C226.537 17.5148 226.63 16.8961 226.596 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M240.095 16.0503L244.679 23.581H241.309L237.826 17.8931C237.62 17.5291 237.459 17.4154 236.909 17.4154H235.328V23.581H232.394V8.99738H235.328V14.708H236.978C237.528 14.708 237.665 14.708 237.895 14.2074L241.034 8.99738H244.289L240.095 16.0503Z'
      fill='#00D1D2'
    />

    <path
      d='M251.164 16.2778C251.169 14.7849 251.619 13.3267 252.458 12.0876C253.297 10.8484 254.487 9.88381 255.877 9.3156C257.268 8.74738 258.797 8.60104 260.272 8.89506C261.746 9.18907 263.1 9.91026 264.162 10.9675C265.224 12.0248 265.946 13.3707 266.238 14.8353C266.53 16.2999 266.377 17.8175 265.801 19.1964C265.224 20.5753 264.249 21.7536 262.998 22.5826C261.748 23.4116 260.277 23.854 258.773 23.854C257.764 23.8824 256.76 23.7057 255.823 23.3347C254.885 22.9638 254.034 22.4064 253.321 21.6968C252.608 20.9872 252.05 20.1404 251.679 19.2085C251.308 18.2767 251.133 17.2793 251.164 16.2778ZM263.334 16.2778C263.285 15.3902 262.975 14.5363 262.442 13.8216C261.909 13.107 261.178 12.5629 260.337 12.2565C259.496 11.9502 258.584 11.895 257.712 12.0978C256.84 12.3007 256.047 12.7526 255.431 13.3978C254.815 14.043 254.402 14.8532 254.245 15.7284C254.088 16.6036 254.192 17.5054 254.545 18.3223C254.898 19.1393 255.485 19.8357 256.232 20.3255C256.979 20.8153 257.855 21.077 258.75 21.0784C259.374 21.0793 259.992 20.9538 260.565 20.7097C261.138 20.4655 261.655 20.1078 262.084 19.6584C262.513 19.209 262.845 18.6774 263.06 18.0961C263.275 17.5148 263.368 16.8961 263.334 16.2778Z'
      fill='#00D1D2'
    />

    <path
      d='M272.112 14.7762H275.023C278.231 14.7762 280.271 16.1413 280.271 19.099C280.271 22.0566 278.163 23.6492 274.931 23.6492H269.201V8.99738H278.896V11.6138H272.158L272.112 14.7762ZM272.112 20.9873H274.839C276.375 20.9873 277.131 20.373 277.131 19.1217C277.131 17.8704 276.306 17.3244 274.839 17.3244H272.112V20.9873Z'
      fill='#00D1D2'
    />

    <path d='M283.228 23.581V8.99738H286.184V23.581H283.228Z' fill='#00D1D2' />

    <path
      d='M299.912 8.99735H302.525V23.581H299.729V13.5931L292.487 23.581H289.759V8.99735H292.578V19.0079L299.912 8.99735ZM292.418 4.17407H294.572C294.581 4.38231 294.631 4.58672 294.721 4.7754C294.81 4.96408 294.936 5.13324 295.092 5.27301C295.247 5.41279 295.43 5.52038 295.628 5.58951C295.826 5.65864 296.036 5.68793 296.245 5.67566C296.456 5.69451 296.669 5.66981 296.87 5.60312C297.071 5.53644 297.256 5.42921 297.413 5.28817C297.57 5.14714 297.696 4.97536 297.783 4.78362C297.87 4.59189 297.916 4.38435 297.918 4.17407H300.096C300.021 5.13391 299.584 6.0304 298.872 6.68437C298.161 7.33834 297.226 7.70162 296.257 7.70162C295.287 7.70162 294.353 7.33834 293.641 6.68437C292.929 6.0304 292.493 5.13391 292.418 4.17407Z'
      fill='#00D1D2'
    />

    <path
      d='M318.132 8.99738H320.86V23.581H318.11V13.9572L314.557 19.5767H312.563L309.011 13.9572V23.581H306.215V8.99738H308.988L313.572 16.073L318.132 8.99738Z'
      fill='#00D1D2'
    />

    <path d='M324.595 23.581V8.99738H327.529V23.581H324.595Z' fill='#00D1D2' />

    <path
      d='M342.495 19.4857C342.508 20.0566 342.398 20.6236 342.173 21.1491C341.948 21.6747 341.613 22.1467 341.191 22.534C340.768 22.9213 340.267 23.2149 339.722 23.3954C339.176 23.5759 338.598 23.6392 338.026 23.581H331.15V8.99738H337.292C340.226 8.99738 341.876 10.499 341.876 12.8196C341.92 13.4444 341.794 14.0694 341.512 14.6296C341.23 15.1898 340.802 15.6647 340.272 16.0048C340.957 16.2887 341.538 16.7749 341.936 17.3976C342.333 18.0202 342.529 18.7492 342.495 19.4857ZM334.152 11.5228V14.981H336.903C338.163 14.981 338.942 14.3894 338.942 13.2064C338.942 12.0233 338.163 11.5228 336.903 11.5228H334.152ZM337.361 21.0556C338.713 21.0556 339.47 20.4413 339.47 19.3037C339.47 18.1662 338.713 17.4836 337.361 17.4836H334.152V21.0556H337.361Z'
      fill='#00D1D2'
    />

    <path
      d='M130.911 16.7783H245.893'
      stroke='#00D1D2'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

export { Header };
