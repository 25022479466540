import classNames from 'classnames';

import type { ReactNode } from 'react';

import { useRef } from 'react';

import closeIcon from '@pages/start-page/data/img/close-icon.svg';

import { useClickOutside } from '@shared/lib/use-click-outside';

import styles from './modal.module.scss';

const Modal = ({
  open,
  className,
  isTablet,
  closeFunction,
  children
}: {
  open: boolean;
  className?: {
    container?: string;
    content?: string;
  };
  closeFunction?: () => void;
  isTablet?: boolean;
  children: ReactNode;
}) => {
  const contentRef = useRef<HTMLDivElement>();

  useClickOutside(contentRef as any, () => {
    if (open) closeFunction?.();
  });

  return (
    <div
      className={classNames(styles.container, className?.container, {
        [styles.containerOpen]: open
      })}
    >
      <div
        ref={contentRef as any}
        className={classNames(styles.content, className?.content, {
          [styles.contentTablet]: isTablet
        })}
      >
        {!!closeFunction && (
          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt='Close icon'
            onClick={closeFunction}
          />
        )}

        {children}
      </div>
    </div>
  );
};

export { Modal };
