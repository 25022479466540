type MobIntrRoutes = {
  odesa: () => string;
  dnipro: () => string;
  kyiv: () => string;
  lviv: () => string;
};

const mobIntrRoutes: MobIntrRoutes = {
  odesa: () => '/odesa-intr',
  dnipro: () => '/dnipro-intr',
  kyiv: () => '/kyiv-intr',
  lviv: () => '/lviv-intr'
};

export { mobIntrRoutes };
