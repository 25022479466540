import { object, string } from 'yup';

import type { SenderInfo } from '../types';

const schema = object<SenderInfo>({
  surname: string().required("Обов'язкове поле"),

  firstname: string().required("Обов'язкове поле"),

  email: string().required("Обов'язкове поле").email()

  // postIndex: string()
  //   .matches(onlyNumbersRegexp, 'Тільки цифри')
  //   .length(5)
  //   .required(),
  //
  // //@ts-expect-error Because field return "empty" as which converted to NaN
  // city: object({}).nullable().required(),
  //
  // //@ts-expect-error Because field return "empty" as which converted to NaN
  // street: object({}).nullable().required(),
  //
  // building: string().required(),
  //
  // apartments: string().required()
});

export { schema };
