import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router';

import { setNavigate } from './actions';

const useSetupRouter = () => {
  const dispatch = useDispatch();
  const navigateFn = useNavigate();

  useEffect(() => {
    dispatch(setNavigate(navigateFn));
  }, [navigateFn]);
};

export { useSetupRouter };
