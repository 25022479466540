import { useFormik } from 'formik';

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { schema } from '../lib';

import type { ReceiverInfoUt } from '../types';

import { setDeliveryReceiverInfoUt } from './actions';

type AddReceiverInfoUtProps = {
  initialValues: ReceiverInfoUt;
};

const useAddReceiverInfoUt = ({ initialValues }: AddReceiverInfoUtProps) => {
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const form = useFormik<ReceiverInfoUt>({
    initialValues,
    validateOnMount: true,
    validationSchema: schema,

    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        setDeliveryReceiverInfoUt({
          ...values,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          additionalInfo: values.additionalInfo.trim()
        })
      );

      setSubmitting(false);
    }
  });

  const onGoBack = () => {
    dispatch(setDeliveryReceiverInfoUt.reset());
  };

  const checkIsValid = async () => {
    const isValid = await form.validateForm(form.values);
  };

  useEffect(() => {
    checkIsValid();

    if (!form.isValid) {
      setIsValid(false);

      return;
    }

    setIsValid(true);
  }, [form.isValid]);

  useEffect(() => {
    checkIsValid();
  }, [form.dirty]);

  return {
    form,
    isValid,
    onGoBack
  };
};

export { useAddReceiverInfoUt };
