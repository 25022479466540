import { array, object, string } from 'yup';

import type { Pricing } from '../types';

const schema = object<Pricing>({
  pricing: string(),

  deliveryСonditions: string()
});

export { schema };
