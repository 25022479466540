import { createDeliveryProcessReducer } from '@processes/create-delivery/model';

import { startPageReducer } from '@pages/start-page';

import { deliveryReducer } from '@entities/delivery';

import { routerReducer } from '@shared/router';

import { spinnerReducer } from '@shared/spinner';

import { generalReducer } from './general';

/**
 * App reducers
 */
const reducers = {
  general: generalReducer,
  startPage: startPageReducer,
  delivery: deliveryReducer,
  createDeliveryProcess: createDeliveryProcessReducer,
  router: routerReducer,
  spinner: spinnerReducer
};

export { reducers };
