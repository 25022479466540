import { PhoneInput } from '@shared/ui/atoms/phone-input';

import {
  NumberField,
  PhoneField,
  RadioGroupField,
  SearchField,
  TextField
} from './adapters';

const Field = {
  Text: TextField,
  Number: NumberField,
  Search: SearchField,
  Phone: PhoneField,
  RadioGroup: RadioGroupField,
  PhoneInput: PhoneInput
};

export { Field };
