import classNames from 'classnames';

import type { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

import styles from './button.module.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: 'primary' | 'secondary';
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  className,
  variant,
  ...props
}) => (
  <button
    className={classNames(className, styles[variant], styles.button)}
    {...props}
  />
);

export { Button };

export type { ButtonProps };
