import { useNavigate } from 'react-router';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import styles from './add-review-info-ut-form.module.scss';

const RecipientInfo = ({ link, image }: { link: string; image: string }) => {
  const { receiverUt } = useTypedSelector(state => state.createDeliveryProcess);
  const navigate = useNavigate();

  const onClickEdit = () => {
    navigate(link);
  };

  return (
    <div className={styles.grid}>
      <div className={styles.labels}>
        <p>Телефон:</p>

        <p>Ім’я:</p>

        <p>Місце отримання:</p>

        <p>Населений пункт:</p>

        {receiverUt?.receivCheckId === 1 && <p>Відділення:</p>}

        {receiverUt?.receivCheckId === 2 && <p>Адреса:</p>}

        {receiverUt?.receivCheckId === 3 && <p>Поштомат:</p>}
      </div>

      <div className={styles.values}>
        <p>{receiverUt?.phone}</p>

        <p>{`${receiverUt?.firstName} ${receiverUt?.lastName}`}</p>

        {receiverUt?.receivCheckId === 1 && <p>Відділення</p>}

        {receiverUt?.receivCheckId === 2 && <p>Адреса</p>}

        {receiverUt?.receivCheckId === 3 && <p>Поштомат</p>}

        <p>{receiverUt?.city?.label}</p>

        {receiverUt?.receivCheckId === 1 && <p>{receiverUt.branch?.label}</p>}

        {receiverUt?.receivCheckId === 2 && (
          <p>{`${receiverUt?.street?.label}, ${receiverUt?.building}, ${receiverUt?.apartments}`}</p>
        )}

        {receiverUt?.receivCheckId === 3 && (
          <p>{receiverUt.parcelLocker?.label}</p>
        )}
      </div>

      <div onClick={onClickEdit} className={styles.editButton}>
        <p>Редагувати</p>

        <img className={styles.editImage} src={image} alt='' />
      </div>
    </div>
  );
};

export { RecipientInfo };
