import { enviroment } from '@shared/config/enviroment';

import type { HttpService } from '@shared/lib/http';

class TranslationService {
  public constructor(private readonly http: HttpService) {}

  public translationText = ({ query }: { query: string }) =>
    this.http
      .request<{ data: { translations: [{ translatedText: string }] } }>({
        url: '/',
        data: { q: query, source: 'uk', target: 'en', format: 'text' },
        method: 'POST',
        params: { key: enviroment.translationApiKey }
      })
      .then(({ data }) => data.data.translations[0].translatedText);

  public translationTextBatch = async (queries: string[]) =>
    Promise.all(queries.map(query => this.translationText({ query })));
}

export { TranslationService };
