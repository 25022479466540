import classNames from 'classnames';

import type {
  FC,
  InputHTMLAttributes,
  PropsWithChildren,
  RefObject,
  TextareaHTMLAttributes
} from 'react';

import { useId, useState } from 'react';

import styles from './input.module.scss';

type InputProps = PropsWithChildren<
  InputHTMLAttributes<HTMLInputElement> &
    TextareaHTMLAttributes<HTMLTextAreaElement> & {
      textarea?: boolean;

      label?: string;

      helperText?: string;

      isError?: boolean;

      forceFocusedStyles?: boolean;

      rootRef?: RefObject<HTMLLabelElement>;

      classnames?: {
        wrapper?: string;
        input?: string;
        label?: string;
        labelText?: string;
      };

      opened?: boolean;
    }
>;

const Input: FC<InputProps> = ({
  label,
  className,
  classnames,
  textarea: isTextArea,
  rootRef,
  children,
  forceFocusedStyles,
  helperText,
  isError,
  value,
  onBlur,
  onFocus,
  disabled,
  opened,
  ...props
}) => {
  const id = useId();

  const [focused, setFocused] = useState(false);

  const hasValue = !!value && value !== '';

  const InputComponent = isTextArea ? 'textarea' : 'input';

  return (
    <label ref={rootRef}>
      <div
        className={classNames(styles.wrapper, classnames?.wrapper, {
          [styles.textarea]: isTextArea
        })}
      >
        {children}

        <InputComponent
          className={classNames(styles.input, className, classnames?.input, {
            [styles.textarea]: isTextArea,
            [styles.forceFocused]: !!forceFocusedStyles,
            [styles.error]: !!isError,
            [styles.hasValue]: hasValue && !focused,
            [styles.focused]: focused || opened
          })}
          id={id}
          placeholder='&nbsp;'
          value={value}
          onFocus={event => {
            setFocused(true);

            onFocus?.(event as any);
          }}
          onBlur={event => {
            setFocused(false);

            onBlur?.(event as any);
          }}
          disabled={disabled}
          {...props}
        />

        <label
          className={classNames(styles.label, classnames?.label, {
            [styles.labelFocused]: focused || hasValue,
            [styles.labelError]: !!isError,
            [styles.labelDisabled]: disabled
          })}
          form={id}
        >
          <span className={classNames(styles.span, classnames?.labelText)}>
            {label}
          </span>
        </label>
      </div>

      {helperText && (
        <div
          className={classNames(styles.helper, { [styles.error]: !!isError })}
        >
          <svg
            className={classNames(styles.infoIcon, {
              [styles.error]: !!isError
            })}
            width='28'
            height='29'
            viewBox='0 0 28 29'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14 28.4557C21.7075 28.4557 27.9557 22.2075 27.9557 14.5C27.9557 6.79241 21.7075 0.544189 14 0.544189C6.29241 0.544189 0.0441895 6.79241 0.0441895 14.5C0.0441895 22.2075 6.29241 28.4557 14 28.4557Z'
              fill='currentColor'
            />

            <path d='M13.9992 20.3883C13.2798 20.3883 12.6967 20.9715 12.6967 21.6909C12.6967 22.4102 13.2798 22.9934 13.9992 22.9934C14.7186 22.9934 15.3018 22.4102 15.3018 21.6909C15.3018 20.9715 14.7186 20.3883 13.9992 20.3883Z' />

            <path d='M12.8828 17.1062C12.8828 17.7228 13.3827 18.2227 13.9993 18.2227C14.6159 18.2227 15.1157 17.7228 15.1157 17.1062L15.1157 7.43019C15.1157 6.81358 14.6159 6.31373 13.9993 6.31373C13.3827 6.31373 12.8828 6.81358 12.8828 7.43019L12.8828 17.1062Z' />
          </svg>

          <span
            className={classNames(styles.helperText, {
              [styles.error]: !!isError
            })}
          >
            {helperText}
          </span>
        </div>
      )}
    </label>
  );
};

export { Input };

export type { InputProps };
