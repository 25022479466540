import type { FocusEvent } from 'react';

import { useRef, useState } from 'react';

import { useLocation } from 'react-router';

/**
 * Input Props
 */
type PhoneInputProps = {
  /**
   * ClassName
   */
  className?: string;

  /**
   * Required
   */
  required?: boolean;

  /**
   * Is error
   */
  isError?: boolean;

  /**
   * Error
   */
  error?: string;

  /**
   * Placeholder
   */
  placeholder?: string;

  /**
   * Label
   */
  label?: string;

  /**
   * Disabled
   */
  disabled?: boolean;

  /**
   * Value
   */
  value: string;

  /**
   * onChange
   */
  onChange: (value: string) => void;

  /**
   * onFocus
   */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;

  /**
   * onBlur
   */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

/**
 * Use Phone Input
 */
const usePhoneInput = ({
  value,
  onChange,
  onFocus,
  onBlur
}: PhoneInputProps) => {
  const { pathname } = useLocation();

  const [focused, setFocused] = useState(false);
  const [values, setValues] = useState(value);
  const hasValue = Boolean(value) || value == '0';
  const input = useRef<HTMLInputElement>();

  const onLabelClick = () => {
    input.current?.focus();
  };

  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);

    onFocus?.(event);
  };

  const _onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);

    onBlur?.(event);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!focused) setFocused(true);

    const { value } = event.target;

    onChange?.(value);

    setValues(value);
  };

  return {
    input,
    value,
    values,
    focused,
    isTablet: pathname.includes('tablet'),
    hasValue,
    onInputChange,
    onLabelClick,
    _onBlur,
    _onFocus
  };
};

export { usePhoneInput };

export type { PhoneInputProps };
