import './app/styles/global.scss';

import { createBrowserHistory } from 'history';

import { createRoot } from 'react-dom/client';

import { App } from '@app';

import { Provider } from '@app/provider';

import { createStore } from '@app/store';

const setup = async () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
        .then(
          registration => {
            console.log(
              'ServiceWorker registration successful with scope: ',
              registration.scope
            );
          },
          error => {
            console.log('ServiceWorker registration failed: ', error);
          }
        );
    });
  }

  const history = createBrowserHistory();
  const store = createStore(history);

  createRoot(document.getElementById('app') as HTMLElement).render(
    <Provider store={store} history={history}>
      <App />
    </Provider>
  );
};

setup();
