import { object, string } from 'yup';

import type { ReceiverInfoUt } from '../types';

import { onlyUkrString } from './regexp';

const schema = object<ReceiverInfoUt>({
  phone: string()
    .matches(/^(?:\+38)?(0[5-9][0-9]\d{7})$/, 'Неправильний номер телефону')
    .required(),

  lastName: string()
    .matches(onlyUkrString, 'Тільки українські літери')
    .required("Обов'язкове поле"),

  firstName: string()
    .matches(onlyUkrString, 'Тільки українські літери')
    .required("Обов'язкове поле"),

  //@ts-expect-error Because field return "empty" as which converted to NaN
  city: object({}).nullable().required(),

  //@ts-expect-error Because field return "empty" as which converted to NaN
  branch: object({}).when('receivCheckId', {
    is: val => val === 1,
    then: object({}).nullable().required(),
    otherwise: object({}).nullable()
  }),

  //@ts-expect-error Because field return "empty" as which converted to NaN
  street: object({}).when('receivCheckId', {
    is: val => {
      val === 2;
    },
    then: object({}).nullable().required(),
    otherwise: object({}).nullable()
  }),

  building: string().when('receivCheckId', {
    is: val => val === 2,
    then: string().required(),
    otherwise: string()
  }),

  apartments: string().when('receivCheckId', {
    is: val => val === 2,
    then: string().required(),
    otherwise: string()
  }),

  //@ts-expect-error Because field return "empty" as which converted to NaN
  parcelLocker: object({}).when('receivCheckId', {
    is: val => val === 3,
    then: object({}).nullable().required(),
    otherwise: object({}).nullable()
  }),

  additionalInfo: string()
});

export { schema };
