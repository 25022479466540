import classNames from 'classnames';

import OtpInput from 'react18-input-otp';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './otp-modal.module.scss';

import { useOtpModalProps } from './otp-modal.props';

/**
 * <OtpModal />
 */
const OtpModal = hoc(
  useOtpModalProps,
  ({
    otpDivRef,
    open,
    otp,
    isValid,
    isTablet,
    incorrectOtp,
    onChange,
    onCheckOtp,
    onSendAgain,
    onClose
  }) => (
    <Modal open={open} isTablet={isTablet} closeFunction={onClose}>
      <h1
        className={classNames(styles.title, { [styles.titleTablet]: isTablet })}
      >
        Твій пароль-перевірка
      </h1>

      <p
        className={classNames(styles.description, {
          [styles.descriptionTablet]: isTablet
        })}
      >
        Надіслали код на твій номер телефону 🙌🏻
      </p>

      <div ref={otpDivRef as any}>
        <OtpInput
          containerStyle={classNames(styles.otpContainer, {
            [styles.otpContainerTablet]: isTablet
          })}
          inputStyle={classNames(styles.otpInput, {
            [styles.otpInputTablet]: isTablet
          })}
          focusStyle={classNames(styles.otpInputFocused, {
            [styles.otpInputFocusedTablet]: isTablet
          })}
          errorStyle={classNames(styles.otpInputError, {
            [styles.otpInputErrorTablet]: isTablet
          })}
          autoComplete='one-time-code'
          value={otp}
          onChange={onChange}
          numInputs={4}
          hasErrored={incorrectOtp}
          shouldAutoFocus={false}
          isInputNum
        />
      </div>

      {incorrectOtp && (
        <p
          className={classNames(styles.error, {
            [styles.errorTablet]: isTablet
          })}
        >
          <p>!</p>Код невірний, перевір ще раз
        </p>
      )}

      <div
        className={classNames(styles.buttonContainer, {
          [styles.buttonTabletContainer]: isTablet
        })}
      >
        <Button
          disabled={!isValid}
          className={classNames(styles.button, {
            [styles.buttonTablet]: isTablet
          })}
          variant='primary'
          onClick={onCheckOtp}
        >
          Підтвердити!
        </Button>
      </div>

      <div className={styles.textContainer}>
        <p
          className={classNames(styles.text, { [styles.textTablet]: isTablet })}
          onClick={onSendAgain}
        >
          Надіслати ще раз
        </p>
      </div>
    </Modal>
  )
);

export { OtpModal };
