import { make } from 'redux-chill';

import type { routes } from '@shared/config/routes';

/**
 * Set city
 */
const setCity = make('[start-page] set city').stage(
  (value: keyof typeof routes.tablet) => value
);

/**
 * Send otp
 */
const sendOtp = make('[start-page] send otp').stage(
  (phone: string, isInternationalClick = false) => ({
    phone,
    isInternationalClick
  })
);

/**
 * Check otp
 */
const checkOtp = make('[start-page] check otp')
  .stage((otp: string, isTablet: boolean) => ({
    otp,
    isTablet
  }))
  .stage('error')
  .stage('success');

/**
 * Set is otp sent
 */
const setIsOtpSent = make('[start-page] set is otp sent').stage(
  (value: boolean) => value
);

/**
 * Set is number already user
 */
const setIsNumberAlreadyUser = make(
  '[start-page] set is number already user'
).stage((value: boolean) => value);

/**
 * Set is otp incorrect
 */
const setIsOtpIncorrect = make('[start-page] set is otp incorrect').stage(
  (value: boolean) => value
);

/**
 * Set phone
 */
const setPhone = make('[start-page] set phone').stage((phone: string) => phone);

const setStartPageParams = make('[start-mage] to start page params').stage(
  (isTablet: boolean, isInternational: boolean) => ({
    isTablet,
    isInternational
  })
);

export {
  setCity,
  sendOtp,
  checkOtp,
  setIsOtpSent,
  setIsNumberAlreadyUser,
  setPhone,
  setStartPageParams,
  setIsOtpIncorrect
};
