import { reducer } from 'redux-chill';

import { setNavigate } from './actions';

import { RouterState } from './state';

const routerReducer = reducer(new RouterState()).on(
  setNavigate,
  (state, { navigateFn }) => {
    state.navigateFn = navigateFn;
  }
);
export { routerReducer };
