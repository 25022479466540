import { array, object, string } from 'yup';

import type { Parameters } from '../types';

const schema = object<Parameters>({
  target: string(),

  delyveryInfo: array().min(1),

  weight: string(),

  length: string(),

  width: string(),

  height: string(),

  volumetricWeight: string()
});

export { schema };
