import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';

import merge from 'deepmerge';

class HttpService {
  /**
   * Get params
   */
  public constructor(
    private readonly getConfig: () => AxiosRequestConfig,
    private readonly statusMap: Record<string, (response: AxiosResponse) => any>
  ) {}

  /**
   * Do api request
   */
  public request = async <T = any>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    const defaults = this.getConfig();

    let response: AxiosResponse;
    let failure = false;

    const { data = {}, url } = config;

    try {
      const configuration = merge(defaults, {
        ...config,
        url,
        data
      });

      if (data instanceof FormData) {
        configuration.data = data;
      }

      response = await axios(configuration);
    } catch (error) {
      // @ts-expect-error ?????
      response = error.response;

      failure = true;
    }

    for (const status in this.statusMap) {
      if (response.status != Number(status)) continue;

      const handler = this.statusMap[status];
      const next = handler(response);

      if (next) break;
    }

    if (failure) {
      throw response;
    }

    return response as AxiosResponse<T>;
  };

  public testRequest = (defaults: AxiosRequestConfig) => {
    const instance = axios.create(defaults);

    return async (
      { headers = {}, ...config }: AxiosRequestConfig,
      enabled = true,
      disabledToken = false
    ) => {
      try {
        headers.channel = 'admin';
        headers['Cache-Control'] = 'no-cache';
        const token = localStorage.getItem('idtoken');
        const response = await instance({
          ...config,
          headers: token
            ? {
                idtoken: token,
                ...headers
              }
            : headers
        });

        const idToken =
          response?.headers?.idtoken || response?.headers?.idToken;

        if (idToken && !disabledToken) {
          localStorage.setItem('idtoken', idToken);
        }

        return response;
      } catch (e) {
        const error = e as AxiosError;

        switch (true) {
          case enabled && error?.response?.status == 401: {
            // TODO: Dispatch logout action: store.dispatch(logout())

            break;
          }

          default: {
            throw error;
          }
        }
      }
    };
  };
}

export { HttpService };
