import { reducer } from 'redux-chill';

import { deliveryDescription } from '../assets/data';

import {
  createDelivery,
  createDeliveryUt,
  getBranch,
  getCities,
  getDelyveryDescription,
  getParcelLocker,
  getStreets,
  setTransliteration
} from './actions';

import { DeliveryState } from './state';

/**
 * General reducer
 */
const deliveryReducer = reducer(new DeliveryState())
  .on(getCities, state => {
    state.searchCitiesPending = true;
  })
  .on(getCities.success, (state, { cities }) => {
    const uniqueIds = new Set();

    state.searchCitiesResult = [];

    cities.forEach(city => {
      if (uniqueIds.has(city.id)) return;

      uniqueIds.add(city.id);

      state.searchCitiesResult.push(city);
    });
  })
  .on([getCities.success, getCities.failure], state => {
    state.searchCitiesPending = false;
  })
  .on(getCities.reset, state => {
    const defaultState = new DeliveryState();

    state.searchCitiesPending = defaultState.searchCitiesPending;
    state.searchCitiesResult = defaultState.searchCitiesResult;
  })

  .on(getStreets, state => {
    state.searchStreetsPending = true;
  })
  .on(getStreets.success, (state, { streets }) => {
    state.searchStreetsResult = streets;

    // const uniqueIds = new Set();

    // state.searchStreetsResult = [];

    // streets.forEach(street => {
    //   if (uniqueIds.has(street.id)) return;

    //   uniqueIds.add(street.id);

    //   state.searchStreetsResult.push(street);
    // });
  })
  .on([getStreets.success, getStreets.failure], state => {
    state.searchStreetsPending = false;
  })
  .on(getStreets.reset, state => {
    const defaultState = new DeliveryState();

    state.searchStreetsResult = defaultState.searchStreetsResult;
    state.searchStreetsPending = defaultState.searchStreetsPending;
  })

  .on(getBranch, state => {
    state.searchBranchPending = true;
  })
  .on(getBranch.success, (state, { branches }) => {
    state.searchBranchResult = branches;

    // const uniqueIds = new Set();

    // state.searchBranchResult = [];

    // branches.forEach(branch => {
    //   if (uniqueIds.has(branch.id)) return;

    // uniqueIds.add(branch.id);

    // state.searchBranchResult.push(branch);
    // });
  })
  .on([getBranch.success, getBranch.failure], state => {
    state.searchBranchPending = false;
  })
  .on(getBranch.reset, state => {
    state.searchBranchPending = false;
  })
  .on(getParcelLocker, state => {
    state.searchParcelLockerPending = true;
  })
  .on(getParcelLocker.success, (state, { parcelLockers }) => {
    const uniqueIds = new Set();

    state.searchParcelLockerResult = [];

    parcelLockers.forEach(parcelLocker => {
      if (uniqueIds.has(parcelLocker.id)) return;

      uniqueIds.add(parcelLocker.id);

      state.searchParcelLockerResult.push(parcelLocker);
    });
  })
  .on([getParcelLocker.success, getParcelLocker.failure], state => {
    state.searchParcelLockerPending = false;
  })
  .on(getParcelLocker.reset, state => {
    const defaultState = new DeliveryState();

    state.searchParcelLockerResult = defaultState.searchParcelLockerResult;
    state.searchParcelLockerPending = defaultState.searchParcelLockerPending;
  })

  .on(getDelyveryDescription, state => {
    state.deliveryDescription = deliveryDescription;
  })

  .on(createDelivery.success, (state, { trackNumber }) => {
    state.createDeliveryTractNumber = trackNumber;
  })
  .on([createDelivery.fail, createDelivery.success], state => {
    state.createDeliveryFinished = true;
  })
  .on(createDelivery.reset, state => {
    const defaultState = new DeliveryState();

    state.createDeliveryTractNumber = defaultState.createDeliveryTractNumber;
    state.createDeliveryFinished = defaultState.createDeliveryFinished;
  })

  .on(createDeliveryUt.success, (state, { trackNumber }) => {
    state.createDeliveryTractNumber = trackNumber;
  })
  .on([createDeliveryUt.fail, createDeliveryUt.success], state => {
    state.createDeliveryFinished = true;
  })
  .on(createDeliveryUt.reset, state => {
    const defaultState = new DeliveryState();

    state.createDeliveryTractNumber = defaultState.createDeliveryTractNumber;
    state.createDeliveryFinished = defaultState.createDeliveryFinished;
  })

  .on(setTransliteration, (state, { transliteration }) => {
    state.transliteration = transliteration;
  });

export { deliveryReducer };
