import type { Payload } from 'redux-chill';

import { Saga } from 'redux-chill';

import { call, put } from 'redux-saga/effects';

import type { StoreContext } from '@app/store/context';

import { enviroment } from '@shared/config/enviroment';

import type { Called } from '@shared/lib/saga';

import { getBranch, getCities, getParcelLocker, getStreets } from './actions';

class DeliverySaga {
  @Saga(getCities)
  public *searchCities(
    { query }: Payload<typeof getCities>,
    { api }: StoreContext
  ) {
    try {
      const response: Called<typeof api.novaPoshta.searchCities> = yield call(
        api.novaPoshta.searchCities,
        query
      );

      yield put(
        getCities.success(
          response.Addresses.map(({ Ref, Present, DeliveryCity, MainDescription, Area }) => ({
            id: Ref,
            ref: DeliveryCity,
            label: Present,
            description: MainDescription,
            area: Area
          }))
        )
      );
    } catch (e) {
      if (enviroment.development) {
        console.error(e);
      }
      yield put(getCities.failure());
    }
  }

  @Saga(getStreets)
  public *searchStreets(
    { query, ref }: Payload<typeof getStreets>,
    { api }: StoreContext
  ) {
    try {
      const response: Called<typeof api.novaPoshta.searchStreets> = yield call(
        api.novaPoshta.searchStreets,
        { query, ref }
      );

      yield put(
        getStreets.success(
          response.Addresses.map(({ SettlementRef, Present }) => ({
            id: SettlementRef,
            label: Present
          }))
        )
      );
    } catch (e) {
      if (enviroment.development) {
        console.error(e);
      }
      yield put(getStreets.failure());
    }
  }

  @Saga(getBranch)
  public *searchBranch(
    { ref }: Payload<typeof getBranch>,
    { api }: StoreContext
  ) {
    try {
      // const {
      //   delivery: { searchBranchResult }
      // }: State = yield select();

      // if (searchBranchResult.length === 0) {
      const response: Called<typeof api.novaPoshta.searchBranch> = yield call(
        api.novaPoshta.searchBranch,
        { ref }
      );

      yield put(
        getBranch.success(
          response.map(branch => ({
            id: branch.Ref,
            label: branch?.Description
          }))
        )
      );

      //   return;
      // }

      // const branches = searchBranchResult
      //   .filter(res => res.label.includes(query))
      //   .map(branch => ({
      //     ...branch,
      //     Ref: branch.id,
      //     Description: branch.label
      //   }));

      // yield put(
      //   getBranch.success(
      //     branches.map(branch => ({
      //       id: branch.Ref,
      //       label: branch?.Description
      //     }))
      //   )
      // );
    } catch (e) {
      if (enviroment.development) {
        console.error(e);
      }
      yield put(getBranch.failure());
    }
  }

  @Saga(getParcelLocker)
  public *searchParcelLocker(
    { query, ref }: Payload<typeof getParcelLocker>,
    { api }: StoreContext
  ) {
    try {
      const response: Called<typeof api.novaPoshta.searchParcelLocker> =
        yield call(api.novaPoshta.searchParcelLocker, { query, ref });

      const parcelLockers = response.filter(res =>
        res.Description.toLocaleUpperCase().includes(query.toLocaleUpperCase())
      );

      if (parcelLockers.length > 5) {
        parcelLockers.length = 5;
      }

      yield put(
        getParcelLocker.success(
          parcelLockers.map(parcelLocker => ({
            id: parcelLocker.Ref,
            label: parcelLocker?.Description
          }))
        )
      );
    } catch (e) {
      if (enviroment.development) {
        console.error(e);
      }
      yield put(getParcelLocker.failure());
    }
  }
}

export { DeliverySaga };
