import classNames from 'classnames';

import { Desktop } from '@app/ui';

import {
  NumberAlreadyUsedModal,
  OtpModal,
  PrivacyPolicyModal
} from '@pages/start-page/ui/atoms';

import { hoc } from '@shared/lib';

import { Button } from '@shared/ui/atoms/button';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import { PhoneInput } from '@shared/ui/atoms/phone-input';

import arrow from '../../data/img/arrow.svg';

import ukFlag from '../../data/img/uk-flag.png';

import styles from './start-page-block.module.scss';

import { useStartPageBlockProps } from './start-page-block.props';

/**
 * <StartPageBlock />
 */
const StartPageBlock = hoc(
  useStartPageBlockProps,
  ({
    city,
    phone,
    accept,
    isValid,
    isTablet,
    width,
    showPrivacy,
    isInternational,
    isTabletInternational,
    isOnlyInternational,
    isPhoneValid,
    setIsPhoneValid,
    setAccept,
    setShowPrivacy,
    setPhone,
    onSendOtp,
    onBackClick
  }) => {
    if (!isTablet && width > 768) return <Desktop />;

    return (
      <>
        <NumberAlreadyUsedModal />

        <OtpModal />

        <PrivacyPolicyModal
          open={showPrivacy}
          onClose={() => setShowPrivacy(false)}
        />

        <div className={classNames({ [styles.container]: isTablet })}>
          <img
            className={classNames(styles.image, {
              [styles.imageTablet]: isTablet
            })}
            src={isTablet ? city.image.tablet : city.image.mobile}
            alt={city.name}
          />

          <div
            className={classNames(styles.content, {
              [styles.contentTablet]: isTablet
            })}
          >
            <div
              className={classNames(styles.top, {
                [styles.topTablet]: isTablet
              })}
            >
              {isTablet && (
                <div className={styles.back} onClick={onBackClick}>
                  <img src={arrow} alt='' />

                  <p>На головну</p>
                </div>
              )}

              {!isTablet ? (
                <h1>Поїхали?</h1>
              ) : isTabletInternational ? (
                <h1>Доставка закордон 🌍</h1>
              ) : (
                <h1>
                  Доставка по Україні
                  <img src={ukFlag} alt='' />
                </h1>
              )}

              <p dangerouslySetInnerHTML={{ __html: `${city.description}` }} />

              <PhoneInput
                className={classNames(styles.input, {
                  [styles.inputTablet]: isTablet,
                  [styles.inputError]: !isPhoneValid && !isTablet,
                  [styles.inputErrorTablet]: !isPhoneValid && isTablet
                })}
                label={!isTablet ? 'Номер телефону' : ''}
                placeholder={isTablet ? 'Твій номер телефону' : ''}
                value={phone}
                isError={!isPhoneValid}
                error={!isPhoneValid ? 'Неправильний номер телефону' : ''}
                onChange={value => {
                  setPhone(value);

                  if (
                    value?.replace(/[\s]/g, '')?.replace('+', '')?.length !== 12
                  )
                    return;

                  setIsPhoneValid(value?.replaceAll(' ', '')?.[3] === '0');
                }}
                onBlur={() => {
                  setIsPhoneValid(phone?.replaceAll(' ', '')?.[3] === '0');
                }}
              />

              <Checkbox
                className={{
                  container: classNames(styles.checkbox, {
                    [styles.checkboxTablet]: isTablet
                  }),
                  box: classNames(styles.checkboxBox, {
                    [styles.checkboxTabletBox]: isTablet
                  }),
                  label: classNames(styles.checkboxLabel, {
                    [styles.checkboxTabletLabel]: isTablet
                  }),
                  checkmark: classNames({
                    [styles.checkboxCheckmark]: !isTablet
                  })
                }}
                label="Я приймаю умови <span id='privacy-modal-open'>Угоди користувача</span>"
                value={accept}
                onChange={setAccept}
              />

              {(isInternational || isOnlyInternational) && (
                <Button
                  style={{ marginBottom: '10px' }}
                  disabled={!isValid}
                  variant='primary'
                  onClick={onSendOtp(true)}
                >
                  Міжнародна доставка обіймів
                </Button>
              )}

              {!isOnlyInternational && (
                <Button
                  disabled={!isValid}
                  variant={
                    isTablet
                      ? 'primary'
                      : isInternational
                      ? 'secondary'
                      : 'primary'
                  }
                  onClick={onSendOtp(isTablet ? isTabletInternational : false)}
                >
                  {isTablet
                    ? isTabletInternational
                      ? 'Міжнародна доставка обіймів'
                      : 'Доставка обіймів по Україні'
                    : 'Доставка обіймів по Україні'}
                </Button>
              )}
            </div>

            {isTabletInternational && (
              <div className={styles.bottom}>
                <div className={styles.bottomContent}>
                  <div>
                    <p>Зручніше з телефону?</p>

                    <p>Скануй, щоб надіслати!</p>
                  </div>

                  <img
                    src={
                      isTabletInternational ? city.qrCode.in : city.qrCode.uk
                    }
                    alt='Text'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

export { StartPageBlock };
