import classNames from 'classnames';

import type { FC } from 'react';

import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getDelyveryDescription } from '@entities/delivery';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import type { useAddParameters } from '../model';

import styles from './add-parameters-form.module.scss';

type DelyveryDescriptionCheckProps = {
  form: ReturnType<typeof useAddParameters>['form'];
};

const DelyveryDescriptionCheck: FC<DelyveryDescriptionCheckProps> = ({
  form
}) => {
  const dispatch = useDispatch();
  const { deliveryDescription } = useTypedSelector(state => state.delivery);

  const onClickDescription = (id: number) => {
    if (form.values.delyveryInfo.find(el => el.id === id)) {
      form.setFieldValue(
        'delyveryInfo',
        form.values.delyveryInfo.filter(el => el.id !== id)
      );

      return;
    }

    form.setFieldValue(
      'delyveryInfo',
      form.values.delyveryInfo.length === 0
        ? [
            {
              id: id,
              label: deliveryDescription.filter(el => el.id === id)
            }
          ]
        : [
            ...form.values.delyveryInfo,
            {
              id: id,
              label: deliveryDescription.filter(el => el.id === id)
            }
          ]
    );
  };

  useEffect(() => {
    dispatch(getDelyveryDescription());
  }, []);

  return (
    <>
      {deliveryDescription.map(delyvery => (
        <div
          className={classNames(styles.blocks, {
            [styles.checked]: form.values.delyveryInfo.some(
              description => description.id === delyvery.id
            )
          })}
          key={delyvery.id}
          onClick={() => {
            onClickDescription(delyvery.id);
          }}
        >
          {delyvery?.label?.filter(el => el.langCode === 'ua')[0]?.value}
        </div>
      ))}
    </>
  );
};

export { DelyveryDescriptionCheck };
