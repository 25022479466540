import type { Payload } from 'redux-chill';

import { Saga } from 'redux-chill';

import { call, put, select } from 'redux-saga/effects';

import type { State } from '@app/store';

import type { StoreContext } from '@app/store/context';

import { routes } from '@shared/config/routes';

import type { Called } from '@shared/lib/saga';

import { navigate } from '@shared/router';

import {
  checkOtp,
  sendOtp,
  setIsNumberAlreadyUser,
  setIsOtpSent,
  setPhone,
  setStartPageParams
} from './actions';

import type { StartPageState } from './state';

/**
 * Start page saga
 */
class StartPageSaga {
  @Saga(sendOtp)
  public *sendOtp(
    { phone, isInternationalClick }: Payload<typeof sendOtp>,
    { api }: StoreContext
  ) {
    try {
      const { city }: StartPageState = yield select(
        (state: State) => state.startPage
      );

      const data: any = { phone };

      data.city = city;

      if (isInternationalClick) {
        data.shipment_type = 'int';
      } else {
        data.shipment_type = 'uk';
      }

      const response: Called<typeof api.startPage.sendOtp> = yield call(
        api.startPage.sendOtp,
        data
      );

      const result = response.data.ops?.[0]?.data?.result;

      if (result === 'error') {
        yield put(setIsNumberAlreadyUser(true));
      } else if (result === 'ok') {
        yield put(setIsOtpSent(true));
        yield put(setPhone(phone));
      }
    } catch (error) {
      console.log(error);
    }
  }

  @Saga(checkOtp)
  public *checkOtp(
    { otp, isTablet }: Payload<typeof checkOtp>,
    { api, history }: StoreContext
  ) {
    try {
      const { phone, city, isInternationalClicked }: StartPageState =
        yield select((state: State) => state.startPage);

      const data: any = { phone, otp };

      const substringsArray = ['in', '-uk'];
      const isInternational = substringsArray.some(substring =>
        history.location.pathname.includes(substring)
      );

      data.source = 'iqos_np';
      data.city = city;
      data.pathname = history.location.pathname;

      if (isInternationalClicked) {
        data.shipment_type = 'int';
      } else {
        data.shipment_type = 'uk';
      }

      if (isTablet) {
        data.device = 'tablet';
      } else {
        data.device = 'mobile';
      }

      const response: Called<typeof api.startPage.checkOtp> = yield call(
        api.startPage.checkOtp,
        data
      );

      const result = response.data.ops?.[0]?.data?.result;

      if (result === 'error') {
        yield put(checkOtp.error());

        return;
      }

      if (result === 'ok') {
        yield put(checkOtp.success());

        if (isTablet || (isInternational && isInternationalClicked)) {
          yield put(setStartPageParams(isTablet, isInternationalClicked));

          if (!isInternationalClicked) {
            yield put(navigate(routes.delivery.senderUt()));
          } else {
            yield put(navigate(routes.delivery.sender()));
          }

          return;
        }

        const userAgent =
          navigator.userAgent || navigator.vendor || (window as any).opera;

        if (userAgent.includes('Android')) {
          setTimeout(() => {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=ua.novaposhtaa&utm_source=npsite&utm_campaign=mobile_app_page&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            );
          }, 700);

          window.location.replace('novaposhta://login');

          return;
        }

        if (userAgent.includes('iPhone')) {
          setTimeout(() => {
            window.location.replace(
              'http://itunes.apple.com/us/app/nova-posta/id545980774?mt=8&ls=1'
            );
          }, 700);

          window.location.replace('novaposhta://login');
        }
      }
    } catch (error) {
      yield put(checkOtp.error());
    }
  }
}

export { StartPageSaga };
