import { object, string } from 'yup';

import type { SenderInfoUT } from '../types';

import { onlyUkrString } from './regexp';

const schema = object<SenderInfoUT>({
  surname: string()
    .matches(onlyUkrString, 'Тільки українські літери')
    .required("Обов'язкове поле"),

  firstname: string()
    .matches(onlyUkrString, 'Тільки українські літери')
    .required("Обов'язкове поле")
});

export { schema };
