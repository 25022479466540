import { Field, Form } from '@shared/form';

import { hoc } from '@shared/lib';

import {
  STEPPER_FORM_CONTROLS_BOTTOM_PADDING,
  StepperFormControls
} from '@shared/ui/stepper-form-controls';

import { contragentTypes, countries } from '../config';

import { useAddReceiverInfo } from '../model';

import styles from './add-receiver-info-form.module.scss';

import { CountrySearchInput } from './search-country.component';

const AddReceiverInfoForm = hoc(
  useAddReceiverInfo,
  ({ form, isValid, onGoBack }) => (
    <Form
      className={styles.form}
      style={{ paddingBottom: STEPPER_FORM_CONTROLS_BOTTOM_PADDING + 33 }}
      use={form}

    >
      <h3 className={styles.title}>
        Інформація про отримувача — це друг, що отримає сюрприз
      </h3>

      <div className={styles.grid}>
        <Field.Phone
          className={styles.field}
          name='phone'
          type='tel'
          label='Телефон отримувача'
          countries={countries.sort((a, b) =>
            a.label[0].localeCompare(b.label[0])
          )}
          helperText='Вкажіть номер телефону без коду країни доставки'
        />

        <Field.Text
          className={styles.field}
          name='email'
          type='email'
          label='Email'
        />
      </div>

      <div className={styles.grid}>
        <Field.RadioGroup
          name='type'
          title='Тип контрагента'
          radios={contragentTypes}
          readOnly
        />
      </div>

      <div className={styles.grid}>
        <Field.Text className={styles.field} name='lastName' label='Прізвище' />

        <Field.Text className={styles.field} name='firstName' label="Ім'я" />
      </div>

      <div className={styles.grid}>
        <CountrySearchInput />

        <City />
      </div>

      <div className={styles.grid}>
        <Field.Text
          className={styles.field}
          name='cityIndex'
          label='Індекс'
          maxLength={10}
          toUpperCase
        />

        <HouseSection />
      </div>

      <Field.Text
        className={styles.field}
        name='additionalInfo'
        label='Додаткова інформація адреси Отримувача'
        rows={10}
        textarea
      />

      <StepperFormControls
        dirty={form.dirty}
        canSubmit={isValid}
        onGoBackClick={onGoBack}
      />
    </Form>
  )
);

const City = () => (
  <Field.Text className={styles.field} name='city' label='Населений пункт' />
);

const HouseSection = () => (
  <div className={styles.grid}>
    <Field.Text className={styles.field} name='street' label='Вулиця' />

    <Field.Text className={styles.field} name='building' label='Будинок' />
  </div>
);

export { AddReceiverInfoForm };
