import type { Directory } from '@shared/lib/directory';

import type { Country } from '@shared/ui/molecules/phone';

const contragentTypes: Directory[] = [
  { id: 'private', label: 'Приватна особа' },
  { id: 'juridic', label: 'Юридична особа' }
];

const countries: Country[] = [
  { id: 'FR', label: 'Франція', phonePrefix: '+33' },
  { id: 'ES', label: 'Іспанія', phonePrefix: '+34' },
  { id: 'SE', label: 'Швеція', phonePrefix: '+46' },
  { id: 'NO', label: 'Норвегія', phonePrefix: '+47' },
  { id: 'DE', label: 'Німеччина', phonePrefix: '+49' },
  { id: 'FI', label: 'Фінляндія', phonePrefix: '+358' },
  { id: 'PL', label: 'Польща', phonePrefix: '+48' },
  { id: 'IT', label: 'Італія', phonePrefix: '+39' },
  { id: 'GB', label: 'Велика британія', phonePrefix: '+44' },
  { id: 'RO', label: 'Румунія', phonePrefix: '+40' },
  { id: 'GR', label: 'Греція', phonePrefix: '+30' },
  { id: 'BG', label: 'Болгарія', phonePrefix: '+359' },
  { id: 'IS', label: 'Ісландія', phonePrefix: '+354' },
  { id: 'HU', label: 'Угорщина', phonePrefix: '+36' },
  { id: 'PT', label: 'Португалія', phonePrefix: '+351' },
  { id: 'AT', label: 'Австрія', phonePrefix: '+43' },
  { id: 'CZ', label: 'Чехія', phonePrefix: '+420' },
  { id: 'RS', label: 'Сербія', phonePrefix: '+381' },
  { id: 'IE', label: 'Ірландія', phonePrefix: '+353' },
  { id: 'LT', label: 'Литва', phonePrefix: '+370' },
  { id: 'LV', label: 'Латвія', phonePrefix: '+371' },
  { id: 'HR', label: 'Хорватія', phonePrefix: '+385' },
  { id: 'BA', label: 'Боснія і Герцоговина', phonePrefix: '+387' },
  { id: 'SK', label: 'Словаччина', phonePrefix: '+421' },
  { id: 'EE', label: 'Естонія', phonePrefix: '+372' },
  { id: 'DK', label: 'Данія', phonePrefix: '+45' },
  { id: 'NL', label: 'Нідерланди', phonePrefix: '+31' },
  { id: 'CH', label: 'Швейцарія', phonePrefix: '+41' },
  { id: 'MD', label: 'Молдова', phonePrefix: '+373' },
  { id: 'BE', label: 'Бельгія', phonePrefix: '+32' },
  { id: 'AL', label: 'Албанія', phonePrefix: '+355' },
  { id: 'SI', label: 'Словенія', phonePrefix: '+386' },
  { id: 'ME', label: 'Чорногорія', phonePrefix: '+382' },
  { id: 'CY', label: 'Кіпр', phonePrefix: '+357' },
  { id: 'LU', label: 'Люксембург', phonePrefix: '+352' },
  { id: 'AD', label: 'Андорра', phonePrefix: '+376' },
  { id: 'MT', label: 'Мальта', phonePrefix: '+356' },
  { id: 'LI', label: 'Ліхтенштейн', phonePrefix: '+423' },
  { id: 'SM', label: 'Сан-Марино', phonePrefix: '+378' },
  { id: 'MC', label: 'Монако', phonePrefix: '+377' },
  { id: 'UA', label: 'Україна', phonePrefix: '+38' }
];

export { contragentTypes, countries };
