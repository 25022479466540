import classNames from 'classnames';

import React from 'react';

import { hoc } from '@shared/lib/hoc';

import AngleDown from './angle-down';

import styles from './select.module.scss';

import { useSelect } from './select.props';

/**
 * Renders Select
 */
const Select = hoc(
  useSelect,
  ({
    color,
    search,
    filteredOptions,
    searchable,
    input,
    label,
    select,
    focused,
    onClick,
    hasValue,
    disabled,
    className,
    selectors,
    reference,
    placeholder,
    setSearch,
    setSearchChange,
    noOptionsLabel,
    onOptionClick,
    onSearchChange
  }) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <div
      className={classNames(
        styles.select,
        (styles as any)[color as any],
        className,
        {
          [styles.focused]: focused,
          [styles.searchable]: focused && searchable,
          [styles.disabled]: disabled,
          [styles.hasValue]: hasValue
        }
      )}
      ref={reference}
      onClick={() => !searchable && onClick()}
    >
      {label && <label className={styles.label}>{label}</label>}

      {setSearchChange && setSearch('')}

      <input
        ref={input}
        className={styles.field}
        readOnly={!searchable}
        value={search || ''}
        placeholder={focused ? placeholder : ''}
        onChange={onSearchChange}
        onClick={() => searchable && onClick()}
      />

      <AngleDown className={styles.icon} onClick={onClick} />

      {focused && (
        <div className={styles.box}>
          {!filteredOptions.length && (
            <p className={classNames(styles.option, styles['no-option'])}>
              {noOptionsLabel ?? 'No options'}
            </p>
          )}

          {filteredOptions?.map((option, index) => {
            const id = select(selectors.id, option);
            const name = select(selectors.name, option);

            return (
              <div
                data-id={id}
                key={`${index}-${id}`}
                className={styles.option}
                onClick={() => onOptionClick(option)}
              >
                {name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  )
);

/**
 * Select Default Props
 */
Select.defaultProps = {
  color: 'white',
  searchable: false,
  shape: {},
  value: null,
  options: [],
  placeholder: null as any
};

export { Select };
