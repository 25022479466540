import type { Payload } from 'redux-chill';

import { Saga } from 'redux-chill';

import { select } from 'redux-saga/effects';

import type { State } from '@app/store';

import type { RouterState } from '@shared/router/state';

import { navigate } from './actions';

class RouterSaga {
  @Saga(navigate)
  public *navigate({ to, options }: Payload<typeof navigate>) {
    const { navigateFn }: RouterState = yield select(
      (state: State) => state.router
    );

    if (!navigateFn) return;

    navigateFn(to, options);
  }
}

export { RouterSaga };
