import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { createDelivery, createDeliveryUt } from '@entities/delivery';

import { setReviewInfoUt } from './actions';

const useAddReviewInfo = () => {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {},

    onSubmit: () => {
      dispatch(createDeliveryUt());
    }
  });

  const onGoBack = () => {
    dispatch(setReviewInfoUt.reset());
  };

  return { form, onGoBack };
};

export { useAddReviewInfo };
