import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';

import { sendOtp, setCity } from '../../model';

const useCityUkStartBlockProps = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState('');
  const [phone, setPhone] = useState('');
  const [accept, setAccept] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const formatPhone = () => phone.replace(/[\s]/g, '').replace('+', '');

  //const onSendOtp = () => {
  //  dispatch(sendOtp(formatPhone()));
  //};

  const onSendOtp =
    (isInternational = false) =>
    () => {
      dispatch(sendOtp(formatPhone(), isInternational));
    };

  const onChange = (value: string) => {
    setSelectedCity(value);
    dispatch(setCity(value as any));
  };

  useEffect(() => {
    const onClick = () => {
      setShowPrivacy(true);
    };

    const button = document.getElementById('privacy-modal-open');

    button?.addEventListener('click', onClick);

    return () => {
      button?.removeEventListener('click', () => onClick);
    };
  }, []);

  return {
    phone,
    selectedCity,
    isInternational: true,
    isValid: formatPhone().length === 12 && accept && !!selectedCity,
    isTablet: location.pathname.includes('tablet'),
    accept,
    showPrivacy,
    setShowPrivacy,
    setPhone,
    setAccept,
    onSendOtp,
    onChange
  };
};

export { useCityUkStartBlockProps };
