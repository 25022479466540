import type { Parameters } from '@features/delivery/add-parameters';

import type { ReceiverInfo } from '@features/delivery/add-receiver-info';

import type { ReceiverInfoUt } from '@features/delivery/add-receiver-info-ut';

import type { SenderInfo } from '@features/delivery/add-sender-info';

import type { SenderInfoUT } from '@features/delivery/add-sender-info-ut';

import type { CreateShipmentDto, CreateShipmentDtoUt } from '@shared/api';

import type { DeepNonNullable } from '@shared/lib/typescript';

class Adapter {
  public static toCreateDto(
    sender: DeepNonNullable<SenderInfo>,
    receiver: DeepNonNullable<ReceiverInfo>,
    details: DeepNonNullable<Parameters>,
    phone: string,
    transliteration: {
      sender: {
        name: string;
        surname: string;
        // city: NonNullable<SenderInfo['city']>['label'];
      };

      receiver: {
        name: ReceiverInfo['firstName'];
        surname: ReceiverInfo['lastName'];
        country: NonNullable<ReceiverInfo['country']>['label'];
        city: ReceiverInfo['city'];
        street: ReceiverInfo['street'];
      };
    },
    city: string,
    pathname: string
  ): CreateShipmentDto {
    type DeliveryInfo<T> = { id: string; label: T[] };

    // FIXME: WTF?
    const deliveryInfo = (
      details.delyveryInfo as DeliveryInfo<
        DeliveryInfo<{ langCode: string; value: string }>
      >[]
    )
      .flatMap(data => data.label)
      .flatMap(({ label }) => label)
      .filter(({ langCode }) => langCode === 'en')
      .map(({ value }) => value);

    return {
      sender_phone: phone,
      sender_name: sender.firstname,
      sender_name_en: transliteration.sender.name,
      sender_surname: sender.surname,
      sender_surname_en: transliteration.sender.surname,
      sender_email: sender.email,
      // sender_post_index: `${sender.postIndex}`,
      // sender_city: sender.city.label,
      // sender_city_en: transliteration.sender.city,
      // sender_city_ref: sender.city.id,
      // sender_street: sender.street.label,
      // sender_house: sender.building,
      // sender_flat: `${sender.apartments}`,

      recipient_phone:
        receiver.phone.country.phonePrefix + receiver.phone.value,
      recipient_email: receiver.email,
      recipient_name: receiver.firstName,
      recipient_surname: receiver.lastName,
      recipient_name_en: transliteration.receiver.name,
      recipient_surname_en: transliteration.receiver.surname,

      recipient_country: receiver.country.label,
      recipient_country_en: transliteration.receiver.country,
      recipient_country_code: receiver.country.id,

      recipient_city: receiver.city,
      recipient_city_en: transliteration.receiver.city,

      recipient_post_index: receiver.cityIndex,

      recipient_post_street: receiver.street,
      recipient_post_street_en: transliteration.receiver.street,
      recipient_house: receiver.building,

      // PostmanRequiredFlags (?)
      // description_shipment: 'тест',
      // description_shipment_en: 'TEST',

      additional_information_addr: receiver.additionalInfo,

      goods_en: deliveryInfo,
      city,
      pathname
    };
  }

  public static toCreateDtoUt(
    sender: DeepNonNullable<SenderInfoUT>,
    receiver: DeepNonNullable<ReceiverInfoUt>,
    phone: string,
    city: string,
    pathname: string
  ): CreateShipmentDtoUt {
    return {
      sender_phone: phone,
      sender_name: sender.firstname,
      sender_surname: sender.surname,

      recipient_phone: receiver.phone,
      recipient_name: receiver.firstName,
      recipient_surname: receiver.lastName,

      recipient_city: receiver.city.description,
      recipient_obl: receiver.city.area,
      recipient_city_ref: receiver.city.ref,

      place_rec:
        receiver.receivCheckId === 1
          ? 'Відділення'
          : receiver.receivCheckId === 2
          ? 'Адреса'
          : 'Поштомат',

      city,
      pathname
    };
  }
}

export { Adapter };
