import classNames from 'classnames';

import type { FC } from 'react';

import type { Directory } from '@shared/lib/directory';

import styles from './radio-group.module.scss';

type RadioGroupProps = {
  title: string;

  radios: Directory[];

  readOnly?: boolean;

  value: Directory | null;

  onChange: (option: Directory) => void;
};

const RadioGroup: FC<RadioGroupProps> = ({
  title,
  radios,
  value,
  readOnly,
  onChange
}) => (
  <div className={styles.wrapper}>
    <h3 className={styles.title}>{title}</h3>

    <div className={styles.container}>
      {radios.map(({ label, id }) => {
        const checked = !!value && value.id === id;

        return (
          <label
            className={classNames(styles.button, {
              [styles.checked]: checked,
              [styles.disabled]: readOnly
            })}
            key={id}
            onClick={() => !readOnly && onChange({ id, label })}
          >
            {label}

            <input
              className={styles.input}
              type='radio'
              checked={checked}
              value={id}
              readOnly={readOnly}
            />
          </label>
        );
      })}
    </div>
  </div>
);

export { RadioGroup };

export type { RadioGroupProps };
