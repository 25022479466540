type DeliveryRoutes = {
  (): string;
  sender: () => string;
  senderUt: () => string;
  receiver: () => string;
  receiverUt: () => string;
  parameters: () => string;
  pricing: () => string;
  review: () => string;
  reviewUt: () => string;
};

const deliveryRoutes: DeliveryRoutes = () => '/delivery';
deliveryRoutes.sender = () => '/delivery/sender';
deliveryRoutes.senderUt = () => '/delivery/sender/ut';
deliveryRoutes.receiver = () => '/delivery/receiver';
deliveryRoutes.receiverUt = () => '/delivery/receiver/ut';
deliveryRoutes.parameters = () => '/delivery/parameters';
deliveryRoutes.pricing = () => '/delivery/pricing';
deliveryRoutes.review = () => '/delivery/review';
deliveryRoutes.reviewUt = () => '/delivery/review/ut';

export { deliveryRoutes };
