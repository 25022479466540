import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { getBranch } from '@entities/delivery';

import { Field } from '@shared/form';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { useCityId, useIsCityEntered } from '../model';

import styles from './add-receiver-info-ut-form.module.scss';

const BranchSearchInput = () => {
  const dispatch = useDispatch();
  const { searchBranchResult, searchBranchPending } = useTypedSelector(
    state => state.delivery
  );

  const isCityEntered = useIsCityEntered();
  const cityId = useCityId();

  const [query, setQuery] = useState('');

  const onReset = () => {
    dispatch(getBranch.reset());

    setQuery('');
  };

  useEffect(() => {
    if (!cityId) return;

    dispatch(getBranch(cityId));
  }, [cityId]);

  return (
    <Field.Search
      className={styles.field}
      name='branch'
      type='search'
      label='Відділення'
      options={
        query
          ? searchBranchResult
              .filter(({ label }) =>
                label.toLocaleUpperCase().includes(query.toLocaleUpperCase())
              )
              .filter((_, index) => index < 5)
          : searchBranchResult.filter((_, index) => index < 5)
      }
      onQueryChange={setQuery}
      onBlur={onReset}
      disabled={!isCityEntered}
      loading={searchBranchPending}
    />
  );
};

export { BranchSearchInput };
